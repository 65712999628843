import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid, TextField } from "@mui/material";

// Token
import { getVars } from "@utils/global";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import FilterCollapse from "@components/Filters/FilterCollapse";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "date-fns/locale/es";
import {
  TIPO_CANDIDATO,
  TIPO_GENERAL,
  TIPO_TERRITORIAL,
} from "../../data/constants";
import {
	isNullOrUndefined,
} from "@utils/validations";

const FilterEvento = ({
  onChangeFilter,
  municipio = true,
  municipio_dl = false,
  poligono = true,
  seccion = true,
  manzana = true,
  checkRespon = false,
  checkResponsabilidades = false,
  responsable = false,
  vista = true,
  flagCategoria = true,
  setActualVista = () => {},
}) => {
  const {
    user: { ambito_perfil },
  } = getVars("Token");
  //variables
  const defaultOption = { value: 0, label: "TODOS" };
  //States
  const [, setCatRegions] = useState([]);
  const [catVista, setCatVista] = useState([
    { value: 1, label: "Región" },
    { value: 2, label: "Municipio" },
  ]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
  const [catCategoria, setCatCategoria] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Vista: 1,
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
    Manzana: [],
    responsable: 0,
    Celular: "",
    NombreCompleto: "",
  });
  const [catResponsabilidades, setCatResponsabilidades] = useState([
    defaultOption,
    {value:1,label:"Sin Responsablidad"},
    {value:2,label:"RCs"},
    {value:3,label:"RGs"},
    {value:8,label:"Abogados"},
  ]);
  const [selectedManzana, setSelectedManzana] = useState([]);

  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id:"modo_evento", getAll:false },
      { id:"tipo_actividad_evento", getAll:false, filtered :[{id:"id",value:[7,8,9,10], filter:"IN"}] }
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "regiones":
                setCatRegions(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatRegionsFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "municipios_dl":
                setCatMunicipality(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipalityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;
              case "municipios":
                setCatMunicipio(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipioFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;
            case "tipo_actividad_evento":
              setCatCategoria(
                [defaultOption].concat(res.response.catalogs[item.id])
              );
              break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    if (ambito_perfil.find((item) => item.idTipo === TIPO_GENERAL)) {
      setCatVista([
        { value: 1, label: "Estatal" },
        { value: 2, label: "Región" },
        { value: 3, label: "Municipio" },
      ]);
    } else if (
      ambito_perfil.find((item) =>
        [TIPO_TERRITORIAL, TIPO_CANDIDATO].includes(item.idTipo)
      )
    ) {
      let perfil = ambito_perfil.find((item) =>
        [TIPO_TERRITORIAL, TIPO_CANDIDATO].includes(item.idTipo)
      );

      switch (perfil.idAmbito) {
        case 1:
          setCatVista([
            { value: 1, label: "Estatal" },
            { value: 2, label: "Región" },
            { value: 3, label: "Municipio" },
          ]);
          break;
        case 2:
          setCatVista([
            { value: 2, label: "Región" },
            { value: 3, label: "Municipio" },
          ]);
          break;
        case 3:
          setCatVista([{ value: 3, label: "Municipio" }]);
          break;
        case 4:
          setCatVista([{ value: 3, label: "Municipio" }]);
          break;
        case 5:
          setCatVista([{ value: 3, label: "Municipio" }]);
          break;
        case 6:
          setCatVista([{ value: 3, label: "Municipio" }]);
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDataCatalog({ ...dataCatalog, Vista: catVista[0].value });
    setActualVista(catVista[0].value);
  }, [catVista]);

  useEffect(() => {
    //console.log("dataCatalog",dataCatalog);
    let newFilter = dataCatalog;

    if (!municipio_dl || !municipio) {
      newFilter = { ...newFilter, Municipio: 0 };
      setCatMunicipioFilter(catMunicipio);
      setCatMunicipalityFilter(catMunicipality);
    }
    if (!seccion) {
      setSelectedSections([]);
      newFilter = { ...newFilter, Seccion: [] };
      setCatSectionFilter(catSection);
    }
    if (!manzana) {
      setSelectedManzana([]);
      newFilter = { ...newFilter, Manzana: [] };
    }

    setDataCatalog(newFilter);

    onChangeFilter(newFilter);
  }, [municipio, municipio_dl, poligono, seccion, manzana]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatMunicipioFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idRegion)
      );
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatSectionFilter(
        catSection.filter((item) => item.idMunicipio === idMunicipality)
      );
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeMunicipio = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatSectionFilter(
        catSection.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeResponsable = (newValue) => {
    const res = newValue.target.value;

    setDataCatalog({
      ...dataCatalog,
      responsable: res,
    });
  };

  const hadleChangeTextField = (e) => {
    setDataCatalog({
      ...dataCatalog,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      responsable: dataCatalog.responsable,
      Responsabilidad: dataCatalog.responsable,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Manzana: dataCatalog.Manzana,
      Celular: dataCatalog.Celular,
      NombreCompleto: dataCatalog.NombreCompleto,
      Vista: dataCatalog.Vista,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      Municipio: 0,
      responsable: 0,
      Poligono: [],
      Seccion: [],
      Manzana: [],
      Celular: "",
      NombreCompleto: "",
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatSectionFilter(catSection);

    setDataCatalog(newFilter);

    /* 
    setData({
      ...data,
      filtered: [],
    }); */
    onChangeFilter(newFilter);
  };


  return (
    <>
      <FilterCollapse>
        <Grid
          container
          spacing={2}
          marginBottom={"1rem"}
          marginTop={"0.5rem"}
          sx={{ position: "relative" }}
        >
          {vista && (
            <Grid item xs={12} sm={6} md={6} xl={4}>
              <InputSelect
                label="Tipo Evento: "
                options={catVista}
                name="vista"
                value={dataCatalog.Vista}
                onChange={(e) => {
                  setDataCatalog({
                    ...dataCatalog,
                    Vista: e.target.value,
                  });
                }}
                //disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {catVista.find((item) => item.value === 1) && (
            <Grid item xs={12} md={6} xl={4}>
              <InputSelect
                label="Región"
                options={catRegionsFilter}
                name="region"
                value={
                  catRegionsFilter.length === 2
                    ? catRegionsFilter[1].value
                    : dataCatalog.Region
                }
                onChange={handleChangeRegion}
                disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {municipio && (
            <Grid item xs={12} md={6} xl={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipioFilter}
                name="municipio"
                value={
                  catMunicipioFilter.length === 2
                    ? catMunicipioFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipality}
                disabled={catMunicipio.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {municipio_dl && (
            <Grid item xs={12} md={6} xl={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                  catMunicipalityFilter.length === 2
                    ? catMunicipalityFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipio}
                disabled={catMunicipality.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} xl={4}>
            <TextField
              label="Nombre Evento"
              size="small"
              name="NombreEvento"
              value={dataCatalog.NombreCompleto.toUpperCase()}
              onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                // disabled={true}
                label="Fecha Evento"
                onChange={(e) => {
                  setDataCatalog({...dataCatalog,FechaEvento:isNullOrUndefined(e) ? "" : e,
                  });
                }}
                //onBlur={handleBlur}
                value={dataCatalog.FechaEvento}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {
            flagCategoria &&
            <Grid item xs={12} sm={6} md={6} xl={4}>
            <InputSelect
              label="Categoria del evento: "
              options={catCategoria}
              name="vista"
              value={dataCatalog.Actividad}
              onChange={(e) => {
                setDataCatalog({
                  ...dataCatalog,
                  Actividad: e.target.value,
                });
              }}
              //disabled={catRegionsFilter.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          }
        </Grid>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          {checkResponsabilidades && (
            <Grid item xs={4} md={4}>
              <InputSelect
                label="Responsable"
                options={catResponsabilidades}
                name="Responsable"
                value={dataCatalog.responsable}
                onChange={handleChangeResponsable}
                disabled={false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {checkRespon && (
            <Grid item xs={4} md={4}>
              <InputSelect
                label="Responsable"
                options={[
                  { label: "Todos", value: 0 },
                  { label: "Sin Responsabilidad", value: 1 },
                  { label: "Con Responsabilidad", value: 2 },
                ]}
                name="Responsable"
                value={dataCatalog.responsable}
                onChange={handleChangeResponsable}
                disabled={false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {responsable && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Nombre"
                  size="small"
                  name="NombreCompleto"
                  value={dataCatalog.NombreCompleto.toUpperCase()}
                  onChange={hadleChangeTextField}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Celular"
                  size="small"
                  name="Celular"
                  value={dataCatalog.Celular.toUpperCase()}
                  onChange={hadleChangeTextField}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default FilterEvento;
