import { useEffect, useState } from "react";

// Material UI
import { Container, Grid, Button, Typography } from "@mui/material";
import Swal from "sweetalert2";

//Services
import movilizacion from "@services/MovilizationServices";

// Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";

import Filter from "@components/Activation/DashboardTerritorial/Filter";
import LastUpdatedCard from "@components/Activation/DashboardElectoral/LastUpdatedCard";
import AdaptiveCard from "@components/Card/AdaptiveCard";
import ChartMobilizationProgress from "@components/Activation/DashboardTerritorial/ChartMobilizationProgress";
import ChartCumulativeProgress from "@components/Activation/DashboardTerritorial/ChartCumulativeProgress";
import ChartRollCallProgress from "@components/Activation/DashboardTerritorial/ChartRollCallProgress";
import TableMobilization from "@components/Activation/DashboardTerritorial/TableMobilization";

// Iconos
import {
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TableAdavanceMobilization from "../../components/Activation/DashboardTerritorial/TableAdavanceMobilization";

const DashboardMobilizationTerritorial = () => {
  const [ambitoUser, setAmbitoUser] = useState("");

  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingCards, setLoadingCards] = useState();

  const total = 10;
  const [loading, setLoading] = useState({
    isLoadigForm: false,
    isSuccessForm: false,
    openLinearProgress: false,
  });
  const [refresh, setRefresh] = useState(false);

  const [idFilter, setIdFilter] = useState(ambitoUser);

  const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] =
    useState(ambitoUser);

  const [catView, setCatView] = useState([]);

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: ambitoUser,
  });
  const [bodyChart, setBodyChart] = useState({
    filtered: [],
    idAmbitoAgrupador: ambitoUser,
  });
  const [dataChart, setDataChart] = useState({
    Fecha: "",
    Movilizacion: [],
    PaseLista: [],
  });

  const handleSearchFilter = ({ filtereds }) => {
    setData((prev) => ({
      ...prev,
      filtered: filtereds,
      page: 0,
      pageSize: 10,
    }));
    setBodyChart((prev) => ({ ...prev, filtered: filtereds }));
  };

  const handleClearFilter = () => {
    if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {
      setData((prev) => ({
        ...prev,
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
      }));
      setBodyChart((prev) => ({
        ...prev,
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
      }));
      setIdAmbitoAgrupadorTable(ambitoUser);
    }
  };

  const handleRefresh = () => {
    setData((prev) => ({ ...prev, page: 0, pageSize: 10, sorted: [] }));
    setDataChart((prev) => ({ ...prev }));
    setRefresh(true);
  };

  const cardsTerritorial = async (bodyChart) => {
    setLoadingCards(true);

    setLoading({ ...loading, isLoadigForm: true, openLinearProgress: true });

    try {
      const { idAmbitoAgrupador, ...params } = bodyChart;
      const res = await movilizacion.getCardsTerritorial(params);

      const { results, message, response } = res;

      if (results) {
        setDataChart({
          Fecha: response.data.Fecha,
          Movilizacion: response.data.Movilizacion,
          PaseLista: response.data.PaseLista,
        });
        setRefresh(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading({
        ...loading,
        isLoadigForm: false,
        openLinearProgress: false,
      });
      setLoadingCards(false);
    }
  };

  // useEffect(() => {
  //   cardsTerritorial(bodyChart);
  //   // eslint-disable-next-line
  // }, [bodyChart]);

  useEffect(() => {
    // Filtrar el array 'filtered' del bodyChart para eliminar el objeto con id 'PorcentajeAvance'
    const filteredBody = {
      ...bodyChart,
      filtered: bodyChart.filtered.filter(
        (item) => item.id !== "PorcentajeAvance"
      ),
    };
    cardsTerritorial(filteredBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyChart]);

  const changeTypeViewFilter = (view) => {
    setIdAmbitoAgrupadorTable(view);
    setBodyChart((prev) => ({
      ...prev,
      filtered: [],
      idAmbitoAgrupador: view,
    }));
    setData((prev) => ({
      ...prev,
      page: 0,
      pageSize: 10,
      filtered: [],
      idAmbitoAgrupador: view,
    }));
  };

  const getCatView = async () => {
    try {
      const res = await movilizacion.getAmbitoAgrupadorMovilizacion();
      const { results, response, message } = res;

      if (results) {
        const data = response.data.data.sort((a, b) => a.value - b.value);
        setCatView(data);
        setAmbitoUser(data[0]?.value);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  useEffect(() => {
    getCatView();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ambitoUser) {
      setIdAmbitoAgrupadorTable(ambitoUser);
      setBodyChart((prev) => ({
        ...prev,
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
      }));
      setData((prev) => ({
        ...prev,
        page: 0,
        pageSize: 10,
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
      }));
    }
    // eslint-disable-next-line
  }, [ambitoUser]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading.isLoadigForm}
        success={loading.isSuccessForm}
        isOpen={loading.openLinearProgress}
        setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
      />
      <Container maxWidth={false}>
        <ActionHeader title={"Dashboard Promoción"} isCustom={true}>
          <Button
            onClick={() => setOpenDrawer(!openDrawer)}
            aria-label="open Drawer"
            endIcon={
              openDrawer ? (
                <KeyboardDoubleArrowRight />
              ) : (
                <KeyboardDoubleArrowLeft />
              )
            }
          >
            <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
          </Button>
        </ActionHeader>

        <Grid
          container
          spacing={openDrawer ? 2 : 0}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid item xs={12} md={openDrawer ? 8 : 12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={openDrawer ? 12 : 4}
                lg={4}
                sx={{ paddingBottom: "20px" }}
              >
                <LastUpdatedCard
                  dateHour={dataChart.Fecha || ""}
                  handleClick={handleRefresh}
                  refresh={refresh}
                  columnIn={openDrawer ? "lg" : "md"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                <AdaptiveCard
                  title="Avance de Movilización"
                  icon="description"
                  config={{
                    percent:
                      parseFloat(dataChart.Movilizacion?.PorcentajeAvance) || 0,
                  }}
                  leftConfig={{
                    title: "Meta",
                    data: parseFloat(dataChart.Movilizacion?.Total) || 0,
                  }}
                  rightConfig={{
                    title: "Avance",
                    data: parseFloat(dataChart.Movilizacion?.Avance) || 0,
                  }}
                  fixed
                  loading={loadingCards}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                <AdaptiveCard
                  title="Avance de Pase de Lista"
                  icon="description"
                  config={{
                    percent:
                      parseFloat(dataChart.PaseLista?.PorcentajeAvance) || 0,
                  }}
                  leftConfig={{
                    title: "Meta",
                    data: parseFloat(dataChart.PaseLista?.Total) || 0,
                  }}
                  rightConfig={{
                    title: "Avance",
                    data: parseFloat(dataChart.PaseLista?.Avance) || 0,
                  }}
                  fixed
                  loading={loadingCards}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ChartMobilizationProgress
                body={bodyChart}
                setLoading={setLoading}
                openDrawer={openDrawer}
                ambitoUser={ambitoUser}
              />
            </Grid>
            <Grid item xs={12}>
              <ChartCumulativeProgress
                body={bodyChart}
                setLoading={setLoading}
                openDrawer={openDrawer}
                ambitoUser={ambitoUser}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={6}>
                  <TableAdavanceMobilization data={data} />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <ChartRollCallProgress
                    body={bodyChart}
                    setLoading={setLoading}
                    openDrawer={openDrawer}
                    ambitoUser={ambitoUser}
                  />
                </Grid2>
              </Grid2>
            </Grid>

            <Grid item xs={12}>
              <TableMobilization
                idAmbitoAgrupadorTable={idAmbitoAgrupadorTable}
                total={total}
                data={data}
                loading={loading}
                setLoading={setLoading}
                catView={catView}
                idFilter={idFilter}
                ambitoUser={ambitoUser}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <DrawerRelative
              anchor="right"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Filtrar"}
              icon="person_search"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                onSearch={handleSearchFilter}
                onClear={handleClearFilter}
                changeTypeView={changeTypeViewFilter}
                refresh={refresh}
                setLoading={setLoading}
                catView={catView}
                setIdFilter={setIdFilter}
                ambitoUser={ambitoUser}
              />
            </DrawerRelative>
          </Grid>
        </Grid>
        {!openDrawer && (
          <FloatingButton
            onClick={() => setOpenDrawer(true)}
            label="Mostrar Filtros"
            sx={{ display: { xs: "none", md: "block" } }}
          />
        )}
      </Container>
    </AdminLayout>
  );
};

export default DashboardMobilizationTerritorial;
