import Yup from "@utils/Yupi18n";
// import moment from "moment";
import {
  VOL_RC,
  VOL_RG,
  VOL_OBSERVER,
  VOL_FiL /*VIGENCIA_INE*/,
} from "@data/constants";
import { isEmptyOrInvalidString, isNullOrUndefined } from "@utils/validations";

const pattern = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/g;
const patternCurp = /^[A-Z]{4}\d{6}[H,M][A-Z]{5}[0-9,A-Z][0-9]$/g;
const patternZipCode =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const CANADA = 3;

const CompromisoAddSchema = Yup.object({
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .required()
    .label("Clave de Elector"),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
    .required("El código postal es obligatorio")
    .integer("El código postal debe ser un número entero")
    .positive("El código postal debe ser un número positivo")
    .test(
      "len",
      "El código postal debe tener 5 dígitos",
      (val) => val && val.toString().length === 5
    ),
  SeccionVota: Yup.number().required().min(1).max(3900).label("Sección Vota"),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  EsVoluntario: Yup.number().required(),
  idMedioContacto: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Medio de contacto"),
  idVoluntario: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Propuesta de Voluntariado"),
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  TieneAuto: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Auto"),
  TieneExperiencia: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Experiencia"),
  AceptaCuidarOtra: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Acepta cuidar otra casilla"),
  Segmentos: Yup.array().optional(),
  idPartido: Yup.number().optional().nullable(),
});

const CompromisoExtranjeroAddSchema = Yup.object({
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .required()
    .label("Clave de Elector"),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idPais: Yup.number()
    .required()
    .label("País")
    .typeError("Seleccione un País válido"),
  idEstadoExtranjero: Yup.number()
    .required()
    .label("Estado")
    .typeError("Seleccione un Estado válido"),
  State: Yup.string().required().min(3).label("Estado/Provincia donde vive"),
  Line1: Yup.string().required().min(3).label("Domicilio"),
  Line2: Yup.string().optional().min(3).label("Tipo de edificio").nullable(),
  ZipCode: Yup.string().when("idPais", {
    is: (val) => parseInt(val) === CANADA,
    then: Yup.string()
      .matches(patternZipCode, "Ingrese un código postal válido")
      .required()
      .label("Código Postal"),
    otherwise: Yup.string()
      .required()
      .test(
        "len",
        "El código postal debe tener 5 dígitos",
        (val) => val && val.toString().length === 5
      ),
  }),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
    .required("El código postal es obligatorio")
    .integer("El código postal debe ser un número entero")
    .positive("El código postal debe ser un número positivo")
    .test(
      "len",
      "El código postal debe tener 5 dígitos",
      (val) => val && val.toString().length === 5
    ),
  Celular: Yup.string()
    //.phone("Ingrese un número de teléfono válido")
    .matches(/^(?:\+1)?[2-9]\d{9}$/, "Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    //.phone("Ingrese un número de teléfono válido")
    .matches(/^(?:\+1)?[2-9]\d{9}$/, "Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    //.phone("Ingrese un número de teléfono válido")
    .matches(/^(?:\+1)?[2-9]\d{9}$/, "Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  //EsVoluntario: Yup.number().required(),
  /* idMedioContacto: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Medio de contacto"), */
  /* idVoluntario: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Propuesta de Voluntariado"), */
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  /*  TieneAuto: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Auto"), */
  /* TieneExperiencia: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Experiencia"), */
  /*  AceptaCuidarOtra: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Acepta cuidar otra casilla"), */
  //Segmentos: Yup.array().optional(),
  idPartido: Yup.number().optional().nullable(),
});

const CompromisoAddPublicSchema = Yup.object({
  isUsuario: Yup.number().required(),
  Password: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string()
      .required("Contraseña")
      .min(8, "Contraseña demasiado corta minimo 8 caracteres")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$.%_^&+=*!()])[A-Za-z0-9@#$.%_^&+.=*!()]{8,}$/,
        "La contraseña debe contener una letra Mayúscula,Número y Carecter Especial"
      ),
    otherwise: Yup.string().optional().nullable(true),
  }),
  Confirm: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Las contraseñas no  coinciden")
      .required("Campo Obligatorio"),
    otherwise: Yup.string().optional().nullable(true),
  }),
  PIN: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string().required().min(5).max(5),
    otherwise: Yup.string().optional().nullable(true),
  }),
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isNullOrUndefined(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isNullOrUndefined(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .required()
    .label("Clave de Elector"),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
    .required("El código postal es obligatorio")
    .integer("El código postal debe ser un número entero")
    .positive("El código postal debe ser un número positivo")
    .test(
      "len",
      "El código postal debe tener 5 dígitos",
      (val) => val && val.toString().length === 5
    ),
  SeccionVota: Yup.number().required().min(1).max(3900).label("Sección Vota"),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  EsVoluntario: Yup.number().required(),
  idMedioContacto: Yup.number()
    .when("isUsuario", {
      is: (val) => val === 1,
      then: Yup.number().optional(),
      otherwise: Yup.number().when("EsVoluntario", {
        is: 1,
        then: Yup.number().required(),
        otherwise: Yup.number().optional().nullable(true),
      }),
    })
    .label("Medio de contacto"),
  idVoluntario: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Propuesta de Voluntariado"),
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  TieneAuto: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Auto"),
  TieneExperiencia: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Experiencia"),
  AceptaCuidarOtra: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Acepta cuidar otra casilla"),
  Segmentos: Yup.array().optional(),
  idPartido: Yup.number().optional().nullable(),
});

const CompromisoEventSchema = Yup.object({
  isUsuario: Yup.number().required(),
  isMenor: Yup.number().required(),
  Password: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string()
      .required("Contraseña")
      .min(8, "Contraseña demasiado corta minimo 8 caracteres")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
        "La contraseña debe contener una letra Mayúscula,Número y Carecter Especial"
      ),
    otherwise: Yup.string().optional().nullable(true),
  }),
  Confirm: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Las contraseñas no  coinciden")
      .required("Campo Obligatorio"),
    otherwise: Yup.string().optional().nullable(true),
  }),
  PIN: Yup.string().when("isUsuario", {
    is: (val) => val === 1,
    then: Yup.string().required().min(5).max(5),
    otherwise: Yup.string().optional().nullable(true),
  }),
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isNullOrUndefined(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isNullOrUndefined(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string().when("isMenor", {
    is: (val) => val === 0,
    then: Yup.string()
      .min(18)
      .max(18)
      .matches(pattern, "Ingrese una Clave de Elector válida")
      .required()
      .label("Clave de Elector"),
    otherwise: Yup.string().optional().nullable(true),
  }),
  CURP: Yup.string().when("isMenor", {
    is: (val) => val === 1,
    then: Yup.string()
      .min(18)
      .max(18)
      .matches(patternCurp, "Ingrese una CURP válida")
      .required()
      .label("CURP"),
    otherwise: Yup.string().optional().nullable(true),
  }),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
    .required("El código postal es obligatorio")
    .integer("El código postal debe ser un número entero")
    .positive("El código postal debe ser un número positivo")
    .test(
      "len",
      "El código postal debe tener 5 dígitos",
      (val) => val && val.toString().length === 5
    ),
  SeccionVota: Yup.number().when("isMenor", {
    is: (val) => val === 0,
    then: Yup.number().required().min(1).max(3900).label("Sección Vota"),
    otherwise: Yup.number().optional().nullable(true),
  }),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  EsVoluntario: Yup.number().required(),
  idMedioContacto: Yup.number()
    .when("isUsuario", {
      is: (val) => val === 1,
      then: Yup.number().optional(),
      otherwise: Yup.number().when("EsVoluntario", {
        is: 1,
        then: Yup.number().required(),
        otherwise: Yup.number().optional().nullable(true),
      }),
    })
    .label("Medio de contacto"),
  idVoluntario: Yup.number()
    .when("EsVoluntario", {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Propuesta de Voluntariado"),
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  TieneAuto: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Auto"),
  TieneExperiencia: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Tiene Experiencia"),
  AceptaCuidarOtra: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER].includes(val),
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Acepta cuidar otra casilla"),
  Segmentos: Yup.array().optional(),
  idPartido: Yup.number().optional().nullable(),
});

const AmbitSchema = Yup.object({
  idTipoAmbito: Yup.number().optional().nullable(true),
  idAmbito: Yup.number().optional().nullable(true),
  AmbitoValor: Yup.string().optional().nullable(true),
  ResponsabilidadEtiqueta: Yup.string().optional().nullable(true),
  idRegion: Yup.number().optional().nullable(true),
  idDL: Yup.number().optional().nullable(true),
  idDF: Yup.number().optional().nullable(true),
  idMunicipio: Yup.number().optional().nullable(true),
  idMunicipio_dl: Yup.number().optional().nullable(true),
  idPoligono: Yup.number().optional().nullable(true),
  idSeccion: Yup.number().optional().nullable(true),
  idManzana: Yup.number().optional().nullable(true),
  idCasilla: Yup.number().optional().nullable(true),
});

const CompromisoFilterHistorySchema = Yup.object({
  celular: Yup.string().optional(),
  nombre: Yup.string().optional(),
  seccion: Yup.string().optional(),
  etiqueta: Yup.string().optional(),
  selectedOption: Yup.string().optional()
});

const CompromisoFilterHistorySchemaExt = Yup.object({
  idPais: Yup.number().optional(),
  nombre: Yup.string().optional(),
  celular: Yup.string().optional(),
});

const AttendanceFilterSchema = Yup.object({
  celular: Yup.string().optional(),
  nombre: Yup.string().optional(),
});

const AttendanceFoundFilterSchema = Yup.object({
  celular: Yup.string().optional(),
  nombre: Yup.string().optional(),
});

const AttendanceReportFilterSchema = Yup.object({
  idRegion: Yup.number().optional().label("Región"),
  idMunicipio: Yup.number().optional().label("Municipio"),
});

const updateInfoCompromisos = Yup.object({
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .optional()
    .label("Clave de Elector"),
  Nombre: Yup.string()
    .min(3)
    .optional()
    .label("Nombre"),
  Paterno: Yup.string()
    .optional()
    .min(2)
    .label("Apellido Paterno"),
  Materno: Yup.string()
    .optional()
    .min(2)
    .label("Apellido Materno"),
  SeccionVota: Yup.number()
    .optional()
    .min(1)
    .max(3900)
    .label("Sección Vota"),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
})

export {
  CompromisoAddSchema,
  AmbitSchema,
  CompromisoFilterHistorySchema,
  CompromisoFilterHistorySchemaExt,
  AttendanceFilterSchema,
  AttendanceFoundFilterSchema,
  AttendanceReportFilterSchema,
  CompromisoAddPublicSchema,
  CompromisoExtranjeroAddSchema,
  CompromisoEventSchema,
  updateInfoCompromisos,
};
