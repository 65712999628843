import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import CardLayout from "./CardLayout";
import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";
import { numberWithCommas } from "@utils/Utilities";

function LinearProgressWithLabel(props) {
  const variant = props.loading ? "indeterminate" : "determinate";
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant={variant} value={props.value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

const FilterTipoEleccion = ({ options, loading, handleChange, value }) => {
  return (
    <>
      <BasicSelect
        name="TipoEleccion"
        label="Tipo Elección"
        options={options.tipo_eleccion}
        value={value}
        onChange={handleChange}
        isLoading={loading}
        sx={{ width: "100%" }}
      />
    </>
  );
};

const MinutesCard = ({ filtered }) => {
  /*  const idAmbitoAgrupadorMap = {
    3: 3,
    5: 4,
    1: 5,
    2: 5,
    4: 5,
    6: 5,
  }; */

  const [loadingData, setLoadingData] = useState(false);
  const [catalogTypeElection, setCatTypeElection] = useState([]);
  const [valueTypeElection, setValueTypeElection] = useState("");
  const [labelElection, setLabelElection] = useState("");
  const [minutesTotal, setMinutesTotal] = useState({
    minutesReceived: 0,
    minutesTotal: 0,
    porcent: 0,
  });
  const [filteredElection, setFilteredElection] = useState({
    idTipoEleccion: 0,
    //ValorAmbito: 0,
    //idAmbitoAgrupador: 3,
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: "tipo_eleccion" }],
    putDefaultOption: false,
  });

  const getSummaryMinutes = async () => {
    try {
      setLoadingData((prevState) => !prevState);
      const result = await sije.getSummary({
        idTipoEleccion: filteredElection.idTipoEleccion,
        filtered: filtered,
      });

      const { response, message, results } = result;

      if (results) {
        setMinutesTotal({
          ...minutesTotal,
          minutesReceived: response.ActasRecibidas,
          minutesTotal: response.TotalActas,
          porcent: response.PActas,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingData((prevState) => !prevState);
    }
  };

  const handleChangeTypeElection = (e) => {
    const idTypeElection = e.target.value;
    const { label } = catalogTypeElection.tipo_eleccion.find(
      (item) => item.value === idTypeElection
    );
    setValueTypeElection(idTypeElection);
    setLabelElection(label);

    if (idTypeElection > 0) {
      //const idAmbitoAgrupador = idAmbitoAgrupadorMap[idTypeElection] || null;
      setFilteredElection((prevState) => ({
        ...prevState,
        idTipoEleccion: idTypeElection,
        //idAmbitoAgrupador,
      }));
    }
  };

  useEffect(() => {
    if (!load) {
      const { value, label } = catalogs.tipo_eleccion[0];
      setCatTypeElection(catalogs);
      setLabelElection(label);
      setValueTypeElection(value);
      setFilteredElection((prevState) => ({
        ...prevState,
        idTipoEleccion: value,
      }));
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (filteredElection.idTipoEleccion > 0) {
      getSummaryMinutes();
    }
    // eslint-disable-next-line
  }, [filteredElection, filtered]);

  return (
    <CardLayout
      title={"Actas Recibidas"}
      subtitle={labelElection}
      bgColor={blueGrey[50]}
      color={blueGrey[800]}
      icon={"description"}
      component={
        <FilterTipoEleccion
          options={catalogTypeElection}
          loading={load}
          value={valueTypeElection}
          handleChange={handleChangeTypeElection}
        />
      }
    >
      <div>
        {loadingData ? (
          <CircularProgress />
        ) : (
          <Typography variant="h4">
            {numberWithCommas(minutesTotal.minutesReceived)} de{" "}
            {numberWithCommas(minutesTotal.minutesTotal)}
          </Typography>
        )}
        <LinearProgressWithLabel
          value={minutesTotal.porcent}
          loading={loadingData}
        />
      </div>
    </CardLayout>
  );
};

export default MinutesCard;
