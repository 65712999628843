import { useState } from "react";

// Material UI
import { Grid, ListItem, ListItemText, ListItemButton, Divider } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicListDoble from "@components/Lists/BasicListDoble";
import AssignUser from "@components/Certificacion/Assign/AssignUserCertification";
import UserInfo from "@components/Certificacion/Assign/UserInfo";

// Utilidades
import { isTypePhone } from "@utils/validations";

// Servicios
import UserService from "@services/UserServices";

const Item = ({ item, index, selected, handleSelected }) => {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton selected={selected === index} onClick={() => handleSelected(item, index)}>
          <ListItemText primary={`${item.Nombre} ${item.Paterno}`} secondary={item.email} />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  );
};

const AssignCertification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Cargando datos...");
  const [userParams, setUserParams] = useState({});
  const [userData, setUserData] = useState([]);

  const handleClick = (data) => setUserParams(data);

  const handleClear = () => setUserParams({});

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={loadingMessage}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <ActionHeader title="Asignación de captura Día D" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <BasicListDoble
            API={UserService.getUsers}
            APIKeys={[
              { id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)", filter: "LIKE" },
              {
                id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                filter: "LIKE",
              },
            ]}
            header={{ title: "Usuarios a asignar", icon: "people" }}
            handleClick={handleClick}
            handleClear={handleClear}
            label={"Encuentra y elige al usuario para la acción a realizar"}
            labelNote={
              "Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
            }
            itemComponent={Item}
            finder
            inputValidation={{ input2: isTypePhone }}
            doubleSearch
            breaks={{ sm: 6 }}
            buttonsBreaks={{ sm: 3, md: 6 }}
            config={{ height: 250 }}
            clearData
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <AssignUser
            userData={userData}
            params={userParams}
            setParams={setUserParams}
            setLoading={setIsLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <UserInfo
            params={userParams}
            setParams={setUserParams}
            userData={userData}
            setUserData={setUserData}
            setLoading={setIsLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default AssignCertification;
