import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/Compromisos/Filter";
import Colors from "@components/Maps/InternalComponents/Cards/Colors";
import TableInfo from "@components/Maps/InternalComponents/Cards/TableInfo";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";

// Servicios y utilidades
import CompromisosService from "@services/MapServices/CompromisoServices";
import CatalogService from "@services/CatalogServices";
import { setVars } from "@utils/global";

// Estilos de mapas
import { style_original } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  manzana: [
    { title: "Manzana:", name: "Manzana", type: "text" },
    { title: "Prioridad:", name: "PrioridadLabel", type: "text" },
  ],
  seccion: [
    { title: "Seccion:", name: "seccion", type: "int" },
    { title: "DLOC:", name: "DLOC", type: "text" },
    { title: "DFED:", name: "DFED", type: "text" },
    { title: "Meta:", name: "MetaCompromisos", type: "int" },
    { title: "Av Esp:", name: "AvanceEsperado", type: "int" },
    { title: "Av Comp:", name: "AvanceCompromisos", type: "int" },
    { title: "Av Comp Únicos:", name: "AvanceCompromisosUnicos", type: "int" },
    { title: "% Comp Únicos:", name: "PorcentajeAvanceCompromisosUnicos", type: "percent" },
    { title: "LNOM:", name: "Lnom", type: "int" },
  ],
  dfed: [{ title: "DFED:", name: "dfed", type: "text" }],
};

const subElements = [
  { title: "Manzana:", name: "manzana", color: "#000", type: "int" },
  { title: "Seccion:", name: "Seccion", color: "#000", type: "int" },
  { title: "Localidad:", name: "Localidad", color: "#000", type: "int" },
  { title: "Prioridad:", name: "Prioridad", color: "#000", type: "int" },
];

const infoGeneralElements = [
  { title: "Meta:", name: "MetaCompromisos", color: "#000", type: "int" },
  { title: "Av Esp:", name: "AvanceEsperado", color: "#000", type: "int" },
  { title: "Av Comp:", name: "AvanceCompromisos", color: "#000", type: "int" },
  { title: "Av Comp Únicos:", name: "AvanceCompromisosUnicos", color: "#000", type: "int" },
  { title: "% Av Comp Únicos:", name: "PorcentajeAvanceCompromisosUnicos", color: "#000", type: "percent" },
  { title: "Total Man. P1:", name: "TotalP1", color: "#000", type: "int" },
  { title: "Total Man. P2:", name: "TotalP2", color: "#000", type: "int" },
  { title: "Total Man. P3:", name: "TotalP3", color: "#000", type: "int" },
];

const colorManzana = [
  { title: "Prioridad 1", color: "#B2EBF0" },
  { title: "Prioridad 2", color: "#FDF8C0" },
  { title: "Prioridad 3", color: "#D4D4D4" },
];

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({ regiones: [], municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState("Region");

  const [colorPercent, setColorPercent] = useState([]);

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = async (_filter, shpMap) => {
    let data_parameters = {};

    if (!shpMap)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filter,
      };
    else
      data_parameters = {
        shp: shpMap,
        page: 0,
        pageSize: 10,
        filtered: _filter,
      };

    setPolygons(null);
    setPolygonsBlocks(null);

    try {
      const result = await CompromisosService.getSections(data_parameters);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }

    let _filterModified = _filter.map((item) => {
      const regex = /cat_secciones/gi;
      let itemModified = item;
      itemModified.id = item.id.replace(regex, "tb");
      return itemModified;
    });
    let lastElement = _filterModified.slice(-1);
    _filterModified = lastElement;
    let shp = "region";
    setShpInfoGeneral("Region");
    const regex = /tb/gi;

    switch (_filterModified[0].id) {
      case "tb.idMunicipioReportes":
        shp = "municipio";
        setShpInfoGeneral("Municipio");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_municipios_reportes");
        break;
      case "tb.idPoligono":
        shp = "poligono";
        setShpInfoGeneral("Poligono");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_poligonos");
        break;
      case "tb.Seccion":
        shp = "seccion";
        setShpInfoGeneral("Seccion");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_secciones");
        break;
      case "tb.idRegion":
        shp = "region";
        setShpInfoGeneral("Region");
        _filterModified[0].id = _filterModified[0].id.replace(regex, "estad_region");
        break;
      default:
        shp = "region";
        setShpInfoGeneral("Region");
    }

    try {
      const params = { page: 0, pageSize: 10, filtered: _filterModified, shp };

      const result = await CompromisosService.getInformationGeneral(params);
      const { results, response, message } = result;

      if (results && response.data.length !== 0) {
        setValuesInfoGeneral(response.data[0]);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const loadCatalogs = useCallback(async () => {
    const catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id: "poligonos", getAll: false },
    ];

    try {
      const result = await CatalogService.getCatalogs(catalogsParams);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const RSRe = response.catalogs?.regiones;
        const RSMu = response.catalogs?.municipios_dl;
        const RSPo = response.catalogs?.poligonos;

        const todas = RSRe?.length === 1 ? RSRe : [{ value: 0, label: "TODAS" }].concat(RSRe);
        const todos = RSMu?.length === 1 ? RSMu : [{ value: 0, label: "TODOS" }].concat(RSMu);
        const todosRSPo = RSPo?.length === 1 ? RSPo : [{ value: 0, label: "TODOS" }].concat(RSPo);

        setCatalogs({
          regiones: RSRe ? todas : [],
          municipios: RSMu ? todos : [],
          poligonos: RSPo ? todosRSPo : [],
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  const loadColors = async () => {
    try {
      const result = await CompromisosService.getRangosColores({});
      const { results, response, message } = result;

      if (results) setColorPercent(response.data.local);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  useEffect(() => {
    setVars("filterSavedMap", {
      idRegion: "",
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });
    setTable(null);
    setCurrentFeature(null);
    getEmptyMap();
    loadCatalogs();
    loadColors();
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} loadingCatalogs={isLoadingCatalogs} handleFilter={handleFilterExect} />;
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={elements[polygons?.shp ?? "dfed"]}
          title={"INFORMACIÓN"}
          toolTip
        />
      )}
      {table && <TableInfo properties={table} elements={elements[polygons?.shp ?? "dfed"]} />}
    </>
  );

  const handleViewDetail = async (e) => {
    const data_parameters = {
      shp: "seccion",
      page: 0,
      pageSize: 10,
      filtered: [{ id: "cat_secciones.Seccion", filter: "=", value: e.Seccion }],
    };

    const data_parameters_secc = {
      color: 0,
      page: 0,
      pageSize: 10,
      filtered: [{ id: "cat_secciones.Seccion", filter: "=", value: e.Seccion }],
    };

    setPolygons(null);
    setPolygonsBlocks(null);

    try {
      const resultSection = await CompromisosService.getSections(data_parameters_secc);
      const resultBlocks = await CompromisosService.getBlocks(data_parameters);
      const { results, response, message } = resultSection;
      const { results: resultsB, response: responseB, message: messageB } = resultBlocks;

      if (results && resultsB) {
        setPolygonsBlocks(responseB.data);

        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(!results ? message : messageB);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const onEachFeature = (feature, layer, click, params) => {
    const style = { permanent: true, direction: "center" };
    const properties = feature.properties;
    const { subData } = params;

    if (properties[properties.shp] !== undefined) {
      if (properties.shp === "seccion" && subData?.shp === "manzana") return true;

      if (properties.shp === "manzana") {
        layer.bindTooltip(
          "M:" +
            properties.manzana +
            " L" +
            properties.Localidad +
            (properties.Prioridad ? " P" + properties.Prioridad : ""),
          style
        );
      }

      layer.setStyle(style_original);
      layer.on({ click: click });
    }
  };

  const highlightFeature = (e, params) => {
    const {
      geoJsonBlocksRef,
      resetHighlight,
      L,
      selectedFeature,
      setSelectedFeature,
      selectedSubFeature,
      setSelectedSubFeature,
      setSubFeature,
      setFeature,
    } = params;

    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);

    setCurrentFeature(properties);

    if (geoJsonBlocksRef.current) {
      if (selectedSubFeature) resetHighlight(selectedSubFeature);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original(layer.feature, true));

      setSubFeature(layer.feature);
      setSelectedSubFeature(e);
      return true;
    }

    if (!properties.seccion) resetHighlight(e);

    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties.seccion) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original(layer.feature, true));
    }
  };

  const cardComponent = {
    component: (
      <Colors
        elements={polygonsBlocks ? colorManzana : colorPercent}
        height={polygonsBlocks ? 24 : 20}
        spacing={polygonsBlocks ? 1 : 0.5}
      />
    ),
    title: "Colores",
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        subData={polygonsBlocks}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350 } }}
        cardComponent={cardComponent}
        cardInfo={{
          initialValues: {
            title: "INFORMACIÓN",
            elements: polygonsBlocks ? subElements : elements[polygons?.shp ?? "dfed"],
          },
          button: { handleClick: handleViewDetail, validShp: ["seccion"] },
        }}
        infoGeneral={{
          values: valuesInfoGeneral,
          initialValues: {
            title: "RESUMEN",
            elements: [
              { title: "center", name: shpInfoGeneral, color: "#000", type: "text" },
              ...infoGeneralElements,
            ],
          },
        }}
        order={["subData", "data"]}
        highlightFeature={highlightFeature}
        onEachFeature={onEachFeature}
        centerByFeatures
      />
    </AdminLayout>
  );
};

export default Maps;
