import { ProtectedRoute, PrivilegedRoute } from "@middlewares";
import Assignments from "@pages/DiaD/Assignments";


const DiaDRoutes = [
    {
        path: "/dia-d/asignacion-usuarios",
        element: (
            <ProtectedRoute>
                <Assignments />
            </ProtectedRoute>
        ),
    },
];

export default DiaDRoutes;
