import React, { useState, useEffect, useCallback } from "react";

import { Card, CardContent, Container, Grid, Modal, CardHeader, Button, Icon } from "@mui/material";
import Swal from "sweetalert2";
import ActionHeader from "@components/Containers/ActionHeader";
import middleware from "@middlewares/middleware";
import useWindowDimensions from "@hooks/useWindowDimensions";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicList from "@components/Lists/BasicList";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserMenuList from "@components/Menus/UserMenuList";
import MenusList from "@components/Menus/MenusList";
import { UserInfoInterface } from "@data/interfaces/UserInterfaces";
import UserInformation from "@components/Users/UserInformation";
import UserAccess from "@components/Users/UserAccess";
import UserListItem from "@components/Users/UserListItem";
import UserSecurity from "@components/Users/UserSecurity";
import UserForm from "@components/Users/UserForm";
import UserService from "@services/UserServices";
import MenuToolbar from "@components/Menus/MenuToolbar";
import AddNewMenuList from "@components/Menus/AddNewMenuList";
import CustomTabs from "@components/Tabs/CustomTabs";
import DrawerRelative from "@components/Containers/DrawerRelative";

const Users = () => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isAddMenusModalOpen, setIsAddMenusModalOpen] = useState(false);
  const [isLoadingAddMenu, setIsLoadingAddMenu] = useState(false);

  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState(UserInfoInterface);
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [userId, setUserId] = useState();

  const [tabValue, setTabValue] = useState("1");
  const [flagForm, setFlagForm] = useState(false);
  const [actionForm, setActionForm] = useState("");
  const [title, setTitle] = useState("Administración de usuarios");
  const [menuType, setMenuType] = useState('web');
  const [currentMenus, setCurrentMenus] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(true);

  const { height } = useWindowDimensions();
  const config = {
    title: "Listado de Usuarios",
    icon: "people",
    height: height * 0.55,
    endMessage: "No hay más registros para mostrar",
  };

  const handleClickItem = useCallback((info) => {
    setUserId(info.id);
    setUserInfo(info);
    setWebMenus([]);
    setAppMenus([]);

    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserService.getMenus({ idUsuario: info.id, Movil: 0 })
      .then((res) => {
        if (res.results) {
          setWebMenus(
            res.response.data.map((item) => {
              return {
                ...item,
                id: info.id,
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });

    UserService.getMenus({ idUsuario: info.id, Movil: 1 })
      .then((res) => {
        if (res.results) {
          setAppMenus(
            res.response.data.map((item) => {
              return {
                ...item,
                id: info.id,
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, []);

  const getUsers = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserService.getUsers({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.data.length > 0) {
            setUserList(res.response.data);
            setTotal(parseInt(res.response.total));
            handleClickItem(userId ? userInfo : res.response.data[0]);
          }
          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
        setHasMore(false);
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers({ page, pageSize, filtered });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tabValue) {
      switch (parseInt(tabValue)) {
        case 2: //WEB
          setCurrentMenus(webMenus);
          setMenuType('web');
          break;
        case 3: //APP
          setCurrentMenus(appMenus);
          setMenuType('app');
          break;
        default:
          setCurrentMenus([]);
          setMenuType('web');
          break;
      }
    }
  }, [tabValue, webMenus, appMenus]);

  const fetchHasMore = () => {
    UserService.getUsers({
      page: page + 1,
      pageSize: pageSize,
      filtered: filtered,
    })
      .then((res) => {
        if (res.results) {
          setUserList(userList.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage(page + 1);
  };

  const handleChangeUserInfo = (params) => {
    setHasMore(true);
    UserService.getUsers({ page: 0, pageSize: pageSize, filtered: filtered })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setUserList(res.response.data);
            setTotal(parseInt(res.response.total));
            handleClickItem(res.response.data[0]);
          }

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setFlagForm(false);
        setTitle("Administración de usuarios");
      });
  };

  const handleAddMenu = () => {
    setIsAddMenusModalOpen(true);
  };

  const submitMenus = (menus) => {
    setIsLoadingAddMenu(true);
    const params = {
      menus,
      idUsuario: userInfo.id,
    };
    UserService.insertUserMenus(params)
      .then((response) => {
        let icon = "warning";
        if (response.success && response.results) {
          icon = "success";
          setIsAddMenusModalOpen(false);
          handleClickItem(userInfo);
        }
        setIsLoadingAddMenu(false);
        Swal.fire({
          title: response.message,
          icon,
          customClass: {
            container: "modal-alert",
          },
        });
      })
      .catch((error) => {
        setIsLoadingAddMenu(false);
        Swal.fire({
          title: error.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      });
  };

  const evalInputSearch = (text) => {
    let out_text = text.trim();
    let type = 'text';
    // const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const phoneRegex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/

    if (phoneRegex.test(out_text)) {
      out_text = out_text.replace(/\D/g, '').slice(-10);
      type = 'phone';
    } else {
      out_text = text.replace(/\s+/g, '%');
    }

    return {
      type,
      text: out_text
    };
  }

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
    } else {
      const { type, text } = evalInputSearch(searchInput);
      if (type === 'phone') {
        filtro = [
          {
            id: `usuarios.Username`,
            filter: "=",
            value: `${text}`,
            inheritFilterType: null,
          },
        ];
      } else {
        filtro = [
          {
            id: `CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)`,
            filter: "LIKE",
            value: `%${text}%`,
            inheritFilterType: null,
          },
        ];
      }

    }
    setFiltered(filtro);
    setPage(0);
    getUsers({ page: 0, pageSize, filtered: filtro });
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader isCustom={true} title={title}>
            <Button 
              color={"primaryDark"}
              variant={"contained"}
              size={"medium"}
              onClick={() => setOpenDrawer((prev) => !prev)}
            >
              {openDrawer ? <Icon>chevron_left</Icon> : <Icon>chevron_right</Icon>}
            </Button>
          </ActionHeader>
          <Modal
            open={isAddMenusModalOpen}
            onClose={() => setIsAddMenusModalOpen(false)}
          >
            <Card
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "60%",
                maxHeight: "100%",
                overflowY: "auto",
                transform: "translate(-50%, -50%)",
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader title="Agregar menus" />
              <CardContent>
                <AddNewMenuList
                  currentMenus={currentMenus}
                  type={menuType}
                  submitMenus={submitMenus}
                  isLoadingAdd={isLoadingAddMenu}
                  handleCloseModal={() => setIsAddMenusModalOpen(false)}
                />
              </CardContent>
            </Card>
          </Modal>
          <LoadingForm
            isLoading={isloadigForm}
            success={issuccessForm}
            isOpen={openLinearProgress}
            setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            loadinMessage={"Cargando Datos..."}
          />
          {flagForm ? (
            <UserForm
              action={actionForm}
              data={userInfo}
              handleChangeUserInfo={handleChangeUserInfo}
            />
          ) : (
            <Grid container spacing={1}>
              <Grid xs={12} md={4} sm={12} lg={4} item>
                <DrawerRelative
                  anchor="left"
                  open={openDrawer}
                  setOpen={setOpenDrawer}
                  title={"Listado de Usuarios"}
                  icon="people"
                  className="card-primary"
                  sxPaper={{ pb: 0, borderRadius: '12px' }}
                  sx={{ mb: "0 !important", borderRadius: '12px !important' }}
                >
                  {
                    <BasicList
                      configParams={config}
                      Header={false}
                      data={userList}
                      fetchHasMore={fetchHasMore}
                      totalItems={total}
                      itemComponent={UserListItem}
                      hasMore={hasMore}
                      handleClickItem={handleClickItem}
                      finder={true}
                      search={handleSearch}
                    />
                  }
                </DrawerRelative>

              </Grid>
              <Grid xs={12} md={openDrawer ? 8 : 12} sm={12} lg={openDrawer ? 8 : 12} item>
                <Card sx={{ height: "100%" }} className="card-primary">
                  <CardContent sx={{ px: { xs: 0, sm: 2 } }}>
                    <CustomTabs
                      value={tabValue}
                      setValue={setTabValue}
                      color={"secondary"}
                      tabs={[
                        {
                          label: "Información",
                          value: "1",
                          icon: 'info',
                          iconPosition: 'top',
                          component: <UserInformation
                            data={userInfo}
                            setFlagForm={setFlagForm}
                            setActionForm={setActionForm}
                            height={height * 0.4}
                          />,
                        },
                        {
                          label: "Seguridad",
                          value: "4",
                          icon: 'lock',
                          iconPosition: 'top',
                          component: <UserSecurity
                            height={height * 0.6}
                            data={userInfo}
                            access="admin"
                          />,
                          display: middleware.checkMenuAction("Editar")
                        },
                        {
                          label: "Menús Web",
                          value: "2",
                          icon: 'admin_panel_settings',
                          iconPosition: 'top',
                          component: middleware.checkMenuAction("Editar") ? (
                            <UserMenuList
                              menus={webMenus}
                              type={"list-edit"}
                              maxHeight={height * 0.6}
                              toolbar={true}
                              ComponentToolbar={MenuToolbar}
                              configToolbar={{
                                title: `${userInfo.Nombre} ${userInfo.Paterno} ${userInfo.Materno}`,
                                icon: "add_circle",
                                btnAdd: true,
                                handleAdd: handleAddMenu,
                              }}
                              setIsOpen={setOpenLinearProgress}
                              setIsLoading={setIsLoadingForm}
                              setIsSuccess={setIsSuccessForm}
                              externalID={userInfo.id}
                              info={userInfo}
                              handleReloadList={handleClickItem}
                            />
                          ) : (
                            <MenusList menus={webMenus} type={"list"} maxHeight={height * 0.5}
                            />
                          ),
                        },
                        {
                          label: "Menús App",
                          value: "3",
                          icon: 'app_settings_alt',
                          iconPosition: 'top',
                          component: middleware.checkMenuAction("Editar") ? (
                            <UserMenuList
                              menus={appMenus}
                              type={"list-edit"}
                              maxHeight={height * 0.6}
                              toolbar={true}
                              ComponentToolbar={MenuToolbar}
                              configToolbar={{
                                title: `${userInfo.Nombre} ${userInfo.Paterno} ${userInfo.Materno}`,
                                icon: "add_circle",
                                btnAdd: true,
                                handleAdd: handleAddMenu,
                              }}
                              setIsOpen={setOpenLinearProgress}
                              setIsLoading={setIsLoadingForm}
                              setIsSuccess={setIsSuccessForm}
                              externalID={userInfo.id}
                              info={userInfo}
                              handleReloadList={handleClickItem}
                            />
                          ) : (
                            <MenusList
                              menus={appMenus}
                              type={"list"}
                              maxHeight={height * 0.5}
                            />
                          )
                        },
                        {
                          label: "Accesos",
                          value: "5",
                          icon: 'exit_to_app',
                          iconPosition: 'top',
                          component: <UserAccess data={userInfo} />
                        }
                      ]}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </AdminLayout>
    </>
  );
};

export default Users;
