import { PrivilegedRoute } from "@middlewares";
import AssignCertification from "@pages/Certificacion/AssignCertification";

const CertificacionRoutes = [
  {
    path: "/asignacion_diad_ruta",
    element: (
      <PrivilegedRoute>
        <AssignCertification />
      </PrivilegedRoute>
    ),
  },
];

export default CertificacionRoutes;
