import React, { useState, useEffect } from "react";

//MUI
import {
  Box,
  Button,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import Swal from "sweetalert2";
import FilterCollapse from "@components/Filters/FilterCollapse";
import { isTypePhone } from "@utils/validations";
import CatalogService from "@services/CatalogServices";

//catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const Filter = ({
  onChangeFilter,
  filterData = true,
  df = true,
  dl = true,
  region = true,
  municipio = false,
  municipioDL = true,
  poligono = true,
  seccion = true,
  casilla =false,
  propietario=false,
  tieneExperiencia = true,
  TieneAuto= true,
  AceptaOtra = true,
  filtroRepresentante=false,
  filtrRuta = false,
  refreash
}) => {

  // catalogo
  const catalogsParams = [
    { id: "regiones", getAll: false},
    { id: "municipios", getAll: false},
    { id: "municipios_dl", getAll: false},
    { id: "poligonos", getAll: false},
    { id: "secciones", getAll: false},
    { id: "calidad_representante", getAll: false},
    { id: "dl", getAll: false},
    { id: "df", getAll: false}
  ];

  //variables
  const animatedComponents = makeAnimated();
  const defaultOption = [{ value: 0, label: "TODOS" }];
  //States
  const [loading, setLoading] = useState([]);
  const [catResponsable] = useState([{value:0,label:"Todos"},{value:1,label:"SIN RESPONSABLES"},{value:2,label:"CON RESPONSABLES"},{value:3,label:"Multiples Asignaciones"}]);
  const [catRuta] = useState([{value:0,label:"Todos"},{value:1,label:"SIN RUTA"},{value:2,label:"CON RUTA"}]);
  const [catDFFilter, setCatDFilter] = useState([]);
  const [catDLFilter, setCatDLFilter] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipalityDL, setCatMunicipalityDL] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catPolygonFilter, setCatPolygonFilter] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [catCasillaFilter, setCatCasillaFilter] = useState([]);
  const [catPropietarioFilter, setCatPropietarioFilter] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    DF:0,
    DL:0,
    Region: 0,
    Municipio: 0,
    MunicipioDL:0,
    Poligono: [],
    Ruta:0,
    Seccion: 0,
    TieneAuto:false,
    TieneExperiencia:false,
    AceptaOtra:false,
    Propietario:0,
    Casilla:0,
    P1:0,
    P2:0,
    S1:0,
    S2:0
  });
  const [filter, setFilter] = useState({
    Celular: "",
    ClaveElector: "",
    NombreCompleto: ""
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsParams) {
      newObject[item.id] = [];
    }
    return newObject;
  });


  useEffect(() => {
    clearFilter();
    if (load === false) {
      setCatalogFiltered(catalogs);
      setCatDFilter(defaultOption.concat(catalogs.df));
      setCatDLFilter(defaultOption.concat(catalogs.dl));
      setCatRegionsFilter(defaultOption.concat(catalogs.regiones));
      setCatMunicipalityFilter(defaultOption.concat(catalogs.municipios));
      setCatMunicipalityDL(defaultOption.concat(catalogs.municipios_dl));
      setCatPolygonFilter(defaultOption.concat(catalogs.poligonos));
      setCatSectionFilter(defaultOption.concat(catalogs.secciones));
      setCatPropietarioFilter(defaultOption.concat(catalogs.calidad_representante));
    }
    // eslint-disable-next-line
  }, [load,refreash]);


  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      MunicipioDL:0,
      Seccion: 0,
      Ruta:0,
    });

  
    if (idRegion > 0) {
    
      setCatMunicipalityFilter(
        defaultOption.concat(
          catalogsFiltered.municipios.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatMunicipalityDL(
        defaultOption.concat(
          catalogsFiltered.municipios_dl.filter((item) => item.idRegion === idRegion)
        )
      );

/*       setCatPolygonFilter(
        catalogsFiltered.poligonos.filter((item) => item.idRegion === idRegion)
      ); */
    
      setCatSectionFilter(
        catalogsFiltered.secciones.filter((item) => item.idRegion === idRegion)
      );

    } else {
      setCatMunicipalityFilter(catalogsFiltered.municipios);
      setCatMunicipalityDL(catalogsFiltered.municipios_dl);
      /* setCatPolygonFilter(catalogsFiltered.poligonos); */
      setCatSectionFilter(catalogsFiltered.secciones);
    }

  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: 0,
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(
        catalogsFiltered.poligonos.filter((item) => item.idMunicipio === idMunicipality)
      );
      setCatSectionFilter(
        catalogsFiltered.secciones.filter((item) => item.idMunicipio === idMunicipality)
      );
    } else {
      setCatSectionFilter(catalogsFiltered.secciones);
      setCatPolygonFilter(catalogsFiltered.poligonos);
    }
  };

  const handleChangeMunicipalityDL = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      MunicipioDL: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(
        catalogsFiltered.poligonos.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
      setCatSectionFilter(
        catalogsFiltered.secciones.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
    } else {
      setCatSectionFilter(catalogsFiltered.secciones);
      setCatPolygonFilter(catalogsFiltered.poligonos);
    }
  };


  const handleChangeSection = (e) => {
    let newValue = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Seccion: newValue,
    });

    let catalogsParams = [
      {
        id: "casillas",
        filtered: [{ id: "cat_casillas.Seccion", filter: "=", value: newValue}],
      }
    ];

    setLoading(true);

    CatalogService.getCatalogs(catalogsParams)
    .then((res) => {
      if (res.success && res.results) {
        setCatCasillaFilter(res.response.catalogs.casillas ? res.response.catalogs.casillas : []);
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(() => setLoading(false));
  };

  const handleChangeDF = (e) => {
    let newValue = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      DF: newValue,
    });

    let catalogsParams = [
      {
        id: "municipios_dl",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDF", filter: "=", value: newValue}],
      },
      {
        id: "municipios",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDF", filter: "=", value: newValue}],
      },
      {
        id: "dl",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDF", filter: "=", value: newValue}],
      },
      {
        id: "secciones",
        filtered:newValue === 0 ? [] : [{ id: "cat_secciones.idDF", filter: "=", value: newValue}],
      },
      {
        id: "poligonos",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDF", filter: "=", value: newValue}],
      }
    ];

    setLoading(true);

    CatalogService.getCatalogs(catalogsParams)
    .then((res) => {
      if (res.success && res.results) {
        setCatMunicipalityDL(defaultOption.concat(res.response.catalogs.municipios_dl));
        setCatDLFilter(defaultOption.concat(res.response.catalogs.dl));
        setCatMunicipalityFilter(defaultOption.concat(res.response.catalogs.municipios));
        setCatPolygonFilter(defaultOption.concat(res.response.catalogs.poligonos));
        setCatSectionFilter(defaultOption.concat(res.response.catalogs.secciones));
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(() => setLoading(false));
  };

  const handleChangeDL = (e) => {
    let newValue = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      DL: newValue,
    });

    let catalogsParams = [
      {
        id: "municipios_dl",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDL", filter: "=", value: newValue}],
      },
      {
        id: "municipios",
        filtered: newValue === 0 ? [] :[{ id: "cat_secciones.idDL", filter: "=", value: newValue}],
      },
      {
        id: "df",
        filtered: newValue === 0 ? [] : [{ id: "cat_secciones.idDL", filter: "=", value: newValue}],
      },
      {
        id: "secciones",
        filtered:newValue === 0 ? [] : [{ id: "cat_secciones.idDL", filter: "=", value: newValue}],
      },
      {
        id: "poligonos",
        filtered:newValue === 0 ? [] : [{ id: "cat_secciones.idDL", filter: "=", value: newValue}],
      }
    ];

    setLoading(true);

    CatalogService.getCatalogs(catalogsParams)
    .then((res) => {
      if (res.success && res.results) {
        setCatMunicipalityDL(defaultOption.concat(res.response.catalogs.municipios_dl));
        setCatDFilter(defaultOption.concat(res.response.catalogs.df));
        setCatMunicipalityFilter(defaultOption.concat(res.response.catalogs.municipios));
        setCatPolygonFilter(defaultOption.concat(res.response.catalogs.poligonos));
        setCatSectionFilter(defaultOption.concat(res.response.catalogs.secciones));
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(() => setLoading(false));
  };

  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      DF:dataCatalog.DF,
      DL:dataCatalog.DL,
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      MunicipioDL:dataCatalog.MunicipioDL,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Ruta:dataCatalog.Ruta,
      Casilla:dataCatalog.Casilla,
      Propietario:dataCatalog.Propietario,
      Celular: filter.Celular,
      ClaveElector: filter.ClaveElector,
      NombreCompleto: filter.NombreCompleto,
      TieneAuto:dataCatalog.TieneAuto,
      TieneExperiencia:dataCatalog.TieneExperiencia,
      AceptaOtra:dataCatalog.AceptaOtra,
      P1:dataCatalog.P1,
      P2:dataCatalog.P2,
      S1:dataCatalog.S1,
      S2:dataCatalog.S2
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      DF:0,
      DL:0,
      Region: 0,
      Municipio: 0,
      MunicipioDL:0,
      Poligono: [],
      Seccion: 0,
      TieneAuto:false,
      TieneExperiencia:false,
      AceptaOtra:false,
      Casilla:0,
      Propietario:0,
      Celular: "",
      ClaveElector: "",
      NombreCompleto: ""
    };
    setCatRegionsFilter(defaultOption.concat(catalogsFiltered.regiones));
    setCatDFilter(defaultOption.concat(catalogsFiltered.df));
    setCatDLFilter(defaultOption.concat(catalogsFiltered.dl));
    setCatMunicipalityFilter(defaultOption.concat(catalogsFiltered.municipios));
    setCatMunicipalityDL(defaultOption.concat(catalogsFiltered.municipios_dl));
    setCatPolygonFilter(defaultOption.concat(catalogsFiltered.poligonos));
    setCatSectionFilter(defaultOption.concat(catalogsFiltered.secciones));
    setCatCasillaFilter([]);

    setDataCatalog({
      ...dataCatalog,
      DF:0,
      DL:0,
      Region: 0,
      Municipio: 0,
      MunicipioDL:0,
      Poligono: [],
      Seccion: 0,
      Ruta:0,
      TieneAuto:0,
      TieneExperiencia:0,
      AceptaOtra:0,
      Casilla:0,
      Propietario:0,
      P1:0,
      P2:0,
      S1:0,
      S2:0
    });

    /* 
    setData({
      ...data,
      filtered: [],
    }); */

    setFilter(newFilter);
    onChangeFilter(newFilter);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };



  return (
    <>
      <FilterCollapse expand={false} effect={refreash}>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          {df && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Distrito Federal"
                options={catDFFilter}
                name="DL"
                value={
                  catDFFilter.length === 2
                    ? catDFFilter[1].value
                    : dataCatalog.DF
                }
                onChange={handleChangeDF}
                disabled={catDFFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {dl && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Distrito Local"
                options={catDLFilter}
                name="DF"
                value={
                  catDLFilter.length === 2
                    ? catDLFilter[1].value
                    : dataCatalog.DL
                }
                onChange={handleChangeDL}
                disabled={catDLFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {region && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Región"
                options={catRegionsFilter}
                name="region"
                value={
                  catRegionsFilter.length === 2
                    ? catRegionsFilter[1].value
                    : dataCatalog.Region
                }
                onChange={handleChangeRegion}
                disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {municipio && (
            <Grid item xs={12} md={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                  catalogsFiltered.municipios.length === 1
                    ? catMunicipalityFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipality}
                disabled={catalogsFiltered.municipios.length === 1 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {municipioDL && (
            <Grid item xs={12} md={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityDL}
                name="municipio"
                value={
                  catalogsFiltered.municipios_dl.length === 1
                    ? catMunicipalityDL[1].value
                    : dataCatalog.MunicipioDL
                }
                onChange={handleChangeMunicipalityDL}
                disabled={catalogsFiltered.municipios_dl.length === 1 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
{/*           {poligono && (
            <Grid item xs={12} md={4}>
              <Select
                placeholder="Polígono"
                styles={selectStyles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={
                  catalogsFiltered.poligonos.length === 1
                    ? [catPolygonFilter[0]]
                    : selectedPolygons
                }
                onChange={handleChangePolygon}
                isMulti
                options={catPolygonFilter}
                isDisabled={catalogsFiltered.poligonos.length === 1 ? true : false}
              />
            </Grid>
          )} */}
         {
          filtrRuta && 
          <Grid item xs={12} md={4} sm={2}>
          <InputSelect
            label="Ruta"
            options={catRuta}
            name="Ruta"
            value={ dataCatalog.Ruta}
            onChange={(e)=> setDataCatalog({...dataCatalog,Ruta:e.target.value})}
            //disabled={catResponsable.length === 2 ? true : false}
            sx={{ width: "100%" }}
          />
        </Grid>
        }
          {seccion && (
            <Grid item xs={12} md={4}>
              <InputSelect
                label="Sección"
                name="Seccion"
                value={
                  catalogsFiltered.secciones.length === 1
                    ? catSectionFilter[0].value
                    : dataCatalog.Seccion
                }
                onChange={handleChangeSection}
                options={catSectionFilter}
                isDisabled={catalogsFiltered.secciones.length === 1 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {casilla && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Casilla"
                options={catCasillaFilter}
                name="Casilla"
                value={
                  catCasillaFilter.length === 2
                    ? catCasillaFilter[1].value
                    : dataCatalog.Casilla
                }
                onChange={(e)=> setDataCatalog({...dataCatalog,Casilla:e.target.value})}
                disabled={catCasillaFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
           {propietario && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Propietario"
                options={catPropietarioFilter}
                name="Propietario"
                value={
                  catPropietarioFilter.length === 2
                    ? catPropietarioFilter[1].value
                    : dataCatalog.Propietario
                }
                onChange={(e)=> setDataCatalog({...dataCatalog,Propietario:e.target.value})}
                disabled={catPropietarioFilter.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
        </Grid>
        {filterData && (
          <Grid
            container
            spacing={2}
            marginBottom={"1rem"}
            marginTop={"0.5rem"}
          >
            <Grid item xs={12} md={4}>
              <TextField
                label="Celular"
                size="small"
                name="Celular"
                value={filter.Celular}
                //onChange={hadleChangeTextField}
                onChange={(e) =>
                  hadleChangeTextField({
                    target: {
                      name: "Celular",
                      value: isTypePhone(e.target.value)
                        ? e.target.value
                        : filter.Celular,
                    },
                  })
                }
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Nombre"
                size="small"
                name="NombreCompleto"
                value={filter?.NombreCompleto }
                onChange={hadleChangeTextField}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Clave Elector"
                size="small"
                name="ClaveElector"
                value={filter?.ClaveElector}
                onChange={hadleChangeTextField}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        )}
        <FormGroup row>
          {
            tieneExperiencia &&
            <FormControlLabel
            control={<Checkbox />}
            value={dataCatalog.TieneExperiencia}
            onClick={(e)=>  setDataCatalog({...dataCatalog,TieneExperiencia:e.target.checked}) }
            label="¿Tiene Experiencia?"/>
          }
          {
            AceptaOtra &&
            <FormControlLabel
            control={<Checkbox />}
            value={dataCatalog.AceptaOtra}
            onClick={(e)=>  setDataCatalog({...dataCatalog,AceptaOtra:e.target.checked}) }
            label="¿Aceptaria cuidar otra casilla?"/>
          }
          {
            TieneAuto &&
            <FormControlLabel  control={<Checkbox />} 
            onClick={(e)=>  setDataCatalog({...dataCatalog,TieneAuto:e.target.checked}) }
            value={dataCatalog.TieneAuto}
            label="¿Tiene Auto" />
          }
          
        </FormGroup>

        {filtroRepresentante && (
          <Grid
            container
            spacing={2}
            marginBottom={"1rem"}
            marginTop={"0.5rem"}
          >
             <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Propietario 1"
                options={catResponsable}
                name="Propietario1"
                value={
                  catResponsable.length === 2
                    ? catResponsable[1].value
                    : dataCatalog.P1
                }
                onChange={(e)=> setDataCatalog({...dataCatalog,P1:e.target.value})}
                disabled={catResponsable.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Propietario 2"
                options={catResponsable}
                name="Propietario2"
                value={dataCatalog.P2}
                onChange={(e)=> setDataCatalog({...dataCatalog,P2:e.target.value})}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Suplente 1"
                options={catResponsable}
                name="Suplente1"
                value={dataCatalog.S1}
                onChange={(e)=> setDataCatalog({...dataCatalog,S1:e.target.value})}
                //disabled={catResponsable.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Suplente 2"
                options={catResponsable}
                name="Suplente2"
                value={ dataCatalog.S2}
                onChange={(e)=> setDataCatalog({...dataCatalog,S2:e.target.value})}
                //disabled={catResponsable.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        )}
 
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default Filter;
