import { useState, useEffect } from "react";

import Modal from "@components/Modal/Dataview";
import CustomTabs from "@components/Tabs/CustomTabs";
import EditingSchedules from "./EditingSchedules";
import TimeLineSchedule from "./TimeLineSchedule";

import Swal from "sweetalert2";
import mobilization from "@services/MovilizationServices";
import { Alert, Icon } from "@mui/material";
import { orange } from "@mui/material/colors";

const ModalSchedule = ({
  open = false,
  data,
  setOpen,
  handleChangeSchedules,
}) => {
  const [tabValue, setTabValue] = useState("0");
  const [disabledHistory, setDisabledHistory] = useState(true);
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [loadingList, setLoadingList] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);
  const [prevMovilization, setPrevMovilization] = useState([]);

  const getMobilizedByScheduleHist = async (body) => {
    setLoading(true);
    try {
      const {
        results,
        message,
        response: { data },
        success,
      } = await mobilization.getMobilizedByScheduleHist(body);

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setDataHistory(data);
        setDisabledHistory(data.length > 1 ? true : false);
      } else {
        setDataHistory([]);
        setDisabledHistory(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const resetMobilizedBySchedule = async ({ body }) => {
    setLoadingList(true);
    try {
      const res = await mobilization.resetMobilizedBySchedule(body);
      const { results, message } = res;
      if (results) {
        getMobilizedBySchedule(data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    }
  };

  const getMobilizedBySchedule = async (data) => {
    try {
      const { results, response, message, success } =
        await mobilization.getDetailMobilizedByHour(data);

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setDataMovilization(response.data);
        setPrevMovilization(response.data);
      } else {
        setDataMovilization([]);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoadingList(false);
    }
  };

  const handleEditMovilizados = (idUsuario, newValue) => {
    const updatedData = dataMovilization.map((row) =>
      row.idUsuario === idUsuario
        ? {
            ...row,
            movilizadosAnterior: prevMovilization.find(
              (prev) => prev.idUsuario === idUsuario
            ).movilizados,
            movilizados: newValue,
          }
        : row
    );

    setDataMovilization(updatedData);
  };

  const handleSaveData = () => {
    const combinedData = dataMovilization.map((row) => {
      const editedRow = dataMovilization.find(
        (editedRow) => editedRow.idUsuario === row.idUsuario
      );
      return editedRow || row;
    });

    const users = combinedData.map(
      ({ idUsuario, movilizados, movilizadosAnterior }) => ({
        idUsuario,
        movilizados: parseInt(movilizados),
        movilizadosAnterior: parseInt(movilizadosAnterior),
      })
    );

    setMovilization({ users });
  };

  const setMovilization = async ({ users }) => {
    setLoadingSave(true);
    try {
      const res = await mobilization.saveMobilizedBySchedule({
        ...data,
        Usuarios: users,
      });
      const { results, message } = res;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
          customClass: {
            container: "modal-alert",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            setOpen(false);
            handleChangeSchedules();
          }
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    if (open) {
      //getMobilizedBySchedule(data);
      resetMobilizedBySchedule({ body: data });
      getMobilizedByScheduleHist({
        Seccion: data.Seccion,
        Hora: data.Hora,
      });
    }
    // eslint-disable-next-line
  }, [data, open]);

  /* useEffect(() => {
    if (open) {
      getMobilizedByScheduleHist({
        Seccion: data.Seccion,
        Hora: data.Hora,
      });
    }
  }, [data]); */

  useEffect(() => {
    if (!open) setTabValue("0");
  }, [open]);

  return (
    <Modal open={open} headerTitle="Responsables" setOpen={setOpen}>
      {disabledHistory && (
        <Alert severity="info" color="warning" sx={{ mb: 2 }}>
          Tienes un total de {dataHistory.length} movimientos registrados en el
          historial de movilizaciones.
        </Alert>
      )}
      <CustomTabs
        value={tabValue}
        setValue={setTabValue}
        tabs={[
          {
            label: "Edición de movilizados",
            value: "0",
            component: (
              <EditingSchedules
                // open={open}
                // data={data}
                // setOpen={setOpen}
                // handleRefresh={handleRefresh}
                // handleChangeSchedules={handleChangeSchedules}
                dataMovilization={dataMovilization}
                loadingList={loadingList}
                loading={loadingSave}
                handleSaveData={handleSaveData}
                handleEditMovilizados={handleEditMovilizados}
              />
            ),
          },
          {
            icon: disabledHistory && (
              <Icon fontSize="small" sx={{ color: orange[600] }}>
                notifications_active
              </Icon>
            ),
            label: "Historial de ediciones",
            value: "1",
            display: disabledHistory,
            component: (
              <TimeLineSchedule data={dataHistory} loading={loading} />
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default ModalSchedule;
