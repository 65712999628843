import {
  Box,
  Icon,
  TableCell,
  TableRow,
  Typography,
  TableBody as TableBodyMui,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styleTextFieldNumber } from "../../../theme";

const TableBody = ({ data, dataFound, handleEditMovilizados }) => {
  return (
    <TableBodyMui>
      {data.length > 0 ? (
        data.map((row) => (
          <TableRow key={Math.random()}>
            <TableCell align="right">{row.Seccion}</TableCell>
            <TableCell>{row.NombreCompleto}</TableCell>
            <TableCell>{row.Responsabilidad}</TableCell>
            <TableCell align="center">
              <TextField
                size="small"
                disabled={row.idEstatus === 1 ? false : true}
                value={row.movilizados !== null ? row.movilizados : ""}
                onChange={(e) =>
                  handleEditMovilizados(row.idUsuario, e.target.value)
                }
                type="number"
                sx={{
                  ...styleTextFieldNumber,
                  width: "50%",
                  display: row.esPaseLista !== 1 ? "none" : "block",
                }}
                //row.movilzados === 1 ? true : false
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4}>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Icon sx={{ fontSize: "5rem", color: grey[500] }}>
                {dataFound ? "person_search" : "search_off"}
              </Icon>
              <Typography fontWeight={600} variant="h6" marginTop={1}>
                {dataFound
                  ? "Datos No Disponible"
                  : "No se econtraron resultados"}
              </Typography>
              <Typography variant="caption" marginTop={1}>
                {dataFound
                  ? " Utiliza el filtro a tu izquierda para realizar una búsqueda y obtener los resultados deseados."
                  : "Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales"}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBodyMui>
  );
};

export default TableBody;
