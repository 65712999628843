import { useEffect, useState } from "react";
import { useFormik } from "formik";

import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
  Icon,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";
import { CapturaInterface } from "@data/interfaces/SijeInterface";
import { CapturaPaseListaSchema } from "@data/schemas/SijeSchema";
import { isEmptyOrInvalidString } from "@utils/validations";
import moment from "moment";

const RollCallForm = ({
  title,
  handleSave,
  loading,
  itemUser,
  options,
  setItemUser,
}) => {
  const [catCasillas, setCatCasillas] = useState([]);
  const [loadCasillas, setLoadCasillas] = useState(false);

  const formik = useFormik({
    initialValues: CapturaInterface,
    validationSchema: CapturaPaseListaSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const handleChangeSection = async (idSection) => {
    if (idSection) {
      try {
        setLoadCasillas((prevState) => !prevState);
        const result = await sije.getCasillasRc({
          Seccion: String(idSection),
          idUsuario: itemUser.idUsuario,
        });
        const { response, results, message } = await result;
        if (results) {
          const casillas = response.data.map((item) => {
            return {
              label: item.NombreCasilla,
              value: item.idCasilla,
            };
          });
          setCatCasillas(casillas);
          formik.setFieldValue("casilla", casillas[0].value);
        } else {
          throw message;
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      } finally {
        setLoadCasillas((prevState) => !prevState);
      }
    }
  };

  useEffect(() => {
    formik.setValues({
      seccion: itemUser.SeccionVota ? itemUser.SeccionVota : "",
      municipio: itemUser.Municipio ? itemUser.Municipio : "",
      idUser: itemUser.idUsuario, //idUsuarioPropietario
      hora: moment().format("HH:mm"),
    });
    handleChangeSection(itemUser.SeccionVota);
    // eslint-disable-next-line
  }, [itemUser]);

  useEffect(() => {
    if (options.length === 0) {
      formik.setValues({
        seccion: "",
        municipio: "",
        casilla: "",
        idUser: "",
        hora: "",
      });
      setCatCasillas([]);
      setItemUser({});
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography textAlign={"center"} variant="body2" fontWeight={600}>
          {title}
        </Typography>
        <Box component={"form"} marginTop={2}>
          <TextField
            disabled
            label="Sección"
            name="seccion"
            value={formik.values.seccion}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <TextField
            disabled
            label="Municipio"
            name="municipio"
            value={formik.values.municipio}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <BasicSelect
            label="Casilla"
            name="casilla"
            options={catCasillas}
            value={formik.values.casilla}
            onChange={(e) => {
              formik.handleChange({
                target: {
                  name: "casilla",
                  value: e.target.value,
                },
              });
            }}
            isLoading={loadCasillas}
            sx={{ width: "100%", mb: 3 }}
          />

          <TextField
            label={"* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)"}
            name="hora"
            value={formik.values.hora}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.hora && !isEmptyOrInvalidString(formik.errors.hora)
            }
            helperText={
              formik.touched.hora && formik.errors.hora && formik.errors.hora
            }
          />
          <Stack
            direction={"row"}
            marginTop={4}
            spacing={2}
            justifyContent={"center"}
          >
            {/*  <Button
              variant="outlined"
              size="small"
              color="primaryDark"
              //onClick={handleClearForm}
              disabled={formik.values.seccion.length === 0}
            >
              Limpiar
            </Button> */}
            <Button
              variant="contained"
              onClick={formik.submitForm}
              color="primaryDark"
              disabled={formik.values.seccion.length === 0}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RollCallForm;
