import { useState } from "react";

// Material UI y estilos
import { Container, Button, ButtonGroup, Card, CardContent, Grid } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Calendario from "@components/Electoral/Calendario";
import ListEventos from "@components/Electoral/ListEventos";

const Capacitaciones = (props) => {
  const [tabButton, setTabButton] = useState(1);

  const handleCalendarioClick = (e) => setTabButton(e);

  const buttons = [
    {
      id: 1,
      button: (
        <Button key="Calendario" onClick={() => handleCalendarioClick(1)}>
          Calendario
        </Button>
      ),
      container: <Calendario />,
    },
    {
      id: 2,
      button: (
        <Button key="ListaEventos" onClick={() => handleCalendarioClick(2)}>
          Lista Eventos
        </Button>
      ),
      container: <ListEventos />,
    },
    /* {
      id: 3,
      button: (
        <Button key="ListadoAsistencia" onClick={() => handleCalendarioClick(3)}>
          Listado Asistencia
        </Button>
      ),
      container: <ListEventosAsistencia />,
      filter: (
        <FilterEvento
          vista={false}
          flagCategoria={false}
          responsable={true}
          checkResponsabilidades={true}
          onChangeFilter={(e) => {
            console.log(e);
          }}
        />
      ),
    }, */
  ];

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={"center"}>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <ButtonGroup color="secondary" aria-label="Medium-sized button group">
                    {buttons.map((item) => item.button)}
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                {buttons.map((item, index) => {
                  return (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={12}>
                        {tabButton === item.id && item.container}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Capacitaciones;
