import { Fragment } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Icon,
  MenuItem,
  Chip,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";

const PreguntasEventos = (props) => {
  const { isLoading, formik, type = "create" } = props;

  const isEdit = type === "edit";

  const handleAddQuestion = () => {
    const newQuestion = {
      ...(isEdit ? { idPregunta: 0 } : {}),
      Pregunta: "",
      Valor: 1,
      idTipoRespuesta: 1,
      Requerido: false,
      Respuestas: [],
    };
    formik.setValues({
      ...formik.values,
      tienePreguntas: true,
      preguntas: [...formik.values.preguntas, newQuestion],
    });
  };

  const handleQuestionChange = (index, fieldName, value) => {
    const updatedQuestions = [...formik.values.preguntas];
    updatedQuestions[index][fieldName] = value;
    formik.setValues({ ...formik.values, preguntas: updatedQuestions });
  };

  const renderRespuestasTextField = (idTipoRespuesta, index) => {
    const questions = formik.values.preguntas;

    if (idTipoRespuesta === 3) return <></>; // Respuesta abierta
    else if (idTipoRespuesta === 1 || idTipoRespuesta === 2) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={1.5}>
            {questions[index].Respuestas.map((respuesta, respIndex) => (
              <Grid item xs={12} key={respIndex}>
                <Grid container columnSpacing={2} sx={{ px: 2 }}>
                  <Grid item xs={12} sm={8} md={9} lg={9}>
                    <TextField
                      size="small"
                      label={`Respuesta ${respIndex + 1}`}
                      fullWidth
                      error={isErrorArray(index, "Respuesta", respIndex)}
                      helperText={getFormikRes(index, respIndex, "Respuesta")}
                      value={respuesta.Respuesta}
                      onChange={(e) => handleResponseChange(index, respIndex, "Respuesta", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2.6} md={2} lg={2}>
                    <FormControlLabel
                      sx={{ fontSize: "0.875rem" }}
                      value="top"
                      control={
                        <Checkbox
                          size="small"
                          checked={respuesta.Correcta}
                          onChange={(e) =>
                            handleResponseChange(index, respIndex, "Correcta", e.target.checked)
                          }
                        />
                      }
                      label="Correcta"
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={6} sm={1.4} md={1} lg={1} sx={{ textAlign: "end" }}>
                    <IconButton
                      aria-label="Eliminar respuesta"
                      onClick={() => handleDeleteResponse(index, respIndex)}
                      color="error"
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                </Grid>

                {respIndex !== questions[index]?.Respuestas?.length - 1 && (
                  <Divider sx={{ display: { xs: "block", sm: "none" }, my: { xs: 1, sm: 0 } }} />
                )}
              </Grid>
            ))}
          </Grid>

          <Button
            color="secondary"
            disabled={isLoading}
            aria-label="Agregar respuesta"
            onClick={() => handleAddResponse(index)}
            startIcon={<Icon>add</Icon>}
            sx={{ fontSize: 13, mt: 1 }}
          >
            Agregar Respuesta
          </Button>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const handleAddResponse = (index) => {
    const updatedQuestions = [...formik.values.preguntas];

    updatedQuestions[index].Respuestas.push({
      ...(isEdit ? { idRespuesta: 0 } : {}),
      Respuesta: "",
      Correcta: false,
    });
    formik.setValues({ ...formik.values, preguntas: updatedQuestions });
  };

  const handleResponseChange = (questionIndex, responseIndex, fieldName, value) => {
    const updatedQuestions = [...formik.values.preguntas];
    updatedQuestions[questionIndex].Respuestas[responseIndex][fieldName] = value;
    formik.setValues({ ...formik.values, preguntas: updatedQuestions });
  };

  const handleDeleteResponse = (questionIndex, responseIndex) => {
    const updatedQuestions = [...formik.values.preguntas];
    updatedQuestions[questionIndex].Respuestas.splice(responseIndex, 1);
    formik.setValues({ ...formik.values, preguntas: updatedQuestions });
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...formik.values.preguntas];
    updatedQuestions.splice(index, 1);

    if (updatedQuestions.length > 0) {
      formik.setValues({ ...formik.values, preguntas: updatedQuestions });
    } else {
      formik.setValues({ ...formik.values, tienePreguntas: false, preguntas: [] });
    }
  };

  const getFormik = (index, value, type = "errors") => {
    const preg = formik[type]?.preguntas;
    if (preg?.length > 0 && preg[index] && preg[index][value]) return preg[index][value];
    return "";
  };

  const isErrorArray = (index, value, indexRes) => {
    if (formik.touched?.preguntas !== undefined) {
      if (indexRes === undefined) return Boolean(getFormik(index, value));
      else return Boolean(getFormikRes(index, indexRes, value));
    }
    return false;
  };

  const getFormikRes = (index, indexRes, valueRes, type = "errors") => {
    const preg = formik[type]?.preguntas;

    if (
      preg?.length > 0 &&
      preg[index] &&
      preg[index]?.Respuestas &&
      preg[index]?.Respuestas[indexRes] &&
      preg[index]?.Respuestas[indexRes][valueRes]
    ) {
      return preg[index]?.Respuestas[indexRes][valueRes];
    }
    return "";
  };

  const getStriped = (index) => ({ background: index % 2 ? "#E4ECFA" : "white" });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>question_mark</Icon>} label="Preguntas Pase de Lista" />
      </Grid>

      {formik.values.preguntas.map((question, index) => (
        <Grid item xs={12} key={index} sx={getStriped(index)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                required
                size="small"
                label={`Pregunta ${index + 1}`}
                fullWidth
                value={question.Pregunta}
                onChange={(e) => handleQuestionChange(index, "Pregunta", e.target.value)}
                error={isErrorArray(index, "Pregunta")}
                helperText={getFormik(index, "Pregunta")}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                select
                size="small"
                label="Tipo Respuesta"
                fullWidth
                value={question.idTipoRespuesta}
                onChange={(e) => handleQuestionChange(index, "idTipoRespuesta", e.target.value)}
              >
                <MenuItem value={1}>Una Opción</MenuItem>
                <MenuItem value={2}>Opción Múltiple</MenuItem>
                <MenuItem value={3}>Abierta</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={8} sm={4} md={2} lg={2}>
              <FormControlLabel
                sx={{ fontSize: "0.875rem" }}
                value="top"
                control={
                  <Checkbox
                    size="small"
                    checked={question.Requerido}
                    onChange={(e) => handleQuestionChange(index, "Requerido", e.target.checked)}
                  />
                }
                label="Obligatorio"
                labelPlacement="end"
              />
            </Grid>

            <Grid item xs={4} sm={2} md={1} lg={1} sx={{ textAlign: "end" }}>
              <IconButton aria-label="Eliminar pregunta" onClick={() => handleDeleteQuestion(index)}>
                <Icon color="error">backspace</Icon>
              </IconButton>
            </Grid>

            {renderRespuestasTextField(question.idTipoRespuesta, index)}

            {!Array.isArray(getFormik(index, "Respuestas")) && Boolean(getFormik(index, "Respuestas")) && (
              <Grid item xs={12}>
                <Typography variant="caption" color={isErrorArray(index, "Respuestas") ? "error" : ""}>
                  {getFormik(index, "Respuestas")}
                </Typography>
              </Grid>
            )}

            {/* {index !== formik.values?.preguntas?.length - 1 && (
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ alignContent: "flex-start" }}>
        <Button
          color="info"
          variant="outlined"
          disabled={isLoading}
          aria-label="Agregar pregunta"
          onClick={handleAddQuestion}
          startIcon={<Icon>library_add</Icon>}
          sx={{ fontSize: 13 }}
        >
          Agregar Pregunta
        </Button>
      </Grid>
    </Grid>
  );
};

export default PreguntasEventos;
