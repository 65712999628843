import ApiExec from "@utils/ApiExec";

class CertificacionServices {
  createUsuarioDiad = (params) => {
    return ApiExec(params, "volunteers/create-usuario-diad", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsabilityRutaDiad = (params) => {
    return ApiExec(params, "volunteers/get-responsability-ruta-diad", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteResponsibility = (params) => {
    return ApiExec(params, "volunteers/delete-responsibility", "DELETE")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
};
}

const services = new CertificacionServices();
export default services;
