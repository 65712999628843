import { Icon, Paper, Stack, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

const LastUpdatedCard = ({ dateHour = "Sin información" }) => {
  return (
    <Paper
      sx={{
        margin: "0 auto",
        width: { xs: "100%", md: "27%" },
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: 1,
      }}
    >
      <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
        <Icon sx={{ color: blue[900] }}>update</Icon>
        <Typography variant="subtitle2" fontWeight={600} textAlign={"center"}>
          ÚLTIMA ACTUALIZACIÓN
        </Typography>
      </Stack>
      <Typography variant="body2" textAlign={"center"}>
        {dateHour}
      </Typography>
    </Paper>
  );
};

export default LastUpdatedCard;
