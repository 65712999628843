import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Catalog from "@services/CatalogServices";
import { isEmptyOrNullObject } from "@utils/validations";
import Swal from "sweetalert2";

export const useCatalogs = (props) => {
    const { catalogsOptions, putDefaultOption } = props;
    const defaultOption = { value: 0, label: "TODOS" };
    const [load, setLoad] = useState(true);
    const [errors, setErrors] = useState({})
    const [catalogs, setCatalogs] = useState(() => {
        let newObject = {};
        Object.keys(catalogsOptions).forEach((key) => {
            catalogsOptions[key.id] = [];
        });

        return newObject;
    });

    const loadCatalogs = useCallback(() => {
        const mapResults = (data) => {
            let newObject = {};
            if(Object.values(data).length > 0){
                if (putDefaultOption) {
                    Object.keys(data).forEach((key) => {
                        newObject[key] = [defaultOption].concat(data[key]);
                    });
                    return newObject;
                }
            }
           else{
                Object.values(catalogsOptions).forEach((key) => {
                    newObject[key.id] = [];
                });
                return newObject;
           }
           
            return data;
        }

        setLoad(true);
        Catalog.getCatalogs(catalogsOptions)
            .then((res) => {
                if (res.results) {
                    setCatalogs(mapResults({
                        ...catalogs,
                        ...res.response.catalogs
                    }));
                    setErrors(res.response.errors);

                } else {
                    Swal.fire({ title: res.message, icon: "warning" });
                }
            })
            .catch((error) =>{
                 Swal.fire({ title: error, icon: "warining" });
                 setCatalogs(catalogs);
                })
            .finally(() => setLoad(false));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (catalogsOptions.length > 0) {
            loadCatalogs()
        }
        // eslint-disable-next-line
    }, []);

    return { catalogs, load, errors }
}

useCatalogs.propTypes = {
    catalogsOptions: PropTypes.array.isRequired,
    putDefaultOption: PropTypes.bool.isRequired,
};

useCatalogs.defaultProps = {
    catalogsOptions: [],
    putDefaultOption: true
};
