import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Componentes
import BasicTable from "@components/Tables/BasicTable";
import FilterEvento from "@components/Electoral/FilterEvento";

// Utilidades
import { isEmptyOrNullObject } from "@utils/validations";

// Servicios
import ElectoralServices from "@services/ElectoralServices";

const ListEventos = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [total, setTotal] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [columns] = useState([
    { id: "FechaInicio", label: "Fecha Inicio" },
    { id: "FechaFin", label: "Fecha Fin" },
    { id: "Actividad", label: "Evento" },
    { id: "TipoActividad", label: "Tipo Evento" },
    { id: "TotalInvitados", label: "Total Asistencia" },
    { id: "TotalRCs", label: "Total RCS" },
    { id: "TotalRGs", label: "Total RGS" },
    { id: "TotalAbogados", label: "Total Abogado" },
    { id: "TotalOtrasResponsabilidades", label: "Total S/R" },
  ]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const getList = () => {
    setLoading(true);

    ElectoralServices.getEventosList(params)
      .then((res) => {
        if (res.results) {
          setEvents(
            res.response.data.map((item) => {
              return {
                ...item,
                title: item.Actividad,
                start: item.FechaInicio,
                end: item.FechaFin,
                color: "#1976d2",
                description: item.Descripcion,
                location: item.NombreLugar,
                //participants: ["Juan", "María"],
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      NombreCompleto: "cat_secciones.NombreCompleto",
      Celular: "cat_secciones.Celular",
      PerfilAmbito: "cat_secciones.idPerfil",
      CompromisosDirectos: "cat_secciones.CompromisosDirectos",
      CompromisosUnicosDirectos: "cat_secciones.CompromisosUnicosDirectos",
      UsuariosDirectos: "cat_secciones.UsuariosDirectos",
      CompromisosEnRed: "cat_secciones.CompromisosEnRed",
      CompromisosUnicosEnRed: "cat_secciones.CompromisosUnicosEnRed",
      UsuariosEnRed: "cat_secciones.UsuariosEnRed",
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  return (
    <>
      <FilterEvento onChangeFilter={(e) => console.log(e)} />
      <br />
      <BasicTable
        rows={events}
        hcolumns={columns}
        pageProp={tableData.page}
        pageSize={tableData.pageSize}
        total={total}
        handleChangePagination={handleChangePagination}
        isLoading={loading}
        stickyHeader={true}
        handleManualSort={handleRequestSort}
      />
    </>
  );
};
export default ListEventos;
