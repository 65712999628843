import { ApiExec, ApiExecPublic } from "@utils/ApiExec";

class EventService {
  getEventsList = (data) => {
    return ApiExec(data, "eventos/get-my-records", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setEvent = (data) => {
    return ApiExec(data, "eventos/set-event-capacitacion-electorala", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setEventsAsisencia = (data) => {
    return ApiExec(data, "eventos/pase-lista", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getEvents = (data) => {
    return ApiExecPublic(data, "app/users/public-get-event", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  validIne = (data) => {
    return ApiExecPublic(data, "app/users/public-get-user", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  registerEvent = (data) => {
    return ApiExecPublic(data, "app/users/public-set-guest-event", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  createUser = (params) => {
    return ApiExecPublic(params, "app/users/public-register-guest", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getEventsUsers = (params) => {
    return ApiExec(params, "eventos/get-event-users", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setAttendance = (params) => {
    return ApiExec(params, "eventos/set-attendance", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setEventCapacitacionElectoral = (params) => {
    return ApiExec(params, "eventos/set-event-capacitacion-electoral", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateEventCapacitacionElectoral = (params) => {
    return ApiExec(params, "eventos/update-event-capacitacion-electoral", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteEventCapacitacionElectoral = (params) => {
    return ApiExec(params, "eventos/delete-event-capacitacion-electoral", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getQuestions = (params) => {
    return ApiExec(params, "eventos/get-questions", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const events = new EventService();
export default events;
