import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/National/FilterTabs";
import Colors from "@components/Maps/InternalComponents/Cards/Colors";
import TableInfo from "@components/Maps/InternalComponents/Cards/TableInfo";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";

// Servicios y Utils
import CatalogService from "@services/CatalogServices";
import NationalService from "@services/MapServices/NationalServices";
import CompromisosService from "@services/MapServices/CompromisoServices";
import { setVars } from "@utils/global";

// Estilos de mapas
import { style_original_data, style_original_sub_data } from "@components/Maps/Auxiliars/ColorFeature";

const commonElements = [
  { title: "Meta Simpatizantes: ", name: "meta", type: "int" },
  { title: "Avance Simpatizantes: ", name: "avance", type: "int" },
  { title: "% Avance: ", name: "porcentaje", type: "percent" },
];

const elements = {
  manzana: [
    { title: "Manzana: ", name: "Manzana", type: "text" },
    { title: "Prioridad: ", name: "PrioridadLabel", type: "text" },
  ],
  seccion: [
    { title: "Sección: ", name: "seccion", type: "text" },
    { title: "Municipio: ", name: "Municipio", type: "text" },
    { title: "DLOC: ", name: "DLOC", type: "text" },
    { title: "DFED: ", name: "DFED", type: "text" },
  ],
  poligono: [{ title: "Polígono: ", name: "poligono", type: "text" }],
  municipio: [{ title: "Municipio: ", name: "municipio", type: "text" }, ...commonElements],
  region: [{ title: "Region: ", name: "region", type: "text" }],
  entidad: [{ title: "Entidad: ", name: "entidad", type: "text" }, ...commonElements],
  dfed: [{ title: "DFED: ", name: "dfed", type: "text" }, ...commonElements],
  dloc: [{ title: "DLOC: ", name: "dloc", type: "text" }, ...commonElements],
};

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);

  const [catalogs, setCatalogs] = useState({ entidades: [], municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState("entidad");

  const [colorPercent, setColorPercent] = useState([]);

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = async (filter = [], value = 0, shpVariable = false) => {
    if (filter.length === 0) return true;

    let shp = "entidad";
    const idEntidad = filter.find((item) => item.id === "tb.idEntidad").value;

    // Checar nivel de capa
    const comparative_word_shp = filter.slice(-1)[0]["id"].split(".")[1];

    switch (comparative_word_shp) {
      case "idMunicipio":
        shp = "seccion";
        break;
      case "idDF":
        shp = "seccion";
        break;
      case "idDL":
        shp = "seccion";
        break;
      case "Seccion":
        shp = "seccion";
        break;
      case "idEntidad":
        shp = "entidad";
        break;
      default:
        shp = "municipio";
    }

    if (idEntidad === 0 || !shpVariable) shp = "entidad";
    else if (value === 0) shp = "municipio";
    else if (value === 1) shp = "dfed";
    else if (value === 2) shp = "dloc";

    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { color: 1, page: 0, pageSize: 10, filtered: filter, shp, idEntidad };

      const result = await NationalService.getShapes(params);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const loadCatalogs = useCallback(async () => {
    const params = [
      { id: "entidades", getAll: false },
      { id: "estructura_electoral", getAll: false },
    ];

    try {
      const result = await CatalogService.getCatalogs(params);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const catalogs = response.catalogs;

        setCatalogs({
          entidades: catalogs.entidades ? [{ value: 0, label: "TODAS" }].concat(catalogs.entidades) : [],
          estructura_electoral: catalogs.estructura_electoral
            ? [{ value: 0, label: "TODAS" }].concat(catalogs.estructura_electoral)
            : [],
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  const loadColors = async () => {
    try {
      const result = await CompromisosService.getRangosColores({});
      const { results, response, message } = result;

      if (results) setColorPercent(response.data.nacional);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  useEffect(() => {
    setVars("filterSavedMap", {
      idEntidad: "",
      idMunicipio: "",
      idDF: "",
      idDL: "",
      Seccion: "",
    });
    setTable(null);
    setCurrentFeature(null);
    getEmptyMap();
    loadCatalogs();
    loadColors();
    // handleFilterExect([{ filter: "IN", id: "tb.idEntidad", value: 0 }]);
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter
        catalogs={catalogs}
        loadingCatalogs={isLoadingCatalogs}
        handleFilter={handleFilterExect}
        section
      />
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={elements[shpInfoGeneral]}
          title={"INFORMACIÓN"}
          toolTip
        />
      )}
      {table && <TableInfo properties={table} elements={elements[shpInfoGeneral]} />}
    </>
  );

  const handleClickViewDetail = async (e) => {
    //mandar a la capa inferior con el parametro solicitado
    let shp = "entidad";
    const idEntidad = e.idEntidad;
    let filter = [];
    switch (e.shp) {
      case "entidad":
        shp = "municipio";
        filter.push({ filter: "IN", id: "tb.idEntidad", value: idEntidad });
        break;
      case "municipio":
      default:
        shp = "seccion";
        filter.push({ filter: "IN", id: "tb.idEntidad", value: idEntidad });
        filter.push({ filter: "IN", id: "tb.idMunicipio", value: e.idMunicipio });
        break;
    }

    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { color: 1, page: 0, pageSize: 10, filtered: filter, shp, idEntidad };

      const result = await NationalService.getShapes(params);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);
    if (!properties[properties.shp]) resetHighlight(e);

    setCurrentFeature(properties);
    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties[properties.shp]) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_data(layer.feature, true));
    }
  };

  const cardComponent = {
    component: <Colors elements={colorPercent} height={20} spacing={0.5} />,
    title: "Colores",
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350, lg: 400 } }}
        cardInfo={{
          initialValues: { title: "INFORMACIÓN", elements: elements[shpInfoGeneral] },
          button: { handleClick: handleClickViewDetail, invalidShp: ["seccion"] },
        }}
        cardComponent={colorPercent?.length ? cardComponent : null}
        highlightFeature={highlightFeature}
        centerByFeatures
        styleProp={{
          data: style_original_data,
          subData: style_original_sub_data,
          onEachFeature: style_original_data,
          highlightFeature: style_original_data,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
