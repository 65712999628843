import { PrivilegedRoute } from "@middlewares";

import AsignacionFolioBingo from "@pages/Activation/BingoFolio";
import FolioUpdate from "@pages/Activation/BingoCorrection";
import EstadisticasBingo from "@pages/Activation/EstadisticasBingo"
import Bingo from "@pages/Activation/Bingo";
import Movilizacion from "@pages/Movilizacion/CompromisosMovilizacion"
import BingoCapturistList from "@pages/Activation/BingoCapturistList";
import CompromisoSinFolio from "@pages/Activation/CompromisosSinFolio"

const BingoRoutes = [
    {
        path: "/asignacion-folio-bingo",
        element: (
          <PrivilegedRoute>
            <AsignacionFolioBingo />
          </PrivilegedRoute>
        ),
      },
      {
        path: "/correccion-folio-bingo",
        element: (
          <PrivilegedRoute>
            <FolioUpdate />
          </PrivilegedRoute>
        ),
      },
      {
        path: "/estadisticas-folios-bingo",
        element: (
          <PrivilegedRoute>
            <EstadisticasBingo />
          </PrivilegedRoute>
        ),
      },
      {
        path: "/captura-folio-bingo",
        element: (
          <PrivilegedRoute>
            <Bingo />
          </PrivilegedRoute>
        ),
      },
      {
        path: '/captura-bingo',
        element: <PrivilegedRoute><Movilizacion /></PrivilegedRoute>
      },
      {
        path: '/consulta-usuario',
        element: <PrivilegedRoute><BingoCapturistList /></PrivilegedRoute>
      },
      {
        path: '/compromisos-sin-folio',
        element: <PrivilegedRoute><CompromisoSinFolio /></PrivilegedRoute>
      }
];

export default BingoRoutes;