import { useEffect, useState } from "react";

import { Chip, Container, Icon } from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import Filter from "@components/Activation/DashboardMobilization/Filter";
import RefreshButton from "@components/Button/RefreshButton";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import ChartBingo from "@components/Activation/DashboardMobilization/ChartBingo";
import ChartMobilization from "@components/Activation/DashboardMobilization/ChartMobilization";
import LastUpdatedCard from "./LastUpdatedCard";
import TableMobilization from "@components/Activation/DashboardMobilization/TableMobilization";

import { numberWithCommas } from "@utils/Utilities";

import {
  COLUMNS_DASH_MOBILIZATION,
  PROGRESS_KEYS,
  CATALOG_GROUP,
} from "@data/constants/Mobilization";
import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";
import { setDiynamicColumns } from "@utils/Utilities";
import { handleGroupToTable } from "@data/constants/Mobilization";

const DashboardMobilization = () => {
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState({
    isLoadigForm: false,
    isSuccessForm: false,
    openLinearProgress: false,
  });
  const [refresh, setRefresh] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] = useState("");
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: 2,
  });
  const [bodyChart, setBodyChart] = useState({
    filtered: [],
    idAmbitoAgrupador: 2,
  });
  const [catGroupTable] = useState(CATALOG_GROUP);
  const [catGroupTableFilter, setCatGroupTableFilter] = useState(CATALOG_GROUP);
  const [dateHour, setDateHour] = useState("");

  const getMovilization = async (data) => {
    setLoading({ ...loading, isLoadigForm: true, openLinearProgress: true });
    try {
      const res = await movilizacion.getMobilizedListByView(data);
      const { results, response, message } = res;
      if (results) {
        handleMobilizationData({ data: response.data });
        setTotal(response.total);
        setDateHour(response.fechaActualizo);
        setRefresh(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading({
        ...loading,
        isLoadigForm: false,
        openLinearProgress: false,
      });
    }
  };

  const handleMobilizationData = ({ data }) => {
    const columns = setDiynamicColumns({
      data: data[0],
      columns: COLUMNS_DASH_MOBILIZATION,
    });
    setColumnsDinamics(columns);

    const newDataMovilization = data.map((item) => {
      const updatedItem = { ...item };

      updatedItem.Efectividad = formatEfectividad(item.Efectividad);
      updatedItem.LNOM = numberWithCommas(item.LNOM);
      updatedItem.Meta = numberWithCommas(item.Meta);
      updatedItem.SIAN = numberWithCommas(item.SIAN);
      updatedItem.AvanceBingo = numberWithCommas(item.AvanceBingo);
      updatedItem.AvanceCompromiso = numberWithCommas(item.AvanceCompromiso);
      updatedItem.AvanceCiudadano = numberWithCommas(item.AvanceCiudadano);
      updatedItem.AvanceTotal = numberWithCommas(item.AvanceTotal);
      PROGRESS_KEYS.forEach((key) => {
        updatedItem[key] = (
          <CircularProgressPorcent size={42} fontSize={10} value={item[key]} />
        );
      });

      return updatedItem;
    });

    setDataMovilization(newDataMovilization);
  };

  const formatEfectividad = (efectividad) => {
    const rango = parseInt(efectividad);

    const color =
      rango === 100
        ? "success"
        : rango >= 50
        ? "primary"
        : rango >= 0
        ? "warning"
        : "error";

    const icon =
      rango === 100
        ? "done"
        : rango > 0
        ? "keyboard_double_arrow_up"
        : "keyboard_double_arrow_down";

    return (
      <Chip
        label={rango.toFixed(2)}
        icon={<Icon>{icon}</Icon>}
        color={color}
        size="small"
      />
    );
  };

  const handleSearchFilter = ({ filtereds }) => {
    setData((prevState) => ({ ...prevState, filtered: filtereds }));
    setBodyChart((prevState) => ({ ...prevState, filtered: filtereds }));
    if (filtereds.length > 0) {
      setCatGroupTableFilter(
        handleGroupToTable({ group: filtereds, catalog: catGroupTableFilter })
      );
    }
  };

  const handleClearFilter = () => {
    if (data.filtered.length > 0 || data.idAmbitoAgrupador !== 2) {
      setData((prevState) => ({
        ...prevState,
        filtered: [],
        idAmbitoAgrupador: 2,
      }));
      setBodyChart((prevState) => ({
        ...prevState,
        filtered: [],
        idAmbitoAgrupador: 2,
      }));
      setCatGroupTableFilter(catGroupTable);
      setIdAmbitoAgrupadorTable("");
    }
  };

  const handleChangeAmbitoTable = (e) => {
    const idAmbito = e.target.value;
    setData((prevState) => ({ ...prevState, idAmbitoAgrupador: idAmbito }));
    setIdAmbitoAgrupadorTable(idAmbito);
  };

  const handleRefresh = () => {
    setData({
      page: 0,
      pageSize: 10,
      filtered: [],
      sorted: [],
      idAmbitoAgrupador: 2,
    });
    setCatGroupTableFilter(catGroupTable);
    setIdAmbitoAgrupadorTable("");
    setRefresh(true);
  };

  useEffect(() => {
    getMovilization(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading.isLoadigForm}
        success={loading.isSuccessForm}
        isOpen={loading.openLinearProgress}
        setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
      />
      <Container maxWidth={false}>
        <ActionHeader title="Dashboard Movilización" />
        <LastUpdatedCard dateHour={dateHour} />
        <Filter
          catGroupTable={CATALOG_GROUP}
          onSearch={handleSearchFilter}
          onClear={handleClearFilter}
          setIdAmbitoAgrupador={setData}
          setIdAmbitoAgrupadorChart={setBodyChart}
          setIdAmbitoAgrupadorTable={setIdAmbitoAgrupadorTable}
          setCatGroupTableFilter={setCatGroupTableFilter}
          refresh={refresh}
          setLoading={setLoading}
          setData={setData}
        />
        <ChartBingo body={bodyChart} setLoading={setLoading} />
        <ChartMobilization body={bodyChart} setLoading={setLoading} />
        <TableMobilization
          catGroupTableFilter={catGroupTableFilter}
          idAmbitoAgrupadorTable={idAmbitoAgrupadorTable}
          dataMovilization={dataMovilization}
          columnsDinamics={columnsDinamics}
          total={total}
          data={data}
          loading={loading}
          setData={setData}
          handleChangeAmbitoTable={handleChangeAmbitoTable}
        />
        <RefreshButton handleRefresh={handleRefresh} />
      </Container>
    </AdminLayout>
  );
};

export default DashboardMobilization;
