import { useState } from "react";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";
import { QRCode } from "react-qrcode-logo";
import Swal from "sweetalert2";

// Material UI
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Icon,
  Stack,
  Divider,
  Tooltip,
} from "@mui/material";

// Importa la imagen predeterminada si no hay una imagen disponible
import defaultImage from "@assets/img/eventosImg.jpg";

const CardEvent = ({ post, handleAction = () => {}, setType = () => {}, handleDelete = () => {} }) => {
  // Verifica si hay una imagen disponible
  const hasImage = post.imagen !== undefined && post.imagen !== null;

  const handleQR = (values) => {
    Swal.fire({
      title: "QR del Evento",
      html: '<div id="my-swal-container"></div>',
      showConfirmButton: false,
      customClass: { container: "modal-alert" },
      showCloseButton: true,
      didOpen: () => {
        const container = document.getElementById("my-swal-container");
        const root = createRoot(container);
        root.render(<GetQR UUID={values.UUID} />);
      },
    });
  };

  return (
    <Card elevation={3} sx={{ borderRadius: "16px", padding: 0 }}>
      <IconButton
        aria-label="close"
        onClick={() => handleAction(false)}
        sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
      >
        <Icon>close</Icon>
      </IconButton>

      <CardMedia
        component="img"
        height="200"
        image={hasImage ? post.imagen : defaultImage}
        alt={post.Actividad}
      />

      <CardContent>
        <Grid container spacing={2} textAlign={"center"}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography gutterBottom variant="h5" component="div">
              {post.Actividad}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {post.description && (
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Icon>notes</Icon>
                <Typography variant="body1">{post.description}</Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {post.FechaInicio && (
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Icon>today</Icon>
                <Typography variant="h6">{post.FechaInicio}</Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {post.NombreLugar && (
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Icon>location_on</Icon>
                <Typography variant="h6">{post.NombreLugar}</Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary">
                <strong>Creado:</strong> {post.FechaCreo}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Tooltip title="Ver QR del evento" disableInteractive arrow>
                <IconButton size="small" aria-label="edit" color="primary" onClick={() => handleQR(post)}>
                  <Icon>qr_code_scanner</Icon>
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar evento" disableInteractive arrow>
                <IconButton size="small" aria-label="edit" color="error" onClick={() => handleDelete(post)}>
                  <Icon>delete</Icon>
                </IconButton>
              </Tooltip>

              <Tooltip title="Editar evento" disableInteractive arrow>
                <IconButton size="small" aria-label="edit" color="success" onClick={() => setType(3)}>
                  <Icon>edit</Icon>
                </IconButton>
              </Tooltip>

              <IconButton size="small" aria-label="shares">
                <Icon>share</Icon>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const GetQR = ({ UUID }) => {
  const [qrSize, setQrSize] = useState(200);

  const increaseSize = () => {
    if (qrSize < 400) setQrSize(qrSize + 50);
  };

  const decreaseSize = () => {
    if (qrSize > 200) setQrSize(qrSize - 50)
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <QRCode value={UUID} size={qrSize} />
      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
        <IconButton onClick={decreaseSize}>
          <Icon>remove</Icon>
        </IconButton>
        <IconButton onClick={increaseSize}>
          <Icon>add</Icon>
        </IconButton>
      </Box>
    </Box>
  );
};

CardEvent.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default CardEvent;
