import Yup from "@utils/Yupi18n";

export const EventosSchema = Yup.object({
  imgURL: Yup.string().optional(),
  imgMovilURL: Yup.string().optional(),
  files: Yup.array().min(1, "Ingresa al menos 1 imagen").required().label("Imagenes del evento"),

  idPartido: Yup.number().required(),
  idAmbito: Yup.number().required(),
  AmbitoValor: Yup.number().required(),

  idTipoActividad: Yup.number().required(),
  Actividad: Yup.string().min(5).required("Nombre del evento es requerido"),
  Descripcion: Yup.string().min(5).required(),
  FechaInicio: Yup.date().required().label("Fecha de inicio del evento"),
  FechaFin: Yup.date()
    .required()
    .label("Fecha de fin del evento")
    .when("FechaInicio", (startDate, schema) => {
      if (startDate instanceof Date) {
        return schema.min(startDate, "La fecha de fin debe ser posterior a la fecha de inicio");
      }
      return schema;
    }),

  idModalidad: Yup.number().required(),
  NombreLugar: Yup.string()
    .min(5)
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Nombre del Lugar"),
  Calle: Yup.string()
    .min(2)
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    }),
  NumExt: Yup.string()
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Num Ext"),
  NumInt: Yup.string().optional().nullable().label("Num Int"),
  Colonia: Yup.string().when("idModalidad", {
    is: (val) => val === 1 || val === 3,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().optional(),
  }),
  idMunicipio: Yup.number().when("idModalidad", {
    is: (val) => val === 1 || val === 3,
    then: Yup.number().positive("Elige un municipio").required(),
    otherwise: Yup.number().nullable().optional(),
  }),
  LigaVirtual: Yup.string()
    .when("idModalidad", {
      is: (val) => val === 2 || val === 3,
      then: Yup.string().required("La Liga del Evento es requerida"),
      otherwise: Yup.string().nullable().optional(),
    })
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, "El link no es válido")
    .min(10)
    .label("Liga del Evento"),

  esConcurrente: Yup.bool().required(),
  diasConcurrentes: Yup.array().when("esConcurrente", {
    is: true,
    then: Yup.array().min(1, "Debe seleccionar al menos un día").required(),
    otherwise: Yup.array().optional(),
  }),

  tienePreguntas: Yup.bool().required(),
  preguntas: Yup.array().when("tienePreguntas", {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          Pregunta: Yup.string()
            .min(5, "La pregunta debe tener al menos 5 caracteres")
            .required("La pregunta es requerida"),
          Valor: Yup.number().required(),
          idTipoRespuesta: Yup.number().oneOf([1, 2, 3]).required(),
          Requerido: Yup.boolean().required(),
          Respuestas: Yup.array().when("idTipoRespuesta", {
            is: (val) => val === 1 || val === 2,
            then: Yup.array()
              .min(2, "La pregunta debe tener al menos 2 respuestas")
              .of(
                Yup.object().shape({
                  Respuesta: Yup.string()
                    .min(3, "la respuesta debe tener al menos 3 caracteres")
                    .required("La respuesta es requerida"),
                  Correcta: Yup.boolean().required(),
                })
              )
              .test("at-least-one-correct", "Al menos una respuesta debe ser correcta", (value) =>
                value.some((respuesta) => respuesta.Correcta === true)
              )
              .required(),
            otherwise: Yup.array().notRequired(),
          }),
        })
      )
      .min(1, "Debe agregar al menos una pregunta")
      .required(),
    otherwise: Yup.array().optional(),
  }),

  TienePreregistro: Yup.number().required(),
  FechaFinPreregistro: Yup.date()
    .when(["esConcurrente", "TienePreregistro"], (esConcurrente, TienePreregistro, schema) => {
      if (TienePreregistro === 1 && !esConcurrente) {
        return schema
          .required()
          .max(
            Yup.ref("FechaFin"),
            "La fecha de fin del preregistro no puede ser posterior a la fecha de fin del evento"
          );
      } else return schema.optional().nullable();
    })
    .label("Fecha de fin preregistro del evento"),

  /* // No se usan en front
  isPrivado: Yup.boolean().required(),
  idModoEvento: Yup.number().required(),
  TieneAsistenciaQR: Yup.boolean().required(),
  shortUrlAsistencia: Yup.boolean().required(),
  textoPreregistro: Yup.boolean().required(),
  LinkPreregistro: Yup.boolean().required(),
  */
});

export const EventosEditSchema = Yup.object({
  idEvento: Yup.number().required(),
  idEstatus: Yup.number().required(),

  imgURL: Yup.string().optional(),
  imgMovilURL: Yup.string().optional(),
  files: Yup.array().optional().label("Imagenes del evento"),

  idPartido: Yup.number().required(),
  idAmbito: Yup.number().required(),
  AmbitoValor: Yup.number().required(),

  idTipoActividad: Yup.number().required(),
  Actividad: Yup.string().min(5).required("Nombre del evento es requerido"),
  Descripcion: Yup.string().min(5).required(),
  FechaInicio: Yup.date().required().label("Fecha de inicio del evento"),
  FechaFin: Yup.date()
    .required()
    .label("Fecha de fin del evento")
    .when("FechaInicio", (startDate, schema) => {
      if (startDate instanceof Date) {
        return schema.min(startDate, "La fecha de fin debe ser posterior a la fecha de inicio");
      }
      return schema;
    }),

  idModalidad: Yup.number().required(),
  NombreLugar: Yup.string()
    .min(5)
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Nombre del Lugar"),
  Calle: Yup.string()
    .min(2)
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    }),
  NumExt: Yup.string()
    .when("idModalidad", {
      is: (val) => val === 1 || val === 3,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Num Ext"),
  NumInt: Yup.string().optional().nullable().label("Num Int"),
  Colonia: Yup.string().when("idModalidad", {
    is: (val) => val === 1 || val === 3,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().optional(),
  }),
  idMunicipio: Yup.number().when("idModalidad", {
    is: (val) => val === 1 || val === 3,
    then: Yup.number().positive("Elige un municipio").required(),
    otherwise: Yup.number().nullable().optional(),
  }),
  LigaVirtual: Yup.string()
    .when("idModalidad", {
      is: (val) => val === 2 || val === 3,
      then: Yup.string().required("La Liga del Evento es requerida"),
      otherwise: Yup.string().nullable().optional(),
    })
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, "El link no es válido")
    .min(10)
    .label("Liga del Evento"),

  tienePreguntas: Yup.bool().required(),
  preguntas: Yup.array().when("tienePreguntas", {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          idPregunta: Yup.number().required(),
          Pregunta: Yup.string()
            .min(5, "La pregunta debe tener al menos 5 caracteres")
            .required("La pregunta es requerida"),
          Valor: Yup.number().required(),
          idTipoRespuesta: Yup.number().oneOf([1, 2, 3]).required(),
          Requerido: Yup.boolean().required(),
          Respuestas: Yup.array().when("idTipoRespuesta", {
            is: (val) => val === 1 || val === 2,
            then: Yup.array()
              .min(2, "La pregunta debe tener al menos 2 respuestas")
              .of(
                Yup.object().shape({
                  idRespuesta: Yup.number().required(),
                  Respuesta: Yup.string()
                    .min(3, "la respuesta debe tener al menos 3 caracteres")
                    .required("La respuesta es requerida"),
                  Correcta: Yup.boolean().required(),
                })
              )
              .test("at-least-one-correct", "Al menos una respuesta debe ser correcta", (value) =>
                value.some((respuesta) => respuesta.Correcta === true)
              )
              .required(),
            otherwise: Yup.array().notRequired(),
          }),
        })
      )
      .min(1, "Debe agregar al menos una pregunta")
      .required(),
    otherwise: Yup.array().optional(),
  }),

  TienePreregistro: Yup.number().required(),
  FechaFinPreregistro: Yup.date()
    .when(["TienePreregistro"], (TienePreregistro, schema) => {
      if (TienePreregistro === 1) {
        return schema
          .required()
          .max(
            Yup.ref("FechaFin"),
            "La fecha de fin del preregistro no puede ser posterior a la fecha de fin del evento"
          );
      } else return schema.optional().nullable();
    })
    .label("Fecha de fin preregistro del evento"),
});

export const EventosFilterSchema = Yup.object({
  actividad: Yup.string().optional(),
});

export const EventosInvitadosFilterSchema = Yup.object({
  celular: Yup.string().optional(),
});
