import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Swal from "sweetalert2";
import moment from "moment";

// Material UI
import { Dialog, LinearProgress } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Componentes
import EventForm from "@components/Electoral/EventForm";
import CardEvent from "@components/Electoral/CardEvent";

// Servicios
import ElectoralServices from "@services/ElectoralServices";
import EventsServices from "@services/EventsServices";

const localizer = momentLocalizer(moment);

const CustomAgendaEvent = ({ event }) => {
  return (
    <div>
      <div>
        <strong>Evento:</strong> {event.title}
      </div>
      <div>
        <strong>Descripción:</strong> {event.description}
      </div>
    </div>
  );
};

const Calendario = (props) => {
  const [events, setEvents] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [params /* setParams */] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });
  const [, /* total */ setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [type, setType] = useState(1);
  const [newEvent, setNewEvent] = useState({});
  const [event, setEvent] = useState({});

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const getList = async () => {
    if (isloading) return true;
    setIsLoading(true);
    try {
      const result = await ElectoralServices.getEventosList(params);
      const { results, response, message } = result;

      if (results) {
        setEvents(
          response.data.map((item) => {
            return {
              ...item,
              title: item.Actividad,
              start: item.FechaInicio,
              end: item.FechaFin,
              color: "#1976d2",
              description: item.Descripcion,
              location: item.NombreLugar,
              //participants: ["Juan", "María"],
            };
          })
        );
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventClick = (event) => {
    // Handle event click, e.g., show details or edit/delete
    setType(2);
    setOpenDialog(true);
    setEvent(event);
  };

  const handleSelectSlot = ({ start, end }) => {
    setNewEvent({ start, end });
    setType(1);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewEvent({});
  };

  const handleDeleteEvent = async (data) => {
    try {
      setIsLoading(true);

      const result = await EventsServices.deleteEventCapacitacionElectoral({ idEvento: data.id });
      const { success, results, message } = result;

      if (success && results) {
        getList();
        setOpenDialog(false);
        Swal.fire({ title: message, icon: "success", customClass: { container: "modal-alert" } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const questionDelete = async (data) => {
    Swal.fire({
      title: "Eliminar Evento",
      text: `¿Estas seguro de querer eliminar el evento: ${data.Actividad}?`,
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      focusDeny: true,
      denyButtonText: "Eliminar evento",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: { container: "modal-alert" },
    }).then((res) => {
      if (res.isDenied) handleDeleteEvent(data);
    });
  };

  return (
    <div>
      {isloading && <LinearProgress color="secondary" />}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, marginTop: 20 }}
        selectable
        onSelectEvent={handleEventClick}
        onSelectSlot={handleSelectSlot}
        messages={{
          today: "Hoy",
          previous: "Anterior",
          next: "Siguiente",
          month: "Mes",
          week: "Semana",
          day: "Día",
          agenda: "Agenda",
          date: "Fecha",
          time: "Hora",
          event: "Evento",
          allDay: "Todo el día",
          showMore: (total) => `+${total} más`,
        }}
        eventPropGetter={(event, start, end, isSelected) => ({
          style: {
            backgroundColor: event.color,
          },
        })}
        components={{
          agenda: {
            event: CustomAgendaEvent,
            description: "Hola",
          },
        }}
      />
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth={"lg"}
        PaperProps={{
          style: { backgroundColor: "transparent", borderRadius: "16px" },
        }}
        disableEscapeKeyDown
      >
        {type === 1 && (
          <EventForm fechaCalendario={newEvent} handleAction={setOpenDialog} getEvents={getList} />
        )}

        {type === 2 && (
          <CardEvent
            post={event}
            handleAction={setOpenDialog}
            setType={setType}
            getEvents={getList}
            handleDelete={questionDelete}
          />
        )}

        {type === 3 && (
          <EventForm
            type="edit"
            data={event}
            handleAction={setOpenDialog}
            setType={setType}
            getEvents={getList}
            handleDelete={questionDelete}
          />
        )}
      </Dialog>
    </div>
  );
};
export default Calendario;
