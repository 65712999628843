import { useState } from "react";
import Swal from "sweetalert2";

// Material UI
import { Button, Chip, Container, Icon, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { orange } from "@mui/material/colors";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Activation/Capture/Filter";
import ListMobilization from "@components/Activation/Capture/ListMobilization";
import StatisticalCard from "@components/Activation/Capture/StatisticalCard";
// import ListPendingNotReported from "@components/Activation/Capture/ListPendingNotReported";
import ListReported from "@components/Activation/Capture/ListReported";
import DrawerRelative from "@components/Containers/DrawerRelative";
import CustomTabs from "@components/Tabs/CustomTabs";


// Servicios y middleware
import MovilizationServices from "@services/MovilizationServices";
import middleware from "@middlewares/middleware";

const MovilizationCapture = () => {
  const baseData = {
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  };

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);
  const [users, setUsers] = useState([]);
  const [paramsFilter, setParamsFilter] = useState({
    seccion: "",
    hora: "",
  });
  const [dataFound, setDataFound] = useState(true);
  const [selectTab, setSelectTab] = useState(middleware.checkMenuAction("Agregar") ? 0 : 1);
  const [dataNotReports, setDataNotReports] = useState(baseData);
  const [dataReports, setDataReports] = useState(baseData);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const onChangeFilter = async (params) => {
    //const { hora, seccion } = params;

    /*  setParamsFilter({
      ...paramsFilter,
      Seccion: seccion,
      Hora: hora,
    }); */

    handleSearch(params);
    if (selectTab === 0) handleGetDetailMobilizedByHour(params);
  };

  const handleGetDetailMobilizedByHour = async (params) => {
    const { hora, seccion } = params;
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      const { results, response, message, success } = await MovilizationServices.getDetailMobilizedByHour({
        Hora: hora,
        Seccion: seccion,
      });

      if (!results && !success) throw new Error(message);

      if (results && success) {
        const transFormedData = response.data.map((item) => ({
          ...item,
          NombreCompleto:
            item.esPaseLista === 1 ? (
              item.NombreCompleto.toUpperCase()
            ) : (
              <Tooltip title="NO TIENE PASE DE LISTA">
                <Chip
                  size="small"
                  icon={
                    <Icon fontSize="small" sx={{ color: "#e65100 !important" }}>
                      error
                    </Icon>
                  }
                  label={item.NombreCompleto.toUpperCase()}
                  variant="contained"
                  sx={{
                    color: orange[900],
                    backgroundColor: orange[100],
                  }}
                />
              </Tooltip>
            ),
        }));

        setDataMovilization(transFormedData);
        //setDataFound(response.data.length > 0);
      } else {
        setDataMovilization([]);
        setDataFound(results);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleSearch = (filterValue) => {
    const filters = [
      { id: "idMunicipioReportes", key: "municipio" },
      { id: "idPoligono", key: "poligono" },
      { id: "Seccion", key: "seccion" },
    ];

    const filteredFirst = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => {
        const { id, key } = filter;
        return { id: id, filter: "=", value: filterValue[key] };
      });

    if (selectTab === 2) {
      const { hora, MovilizacionReportada } = filterValue;

      filters.push(
        { id: "TienePaseLista", key: "TienePaseLista" },
        {
          id: "MovilizacionReportada",
          key: "MovilizacionReportada",
          filter: MovilizacionReportada === "SI" ? ">" : "=",
          value: 0,
        }
      );

      if (hora && MovilizacionReportada !== 0) {
        filters.push({
          id: hora,
          key: "hora",
          filter: MovilizacionReportada === "SI" ? "IS NOT" : "IS",
          value: null,
        });
      }
    }

    const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0 || filterValue[filter.key].length)
      .map((val) => {
        const { id, key, filter, value } = val;
        const valueL = filterValue[key];

        return {
          id: id,
          filter: filter ?? "=",
          value: value !== undefined ? value : valueL,
        };
      });

    setDataReports((prev) => ({ ...prev, filtered }));
    setDataNotReports((prev) => ({ ...prev, filtered: filteredFirst }));
  };

  const onClearFilter = () => {
    setDataMovilization([]);
    setDataFound(true);
    setDataNotReports(baseData);
    setDataReports(baseData);
  };

  const setMovilization = async ({ users }) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const res = await MovilizationServices.saveMobilizedBySchedule({
        Seccion: paramsFilter.seccion,
        Hora: paramsFilter.hora,
        Usuarios: users,
      });
      const { results, message } = res;
      if (results) {
        Swal.fire({ title: message, icon: "success" });
        onChangeFilter(paramsFilter);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setRefresh(true);
    }
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando Datos..."}
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Container maxWidth={false}>
        <ActionHeader title={"Captura de movilización"} isCustom={true}>
          <Button
            onClick={() => setOpenDrawer(!openDrawer)}
            aria-label="open Drawer"
            endIcon={openDrawer ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
          >
            <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
          </Button>
        </ActionHeader>
        {/* <StatisticalCard
          params={dataNotReports.filtered}
          refresh={refresh}
          setRefresh={setRefresh}
        /> */}
        <Grid2
          container
          spacing={openDrawer ? 2 : 0}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid2 xs={12} md={3} lg={3}>
            <StatisticalCard params={dataNotReports.filtered} refresh={refresh} setRefresh={setRefresh} />
            <DrawerRelative
              anchor="left"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Filtros"}
              icon="filter_list"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                tab={selectTab}
                setParamsFilter={setParamsFilter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </DrawerRelative>
          </Grid2>
          <Grid2 xs={12} md={openDrawer ? 9 : 12}>
            <CustomTabs
              value={selectTab}
              setValue={setSelectTab}
              useNumbers
              tabs={[
                {
                  value: 0,
                  icon: "edit",
                  label: "CAPTURA DE MOVILIZACION",
                  display: middleware.checkMenuAction("Agregar"),
                  component: (
                    <ListMobilization
                      data={dataMovilization}
                      users={users}
                      paramsFilter={paramsFilter}
                      dataFound={dataFound}
                      setUsers={setUsers}
                      setMovilization={setMovilization}
                      getData={onChangeFilter}
                    />
                  ),
                },
                // {
                //   value: 1,
                //   icon: "pending_actions", // pending
                //   label: "Pendientes por reportar",
                //   component: (
                //     <ListPendingNotReported dataParams={dataNotReports} setData={setDataNotReports} />
                //   ),
                // },
                {
                  value: 2,
                  icon: "how_to_reg",
                  label: "REPORTE",
                  component: <ListReported dataParams={dataReports} />,
                },
              ]}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default MovilizationCapture;
