import { ApiExec, ApiExecPublic } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
class UserService {
  /**
   * *OBLIGATORIO* @param page Número de página actual
   * *OBLIGATORIO* @param pageSize Tamaño del bloque de respuesta
   * @returns Una listado de elementos de tamaño pageSize
   */
  getUsers = (params) => {
    return ApiExec(params, "users/get", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getUser = (params) => {
    return ApiExec(params, "/support/get-users", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getDevices = (Device) => {
    const device = DEBUG ? Device : encryptVars(Device);
    return ApiExec({}, `users/get-devices/${device}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  confirmarCodigo = (params) => {
    return ApiExecPublic(params, "app/users/code-validate", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * *OBLIGATORIO* @param id id de usuario
   * @returns Objeto con los datos del usuario
   */
  /*   getUser = (id) => {
    return ApiExec({}, `users/find/${id}`, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  }; */

  setUser = (params) => {
    return ApiExec(params, `users/create`, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updateUser = (params) => {
    return ApiExec(params, `users/update`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updateProfile = (params) => {
    return ApiExec(params, "users/update-user-profile", "PUT")
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  updatePassword = (params) => {
    return ApiExec(params, `users/update-password`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updatePIN = (params) => {
    return ApiExec(params, `users/update-pin`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updateStatus = (params) => {
    return ApiExec(params, `users/update-status`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updatePhoneNumber = (params) => {
    return ApiExec(params, "api")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * *OBLIGATORIO* @param page Número de página actual
   * *OBLIGATORIO* @param pageSize Tamaño del bloque de respuesta
   * *OBLIGATORIO* @param idUsuario id del usuario
   * @returns Una listado de elementos de tamaño pageSize
   */
  getAccessLogs = (params) => {
    return ApiExec(params, `users/get-access`, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getMenus = (params) => {
    return ApiExec(params, `users/get-menus`, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} changeAccess - Función para actualizar los privilegios de un menú
   * @param {Object} params - Parámetros para el llamado de la api
   * @param {string} token - Token de acceso para el llamado de la api
   * @returns {Object}
   */
  changeAccess = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} id - id del menú a modificar
     * @property {string} colum - Opción de ["Ver","Agregar","Editar","Eliminar","Exportar","Visible"]
     * @property {number} value - bit de acceso para la columna [0,1]
     * @property {number} userId - id del usuario al que le pertenece el menú
     */
    return ApiExec(params, "users/update-menu", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} deleteMenu - Función para eliminar un menú del usuario
   * @param {Object} params - Parámetros para el llamado de la api
   * @param {string} token - Token de acceso para el llamado de la api
   * @returns {Object}
   */
  deleteMenu = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} menuId - id del menú a modificar
     * @property {number} userId - id del usuario al que le pertenece el menú
     */
    return ApiExec(params, "users/delete-menu", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} insertUserMenu - Función para insertar menus al usuario
   * @param {Object} params - Parámetros para el llamado de la api
   * @param {string} token - Token de acceso para el llamado de la api
   * @returns {Object}
   */
  insertUserMenus = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} menus - menus a insertar, debe ser un arreglo de objetos con estrucura:
     * {
     *   menuId: *valor*,
        see: *valor*,
        create: *valor*,
        edit: *valor*,
        delete: *valor*,
        export: *valor*,
        visible: *valor*
     * }
     * @property {number} userId - id del usuario al que le insertarán los menus
     */
    return ApiExec(params, "users/insert-menus", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getMyUsers = (params) => {
    return ApiExec(params, "users/get-my-users", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getResponsibles = (params) => {
    return ApiExec(params, "users/get-responsibles", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDataUserOwner = (params) => {
    return ApiExecPublic(params, "app/users/get-data-user-owner", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createUser = (params) => {
    return ApiExecPublic(params, "app/users/public-register-invito", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  codeValidate = (params) => {
    return ApiExecPublic(params, "app/users/code-validate", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getAvanceCompromisos = (params) => {
    return ApiExec(params, "users/get-stats-compromisos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  messageChange = (params) => {
    return ApiExecPublic(params, "app/users/recovery-passwd", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setRecoveryPasswd = (params) => {
    return ApiExecPublic(params, "app/users/set-recovery-passwd", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  saveSupportRequest = (params) => {
    return ApiExecPublic(params, "app/support/save-support-request", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  validateRequest = (params) => {
    return ApiExecPublic(params, "app/support/validate-request", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  deleteDevice = (params) => {
    return ApiExec(params, "users/delete-device", "DELETE")
    .then(function (res) {
      return res;
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  };

  getResponsibilities = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `users/get-responsibilities/${_id}`, "GET")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
};

}

const user = new UserService();
export default user;
