import { PrivilegedRoute } from "@middlewares";

/* import Estructura from "@pages/Social/Structure"; */
import StructureTabs from "@pages/Social/StructureTabs";
import SocialNumerals from "@pages/Social/SocialNumerals";
import SocialCommitments from "@pages/Social/SocialCommitments";
import SocialStructure from "@pages/Social/SocialStructure";
import SocialResponsible from "../../pages/Social/SocialResponsible";
import AvanceCompromisos from "../../pages/Social/AvanceCompromisos";

const SocialRoutes = [
    {
        path: '/estructura-social',
        element: <PrivilegedRoute><StructureTabs /></PrivilegedRoute>
    },
    {
        path: '/social-compromisos',
        element: <PrivilegedRoute><SocialCommitments /></PrivilegedRoute>
    },
    {
        path: '/social-numeralia',
        element: <PrivilegedRoute><SocialNumerals /></PrivilegedRoute>
    },
    {
        path: '/social',
        element: <PrivilegedRoute><SocialStructure /></PrivilegedRoute>
    },
    {
        path: '/social-responsible',
        element: <PrivilegedRoute><SocialResponsible /></PrivilegedRoute>
    },
    {
        path: '/avance-compromisos',
        element: <PrivilegedRoute><AvanceCompromisos /></PrivilegedRoute>
    }
];

export default SocialRoutes;