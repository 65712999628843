import { useState, useEffect } from "react";
import QrReader from "react-camera-qr";
import Swal from "sweetalert2";

// Material UI
import { Typography, Grid, Box, Button, Icon } from "@mui/material";
import { ThreeSixty } from "@mui/icons-material";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import LoadingForm from "@components/LinearProgress/LoadingForm";

// Servicios
import EventsServices from "@services/EventsServices";
import { getVars } from "@utils/global";

const EventsAttendance = () => {
  const user = getVars("Token", {})?.user;

  const columns = [
    { id: "Actividad", label: "Evento", type: "text" },
    { id: "TipoActividad", label: "Tipo de Evento", type: "text" },
    { id: "FechaInicio", label: "Fecha", align: "center" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [qr, setQR] = useState("");
  const [facingMode, setFacingMode] = useState("user");

  useEffect(() => {
    if (user?.id) getEventsUsers(user?.id);
    // eslint-disable-next-line
  }, []);

  const getEventsUsers = async (id) => {
    try {
      setIsLoading(true);

      const params = {
        page: 0,
        pageSize: 9999,
        filtered: [{ id: "usuarios.id", filter: "=", value: id }],
        sorted: [{ id: "eventos.FechaInicio", value: "desc" }],
      };

      const result = await EventsServices.getEventsUsers(params);
      const { results, response, message } = await result;

      if (results) setRows(response.data);
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const setAttendance = async (UUID) => {
    try {
      setIsLoadingForm(true);

      const result = await EventsServices.setAttendance({ UUID: UUID });
      const { results, message } = await result;

      if (results) {
        setQR(UUID);
        getEventsUsers(user?.id);
        Swal.fire({ title: message, icon: "success" });
      } else throw new Error(message);
    } catch (e) {
      setQR("");
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoadingForm(false);
    }
  };

  const handleScan = (itemQR) => {
    if (itemQR !== null) {
      const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (regexUUID.test(itemQR) && itemQR.length === 36) {
        if (itemQR !== qr) {
          setAttendance(itemQR);
          setOpen(!open);
        } else Swal.fire({ title: "Ya escaneaste este evento!", icon: "warning" });
      } else Swal.fire({ title: "Intenta escanear nuevamente", icon: "warning" });
    }
  };

  const handleError = (e) => console.log("=>Error: ", e);

  const handleFacingMode = () => {
    if (facingMode === "user") setFacingMode("environment");
    else setFacingMode("user");
  };

  const handleOpen = () => setOpen(!open);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <LoadingForm
        isLoading={isLoadingForm}
        isOpen={isLoadingForm}
        setIsOpen={() => {}}
        loadinMessage={"Registrando..."}
      />

      {open && (
        <Box sx={{ p: 1, display: "flex", justifyContent: "center" }}>
          <QrReader
            facingMode={facingMode}
            delay={2000}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%", maxWidth: "400px" }}
          />
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={open ? 6 : 12}>
          <Button
            variant="outlined"
            onClick={handleOpen}
            fullWidth
            startIcon={<Icon>{open ? "close" : "qr_code_scanner"}</Icon>}
          >
            {open ? "Cerrar cámara" : "Escanear QR"}
          </Button>
        </Grid>

        {open && (
          <Grid item xs={12} sm={6}>
            <Button variant="contained" onClick={handleFacingMode} endIcon={<ThreeSixty />} fullWidth>
              Girar camara
            </Button>
          </Grid>
        )}
      </Grid>

      <Typography>Eventos Asistidos</Typography>

      <CustomTable
        rows={rows ?? []}
        columns={columns}
        stickyHeader
        maxHeight={230}
        disableCardType
        isLoading={isLoading}
      />
    </Box>
  );
};

export default EventsAttendance;
