import Swal from "sweetalert2";
import { isEmptyOrNullObject } from "@utils/validations";
import {
  URL_PUBLICA,
  PUBLIC_API_KEY,
  setVars,
  getVars,
  encryptVars,
  decryptVars,
  DEBUG,
} from "@utils/global";
import Clock from "@assets/img/clock_svg.svg";

const handleParams = (data, encriptar = true) => {
  let params = data ? data : {};
  return DEBUG && encriptar ? JSON.stringify(params) : encryptVars(JSON.stringify(params));
};

export const ApiExec = (data, api, method = "POST") => {
  let headers = new Headers();
  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      // body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      body: handleParams(data),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((e) => {
        reject({ data: {}, ...errorFetch(e.message) });
      });
  });
};

export const ApiExecPublic = (data, api, method = "POST") => {
  let headers = new Headers({
    Authorization: PUBLIC_API_KEY,
  });

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      // body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      body: handleParams(data),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            break;

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((e) => {
        reject({ data: {}, ...errorFetch(e.message) });
      });
  });
};

export const ApiExecDecrypted = (data, api, method = "POST") => {
  let headers = new Headers();
  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      // body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      body: handleParams(data),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            break;

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: dataRS.response,
          });
        }
      })
      .catch((e) => {
        reject({ data: {}, ...errorFetch(e.message) });
      });
  });
};

/*
export const downloadFile = ({ data, api, method = "GET" }) => {
  let fileName = "";
  let headers = new Headers();

  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let link = URL_PUBLICA + api;

    let requestInfo = {
      method: method,
      // body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      body: handleParams(data),
      headers: headers,
    };
    let url = "";

    if (method === "GET") {
      url = new URL(URL_PUBLICA + api);
      delete requestInfo.body;
      if (!isEmptyOrNullObject(data)) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value !== "") {
            url.searchParams.append(key, data[key]);
          }
        });
      }
    }

    fetch(link, requestInfo)
      .then((response) => {
        if (response.ok) {
          const header = response.headers.get("Content-Disposition");
          if (!header) {
            throw new Error("¡No cuenta con permisos suficientes, contacte al administrador!");
          }
          const parts = header.split(";");
          // fileName = parts[1].split('=')[1];
          fileName = parts[1].split("=")[1].replaceAll('"', "");
          return response.blob();
        }
        throw new Error("Error=>[url]: ", response);
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName.trim());

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        resolve({ success: true });
      })
      .catch((e) => {
        reject({ success: false, error: e });
      });
  });
};
*/

export const uploadFiles = (data, api = "global/uploadFiles", method = "POST") => {
  if (isEmptyOrNullObject(getVars("Token"))) {
    return Swal.fire({
      icon: "error",
      title: "Es requerido enviar la sesión del usuario",
    });
  }

  const userData = getVars("Token");
  const headers = new Headers({
    Authorization: `Bearer ${userData.access_token}`,
    "Content-Type": "application/json",
  });

  return new Promise((resolve, reject) => {
    let errorMessage = "";

    fetch(URL_PUBLICA + api, {
      method: method,
      body: JSON.stringify(mapEncryptedData(data)),
      headers: headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((e) => {
        reject({ data: {}, ...errorFetch(e.message) });
      });
  });
};

export const uploadFilesFormData = (data, api = "global/uploadFiles", method = "POST") => {
  if (isEmptyOrNullObject(getVars("Token"))) {
    return Swal.fire({
      icon: "error",
      title: "Es requerido enviar la sesión del usuario",
    });
  }

  const userData = getVars("Token");
  const headers = new Headers({
    Authorization: `Bearer ${userData.access_token}`,
  });

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    createFormData(formData, "data", data);

    let errorMessage = "";

    fetch(URL_PUBLICA + api, {
      method: method,
      body: formData,
      headers: headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((e) => {
        reject({ data: {}, ...errorFetch(e.message) });
      });
  });
};

function createFormData(formData, key, data) {
  if ((data === Object(data) && !(data instanceof File)) || Array.isArray(data)) {
    for (var i in data) {
      createFormData(formData, key + "[" + i + "]", data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

function mapEncryptedData(obj) {
  for (var k in obj) {
    if (typeof obj[k] == "object" && obj[k] !== null) mapEncryptedData(obj[k]);
    else obj[k] = k !== "file" ? (DEBUG ? obj[k] : encryptVars(obj[k])) : obj[k];
  }

  return obj;
}

export const downloadFile = ({ data, api, method = "GET" }) => {
  let fileName = "";
  let id;
  let headers = new Headers();

  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let link = URL_PUBLICA + api;

    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };
    let url = "";

    if (method === "GET") {
      url = new URL(URL_PUBLICA + api);
      delete requestInfo.body;
      if (!isEmptyOrNullObject(data)) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value !== "") {
            url.searchParams.append(key, data[key]);
          }
        });
      }
    }

    let errorMessage = "";

    fetch(link, requestInfo)
      .then(async (res) => {
        if (res.ok) {
          const header = res.headers.get("Content-Disposition");
          if (!header) {
            return res.json();
          } else {
            const parts = header.split(";");
            fileName = parts[1].split("=")[1].replaceAll('"', "");
            id = res.headers.get("Id");
            return res.blob();
          }
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((blob) => {
        if (blob instanceof Blob) {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName.trim());

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          resolve({
            success: true,
            results: true,
            message: "¡Descargado con éxito!",
            data: id,
          });
        } else {
          if (!blob.success) {
            reject({ ...blob, results: false });
          } else {
            resolve({
              ...blob,
              response: DEBUG ? blob.response : decryptVars(blob.response),
            });
          }
        }
      })
      .catch((e) => {
        reject({ error: e, ...errorFetch(e.message) });
      });
  });
};

export const uploadAndDownloadFile = ({ data, api }) => {
  let fileName = "";
  if (isEmptyOrNullObject(getVars("Token"))) {
    return Swal.fire({
      icon: "error",
      title: "Es requerido enviar la sesión del usuario",
    });
  }

  const userData = getVars("Token");
  const headers = new Headers({
    Authorization: `Bearer ${userData.access_token}`,
    "Content-Type": "application/json",
  });

  return new Promise((resolve, reject) => {
    let errorMessage = "";
    fetch(URL_PUBLICA + api, {
      method: "POST",
      body: JSON.stringify(mapEncryptedData(data)),
      headers: headers,
    })
      .then(async (res) => {
        if (res.ok) {
          const header = res.headers.get("Content-Disposition");
          if (!header) {
            return res.json();
          } else {
            const parts = header.split(";");
            fileName = parts[1].split("=")[1].replaceAll('"', "");
            return res.blob();
          }
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title: "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 406:
            return Swal.fire({
              title: "¡Sistema en mantenimiento!",
              text: "El Sistema se encuentra en mantenimiento. Inténtelo de nuevo más tarde.",
              icon: "warning",
              iconHtml: `<img src="${Clock}" height="80px">`,
              iconColor: "#dc3741",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Cerrar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 428:
            errorMessage = "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un inconveniente, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((blob) => {
        if (blob instanceof Blob) {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName.trim());

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          resolve({
            success: true,
            results: true,
            message: "¡Descargado con éxito!",
          });
        } else {
          if (!blob.success) {
            reject({ ...blob, results: false });
          } else {
            resolve({
              ...blob,
              response: DEBUG ? blob.response : decryptVars(blob.response),
            });
          }
        }
      })
      .catch((e) => {
        reject({ error: e, ...errorFetch(e.message) });
      });
  });
};

export const downloadFilePreview = ({ data, api, method = "GET" }) => {
  let fileName = "";
  let headers = new Headers();

  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };

    let url = "";

    if (method === "GET") {
      url = new URL(URL_PUBLICA + api);
      delete requestInfo.body;
      if (!isEmptyOrNullObject(data)) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value !== "") {
            url.searchParams.append(key, DEBUG ? data[key] : encryptVars(data[key]));
          }
        });
      }
    } else {
      url = URL_PUBLICA + api;
    }

    fetch(url, requestInfo)
      .then((response) => {
        if (response.ok) {
          const header = response.headers.get("Content-Disposition");
          const parts = header.split(";");
          // fileName = parts[1].split('=')[1];
          fileName = parts[1].split("=")[1].replaceAll('"', "");
          return response.blob();
        }

        throw new Error("Error=>[url]: ", response.statusText);
      })
      .then(async (blob) => {
        const base64Url = await fileToBase64(new Blob([blob]));

        resolve({ success: true, url: base64Url });
      })
      .catch((e) => {
        reject({ error: e, ...errorFetch(e.message) });
      });
  });
};

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      const base64Url = base64String.replace("application/octet-stream", "application/pdf");
      resolve(base64Url);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

const errorFetch = (message) => {
  const error = { success: false, results: false };
  if (!window.navigator.onLine) return { ...error, message: "Revisa tu conexión a internet." };
  else if (message === "Failed to fetch") return { ...error, message: "La plataforma está en actualización, en breve se restablece el servicio, si el mensaje persiste revisa tu conexión a internet." };
  else return { ...error, message };
};

export default ApiExec;
