import ApiExec from "@utils/ApiExec";

class ExamplesServices {
  uploadFile = (params) => {
    return ApiExec(params, "electoral/upload-file", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const services = new ExamplesServices();
export default services;
