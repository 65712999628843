import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, Stack } from "@mui/material";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";

// Services y Utils
import CatalogService from "@services/CatalogServices";
import { getVars, setVars } from "@utils/global";

const Filter = ({ catalogs, loadingCatalogs, handleFilter }) => {
  const [catMunicipiosReportes, setCatMunicipiosReportes] = useState([]);
  const [catPoligonos, setCatPoligonos] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);
  const [isLoadingCatMunicipios, setIsLoadingMunicipios] = useState(false);
  const [isLoadingCatPoligonos, setIsLoadingCatPoligonos] = useState(false);
  const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);
  const [filter, setFilter] = useState({
    idRegion: "",
    idMunicipioReportes: "",
    idPoligono: "",
    Seccion: "",
  });

  const getCatalogsWithStored = (idRegion, idMunicipio, idPoligono) => {
    const filterSavedMapMunicipio = getVars("filterSavedMapMunicipio", []);
    const filterSavedMapPoligono = getVars("filterSavedMapPoligono", []);
    const filterSavedMapSeccion = getVars("filterSavedMapSeccion", []);

    if (filterSavedMapMunicipio.length === 0) {
      handleChangeRegion(idRegion);
      return;
    }

    setCatMunicipiosReportes(filterSavedMapMunicipio);

    if (filterSavedMapPoligono.length === 0) {
      handleChangeMunicipio(idMunicipio, idRegion);
      return;
    }

    setCatPoligonos(filterSavedMapPoligono);

    if (catalogs?.poligonos?.length === 1) {
      handleChangePoligono(idPoligono, idRegion, idMunicipio);
      return;
    }

    setCatSecciones(filterSavedMapSeccion);
  };

  useEffect(() => {
    const filter_saved = getVars("filterSavedMap", {
      idRegion: "",
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });
    if (
      filter_saved.idRegion !== "" ||
      filter_saved.idMunicipioReportes !== "" ||
      filter_saved.idPoligono !== "" ||
      filter_saved.Seccion !== ""
    ) {
      setFilter(filter_saved);
      getCatalogsWithStored(filter_saved.idRegion, filter_saved.idMunicipioReportes, filter_saved.idPoligono);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const Re = catalogs?.regiones;
    const Mu = catalogs?.municipios;
    const Po = catalogs?.poligonos;

    const filter_saved = getVars("filterSavedMap", {
      idRegion: "",
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });

    if (filter_saved.idRegion === "" && filter_saved.idMunicipioReportes === "") {
      if (Po?.length === 1) {
        const params = {
          idRegion: Re[0].value,
          idMunicipioReportes: Mu[0].value,
          idPoligono: Po[0].value,
          Seccion: "",
        };
        handleClick(params, Mu, Po);
      } else if (Re?.length === 1 && Mu?.length > 1) {
        const params = {
          idRegion: Re[0].value,
          idMunicipioReportes: "",
          idPoligono: "",
          Seccion: "",
        };
        handleClick(params);
      } else if (Re?.length === 1 && Mu?.length === 1) {
        const params = {
          idRegion: Re[0].value,
          idMunicipioReportes: Mu[0].value,
          idPoligono: "",
          Seccion: "",
        };
        handleClick(params, Mu);
      } else if (Re?.length === 1 && Mu?.length > 1) {
        const params = {
          idRegion: Re[0].value,
          idMunicipioReportes: 0,
          idPoligono: "",
          Seccion: "",
        };
        handleClick(params, Mu);
      } else if (Re?.length > 1) {
        const params = {
          idRegion: 0,
          idMunicipioReportes: "",
          idPoligono: "",
          Seccion: "",
        };
        handleClick(params, Mu);
      }
    }

    // eslint-disable-next-line
  }, [catalogs]);

  const loadCatalogsByRegion = (idRegion = null) => {
    let catalogsParams = [
      {
        id: "municipios_dl",
        getAll: false,
        filtered: [],
      },
      {
        id: "poligonos",
        getAll: false,
        filtered: [],
      },
      {
        id: "secciones",
        getAll: false,
        filtered: [],
      },
    ];
    if (idRegion) {
      catalogsParams = [
        {
          id: "municipios_dl",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idRegion",
              filter: "=",
              value: idRegion,
            },
          ],
        },
        {
          id: "poligonos",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idRegion",
              filter: "=",
              value: idRegion,
            },
          ],
        },
        {
          id: "secciones",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idRegion",
              filter: "=",
              value: idRegion,
            },
          ],
        },
      ];
    }

    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);
    setIsLoadingMunicipios(true);
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          const RS = res.response.catalogs;

          setCatMunicipiosReportes([{ value: 0, label: "TODOS" }].concat(RS.municipios_dl));

          setCatPoligonos([{ value: 0, label: "TODOS" }].concat(RS.poligonos));

          setCatSecciones([{ value: 0, label: "TODAS" }].concat(RS.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
        setIsLoadingMunicipios(false);
      });
  };

  const loadCatalogsByMunicipio = (idMunicipioReportes = null) => {
    let catalogsParams = [
      {
        id: "poligonos",
        getAll: false,
        filtered: [{ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion }],
      },
      {
        id: "secciones",
        getAll: false,
        filtered: [{ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion }],
      },
    ];
    if (idMunicipioReportes) {
      catalogsParams = [
        {
          id: "poligonos",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idMunicipioReportes",
              filter: "=",
              value: idMunicipioReportes,
            },
          ],
        },
        {
          id: "secciones",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idMunicipioReportes",
              filter: "=",
              value: idMunicipioReportes,
            },
          ],
        },
      ];
    }
    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatPoligonos([{ value: 0, label: "TODOS" }].concat(res.response.catalogs.poligonos));
          setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
      });
  };

  const loadCatalogsByPoligono = (idPoligono = null) => {
    let filter_temp = [];
    if (filter.idRegion !== "") {
      filter_temp.push({ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion });
    }
    if (filter.idMunicipioReportes !== "") {
      filter_temp.push({
        id: "cat_secciones.idMunicipioReportes",
        filter: "=",
        value: filter.idMunicipioReportes,
      });
    }
    let catalogsParams = [
      {
        id: "secciones",
        getAll: false,
        filtered: filter_temp,
      },
    ];
    if (idPoligono) {
      catalogsParams = [
        {
          id: "secciones",
          getAll: false,
          filtered: [{ id: "cat_secciones.idPoligono", filter: "=", value: idPoligono }],
        },
      ];
    }

    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const handleChangeRegion = (idRegion) => {
    setFilter({
      idRegion: idRegion,
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });

    if (idRegion > 0) loadCatalogsByRegion(idRegion);
    else loadCatalogsByRegion();
  };

  const handleChangeMunicipio = (value, idRegion) => {
    setFilter({
      idRegion: idRegion ?? filter.idRegion,
      idMunicipioReportes: value,
      idPoligono: "",
      Seccion: "",
    });

    if (value > 0) loadCatalogsByMunicipio(value);
    else loadCatalogsByMunicipio();
  };

  const handleChangePoligono = (value, idRegion, idMunicipio) => {
    setFilter({
      idRegion: idRegion ?? filter.idRegion,
      idMunicipioReportes: idMunicipio ?? filter.idMunicipioReportes,
      idPoligono: value,
      Seccion: "",
    });
    loadCatalogsByPoligono(value);
  };

  const handleChangeSeccion = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (params, catalogMun, catalogPo) => {
    let filter_data = [];
    let shp = "region";

    if (params.idRegion === 0) shp = "region";

    if (params.idRegion !== "") {
      filter_data.push({
        id: "cat_secciones.idRegion",
        filter: params.idRegion === 0 ? "IN" : "=",
        value: params.idRegion === 0 ? [1, 2, 3, 4, 5, 6, 7] : params.idRegion,
      });
      shp = "municipio";
    }
    if (params.idMunicipioReportes !== "" && params.idMunicipioReportes !== 0) {
      filter_data.push({
        id: "cat_secciones.idMunicipioReportes",
        filter: "=",
        value: params.idMunicipioReportes,
      });
      shp = "poligono";
    }
    if (params.idPoligono !== "" && params.idPoligono !== 0) {
      filter_data.push({
        id: "cat_secciones.idPoligono",
        filter: "=",
        value: params.idPoligono,
      });
      shp = "seccion";
    }
    if (params.Seccion !== "" && params.Seccion !== 0) {
      filter_data.push({
        id: "cat_secciones.Seccion",
        filter: "=",
        value: params.Seccion,
      });
      shp = "seccion";
    }

    setVars("filterSavedMapMunicipio", catalogMun ?? catMunicipiosReportes);
    setVars("filterSavedMapPoligono", catalogPo ?? catPoligonos);
    setVars("filterSavedMapSeccion", catSecciones);
    setVars("filterSavedMap", {
      idRegion: params.idRegion,
      idMunicipioReportes: params.idMunicipioReportes,
      idPoligono: params.idPoligono,
      Seccion: params.Seccion,
    });
    handleFilter(filter_data, shp);
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <BasicSelect
            label="Región"
            name="region"
            options={catalogs.regiones}
            value={filter.idRegion}
            onChange={(e) => handleChangeRegion(e.target.value)}
            disabled={catalogs?.regiones?.length === 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            name="idMunicipioReportes"
            label="Muncipio"
            options={catMunicipiosReportes}
            value={filter.idMunicipioReportes}
            onChange={(e) => handleChangeMunicipio(e.target.value)}
            disabled={catMunicipiosReportes.length === 1 || loadingCatalogs || isLoadingCatMunicipios}
            isLoading={loadingCatalogs || isLoadingCatMunicipios}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            name="idPoligono"
            label="Poligono"
            options={catPoligonos}
            value={filter.idPoligono}
            onChange={(e) => handleChangePoligono(e.target.value)}
            disabled={catPoligonos.length === 1 || loadingCatalogs || isLoadingCatPoligonos}
            isLoading={loadingCatalogs || isLoadingCatPoligonos}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            name="Seccion"
            label="Sección"
            options={catSecciones}
            value={filter.Seccion}
            onChange={handleChangeSeccion}
            disabled={catSecciones.length === 1 || loadingCatalogs || isLoadingCatSecciones}
            isLoading={loadingCatalogs || isLoadingCatSecciones}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={
            (filter.idRegion === "" &&
              filter.idMunicipioReportes === "" &&
              filter.idPoligono === "" &&
              filter.Seccion === "") ||
            isLoadingCatMunicipios ||
            isLoadingCatPoligonos ||
            isLoadingCatSecciones
          }
          onClick={() => handleClick(filter)}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;
