import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import {
  Icon,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

const NestedLink = (props) => {
  const { menu, setBreadcrumbs, handleToggle } = props;
  const [isOpenSubmenus, setIsOpenSubmenus] = useState(false);

  const handleSubmenus = (name, id) => {
    setIsOpenSubmenus((prevOpen) => !prevOpen);
  };

  return (
    <Fragment key={Math.random()}>
      <ListItemButton
        onClick={(e) => {
          handleSubmenus(menu.Menu, menu.idMenu);
          handleToggle(true);
        }}
        key={Math.random()}
      >
        <ListItemIcon>
          <Icon className="nestedLink-icons">{menu.Icono}</Icon>
        </ListItemIcon>
        <ListItemText primary={menu.Menu} />
        {isOpenSubmenus ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
      </ListItemButton>
      <Collapse in={isOpenSubmenus} timeout="auto" unmountOnExit>
        <List>
          {menu.submenus.length > 0 &&
            menu.submenus.map((submenu) => {
              return (
                submenu.Visible === 1 && (
                  <ListItemButton
                    onClick={() => setBreadcrumbs(submenu.Menu, submenu.idMenu)}
                    key={Math.random()}
                  >
                    <NavLink
                      to={submenu.Ruta}
                      className={"nestedLink-text-siglas"}
                      style={{
                        textDecoration: "none",
                        display: "inline-block",
                        width: "100%",
                        fontSize: '0.9em'
                      }}
                    >
                      <ListItemIcon className={"nestedLink-text-siglas"}>
                        {/* <Icon sx={{ color: "white" }}>{submenu.Icono}</Icon> */}
                        {submenu.Siglas}
                      </ListItemIcon>
                      <span className={"nestedLink-text-siglas"}>
                        {submenu.Menu}
                      </span>
                    </NavLink>
                  </ListItemButton>
                )
              );
            })}
        </List>
      </Collapse>
      <Divider />
    </Fragment>
  );
};

export default NestedLink;
