import { useEffect, useState } from "react";
import useSearchData from "@hooks/useSearchData";

import {
  Table,
  TableContainer,
  Card,
  Typography,
  Icon,
  CardContent,
  Divider,
  Stack,
  TextField,
  ButtonGroup,
  Tooltip,
  IconButton,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";

import TableHeadReport from "./TableHeadReport";
import TableBodyReport from "./TableBodyReport";

import { ACTIONS_SPEED } from "@data/constants/Mobilization";
import moment from "moment";

const ListPending = ({ title = "", rows, filterDefault = "" }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);

  const { filteredData, searchValue, handleSearch } = useSearchData(
    data,
    () => {},
    "NombreCompleto"
  );

  const getMovilization = async ({ rows }) => {
    const tranformedData = rows.map((item) => ({
      ...item,
      NombreCompleto: (
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            primary=<Stack
              direction={"row"}
              alignItems={"center"}
              spacing={0.5}
            >
              <Typography variant="body2">{item.NombreCompleto}</Typography>
              <Chip
                label={`PASE DE LISTA: ${item.FechaHoraPaseLista}`}
                size="small"
                sx={{
                  fontSize: 11,
                  backgroundColor: green[50],
                  color: green[900],
                }}
              />
            </Stack>
            secondary={
              <Stack direction={"row"} fontSize={12} spacing={0.5}>
                <div> Registrado por: {item.UsuarioReporto}</div>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ backgroundColor: grey[500] }}
                />
                <div>
                  {moment(item.FechaRegistro, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </div>
              </Stack>
            }
            sx={{ p: 0, m: 0 }}
          />
        </ListItem>
      ),
      Contacto: (
        <ButtonGroup>
          {ACTIONS_SPEED.map(
            (action) =>
              item[action.key] && (
                <Tooltip
                  key={action.key}
                  title={item[action.key]}
                  placement="left"
                >
                  <IconButton
                    size="small"
                    href={
                      action.link &&
                      `${action.link}${item[action.key]}&text=Hola%20*${
                        item.NombreCompleto
                      }*`
                    }
                    target="_blank"
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              )
          )}
        </ButtonGroup>
      ),
    }));
    setData(tranformedData);
  };

  useEffect(() => {
    if (rows && rows.length > 0) {
      getMovilization({ rows });
      setDataFound(true);
    } else {
      setData([]);
      setDataFound(false);
    }
  }, [rows]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"column"} gap={0.4}>
            <Typography fontWeight={600} variant="subtitle2">
              {title}
            </Typography>
            {data.length > 0 && (
              <Chip
                label={data.length}
                color="primary"
                size="small"
                icon={
                  <Icon>
                    {filterDefault === "pendingReport"
                      ? "blocked"
                      : "check_circle"}
                  </Icon>
                }
                sx={{
                  width: filterDefault === "pendingReport" ? "40%" : "80%",
                }}
              />
            )}
          </Stack>
          <Stack direction={"row"} spacing={1} width={"70%"}>
            <TextField
              placeholder="Buscar por nombre"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              InputProps={{
                startAdornment: (
                  <Icon fontSize="small" color="action">
                    search
                  </Icon>
                ),
                sx: { borderRadius: 2 },
              }}
              sx={{ width: "80%" }}
            />
          </Stack>
        </Stack>
        <Divider sx={{ mt: 1 }} />
        <TableContainer
          sx={{
            maxHeight: 440,
            overflow: loading && "unset",
            borderRadius: 3,
            marginTop: 2,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHeadReport />
            <TableBodyReport
              data={filteredData.length > 0 ? filteredData : data}
              loading={loading}
              dataFound={dataFound}
            />
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ListPending;
