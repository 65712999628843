import { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Divider,
  Card,
  Typography,
  Button,
  Icon,
  ListItem,
  List,
  IconButton,
  ListItemText,
  LinearProgress,
  ListItemAvatar,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { red, yellow, blue } from "@mui/material/colors";
import moment from "moment";
import Swal from "sweetalert2";
import { Edit } from "@mui/icons-material";

import { VolunteerInfoInterface } from "@interfaces/VolunteerInterfaces";
import VolunteerServices from "@services/VolunteerServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import { SIN_RESPONSABILIDAD, USUARIO_INACTIVO } from "@data/constants";
import EditModal from "@components/Compromisos/AuditoriaOrigen/EditModal";

const VolunteerInfo = (props) => {
  const { handleClick, volunteer, assigned, isInsaculado = () => {}, setIsLoading } = props;
  const [dataInfo, setDataInfo] = useState(VolunteerInfoInterface);
  const [loading, setLoading] = useState(false);
  const [loadingResponsibilities, setLoadingResponsibilities] = useState(false);
  const [responsibilities, setResponsibilities] = useState([]);
  const [userStatus, setUserStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (assigned.length > 0) {
      getResponsibilities(volunteer.id);
    } else {
      setResponsibilities([]);
    }
    //eslint-disable-next-line
  }, [assigned]);

  const getVolunteerInfoById = useCallback((id) => {
    setLoading(true);

    VolunteerServices.findById(id)
      .then((res) => {
        if (res.results) {
          setDataInfo(res.response);
          isInsaculado(res.response?.EsInsaculado);
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error,
          icon: "warning",
        })
      )
      .finally(() => setLoading(false));
  }, []);

  const getResponsibilities = useCallback((id) => {
    setLoadingResponsibilities(true);

    VolunteerServices.getResponsibilities(id)
      .then((res) => {
        if (res.results) {
          let list_responsibilities = res.response.data;
          setUserStatus(list_responsibilities[0].idEstatus);
          setResponsibilities(list_responsibilities.filter((item) => item.idPerfil !== SIN_RESPONSABILIDAD));
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error,
          icon: "warning",
        })
      )
      .finally(() => setLoadingResponsibilities(false));
  }, []);

  useEffect(() => {
    if (!isEmptyOrNullObject(volunteer)) {
      getVolunteerInfoById(volunteer.id);
      getResponsibilities(volunteer.id);
    }
    //eslint-disable-next-line
  }, [volunteer]);

  const handleDeleteResponsibility = (item) => {
    Swal.fire({
      title: "¿Desea continuar?",
      html: `Esta acción eliminará la responsabilidad asignada al voluntario. ¿Qué acción desea realizar?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, Cancelar",
      confirmButtonText: "Si, Eliminar",
      cancelButtonColor: red[800],
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((res) => {
      if (res.isConfirmed) {
        const params = {
          id: item.id,
          idCompromisoUnico: volunteer.id,
        };
        setLoadingResponsibilities(true);
        VolunteerServices.deleteResponsibility(params)
          .then((res) => {
            if (res.results) {
              Swal.fire({
                title: res.message,
                icon: "success",
              });
              getResponsibilities(volunteer.id);
            } else {
              Swal.fire({
                title: res.message,
                icon: "warning",
              });
            }
          })
          .catch((error) =>
            Swal.fire({
              title: error,
              icon: "warning",
            })
          )
          .finally(() => setLoadingResponsibilities(false));
      }
    });
  };

  return (
    <Box component={Card} className="card-primary" sx={{ width: "100%", bgcolor: "background.paper", p: 2 }}>
      <Grid2 container>
        <Button variant="contained" size="small" color="primaryDark" startIcon={<Icon>navigate_before</Icon>} onClick={handleClick}>
          Regresar
        </Button>
      </Grid2>

      <Box sx={{ my: 2 }}>
        <Grid2 container display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
          <Typography component={"h6"} variant="h6" fontWeight={800} fontSize={18}>
            DATOS DE LA PROPUESTA
          </Typography>
          {middleware.checkMenuAction("Editar") && (
            <IconButton
              edge="end"
              aria-label="Editar"
              onClick={() => {
                setOpenModal((prev) => !prev);
              }}
              sx={{
                marginLeft: 2,
                "&:hover": {
                  color: "rgb(0, 65, 160, .9)",
                },
              }}
            >
              <Edit color="action" sx={{ color: blue[700] }} />
            </IconButton>
          )}
        </Grid2>
        {loading && <LinearProgress color="info" />}
        <Grid2 xs={12} sm={12}>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Nombre:{" "}
            <strong>
              {dataInfo.Nombre} {dataInfo.Paterno} {dataInfo.Materno}
            </strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Clave Elector: <strong>{dataInfo.INE}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Celular: <strong>{dataInfo.Celular}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Región: <strong>{dataInfo.Region}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Municipio: <strong>{dataInfo.Municipio}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Sección Vota: <strong>{dataInfo.SeccionVota}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Domicilio:{" "}
            <strong>
              {dataInfo.CalleVive}
              {dataInfo.NumExtVive} {dataInfo.NumIntVive && dataInfo.NumIntVive.length > 0 && dataInfo.NumIntVive}
            </strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Colonia: <strong>{dataInfo.ColoniaVive}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            CP: <strong>{dataInfo.CPVive}</strong>
          </Typography>
          <Typography component={"div"} variant={"div"} fontSize={15}>
            Responsabilidad(es):
          </Typography>
        </Grid2>
      </Box>

      <Divider variant="middle" />
      <Box sx={{ my: 2 }}>
        {loadingResponsibilities ? (
          <LinearProgress color="info" />
        ) : responsibilities && responsibilities.length > 0 ? (
          <List dense={true} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {responsibilities.map((item) => (
              <ListItem alignItems="flex-start">
              {middleware.checkMenuActionId("Eliminar", 7) && (
                <ListItemAvatar>
                  <IconButton
                    color="error"
                    onClick={(e) => handleDeleteResponsibility(item)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={<strong>{item.ResponsabilidadEtiqueta}</strong>}
                secondary={`${item.AsignadoPor} - ${moment(
                  item.FechaCreo,
                  "YYYY-MM-DD H:m:s"
                ).format("DD/MM/YYYY H:m")}`}
              />
            </ListItem>
            ))}
          </List>
        ) : (
          <Grid2 xs={12} sm={12} display="flex" justifyContent="center" alignItems="center">
            <Typography component={"div"} variant={"div"} fontSize={15}>
              <strong>SIN RESPONSABILIDAD</strong>
            </Typography>
          </Grid2>
        )}
      </Box>
      {openModal && <EditModal setModalOpen={setOpenModal} modalOpen={openModal} params={dataInfo} hasResponsable={responsibilities.length} refresh={()=>{getVolunteerInfoById(volunteer.id)}} setLoading={(e) => setIsLoading(e)} />}
      {userStatus === USUARIO_INACTIVO && (
        <Fragment>
          <Divider variant="middle" />
          <Box sx={{ my: 2 }} color="secondary">
            <Grid2 xs={12} sm={12}>
              <Typography component={"div"} variant={"div"} fontSize={15} color={yellow[900]}>
                <strong>Usuario creado sin acceso al sistema</strong>
              </Typography>
            </Grid2>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

VolunteerInfo.propTypes = {
  handleClick: PropTypes.func.isRequired,
  volunteer: PropTypes.object.isRequired,
  assigned: PropTypes.array.isRequired,
};

VolunteerInfo.defaultProps = {
  assigned: [],
  setIsLoading: false,
};

export default VolunteerInfo;
