import { ProtectedRoute } from "@middlewares";
import Examples from "@pages/_Examples";
import Pruebas from "@pages/_Examples/Pruebas";

const ExamplesRoute = [
  {
    path: "/component-examples",
    element: (
      <ProtectedRoute>
        <Examples />
      </ProtectedRoute>
    ),
  },
  {
    path: "/botonPruebas",
    element: <Pruebas />,
  },
];

export default ExamplesRoute;
