import { ApiExec, ApiExecDecrypted } from "@utils/ApiExec";

class MapService {
  getSections = (params) => {
    return ApiExecDecrypted(params, "map/compromisos/get-sections", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBlocks = (params) => {
    return ApiExecDecrypted(params, "map/compromisos/get-blocks", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getFederalDistricts = (params) => {
    return ApiExecDecrypted(params, "map/compromisos/get-dfeds", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getInformationGeneral = (params) => {
    return ApiExec(params, "map/compromisos/get-information-general", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getRangosColores = (params) => {
    return ApiExec(params, "map/compromisos/get-rangos-colores", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const map = new MapService();
export default map;
