import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import Swal from "sweetalert2";

// Material UI
import {
  Button,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
  Icon,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import CardIconColor from "@components/Card/CardIconColor";

// Utils, Servicios y Middleware
import { URL_PUBLICA_FRONT, ClipboardCopy, getVars } from "@utils/global";
import UserServices from "@services/UserServices";
import middleware from "@middlewares/middleware";

const Welcome = () => {
  const navigate = useNavigate();
  const { user } = getVars("Token");

  const username = `${user.Nombre} ${user.Paterno} ${user.Materno}`.split(" ");
  let delayNumber = 0;

  const [shortUrl, setShortUrl] = useState("");
  const [totalesAvance, setTotalesAvance] = useState({
    TotalWeb: 0,
    TotalGuest: 0,
  });
  const [fechaFin, setFechaFin] = useState();
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [message, setMessage] = useState(``);
  const [messageLink, setMessageLink] = useState(``);

  useEffect(() => {
    getAvances();

    if (fechaFin) {
      // Calcula el tiempo restante
      const targetDate = new Date(fechaFin); // Cambia a tu fecha objetivo
      const now = new Date();

      const timeDiff = targetDate - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });

        // Actualiza la cuenta regresiva cada segundo
        const countdownInterval = setInterval(() => {
          if (timeDiff > 0) {
            const updatedDiff = targetDate - new Date();
            const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
            const updatedHours = Math.floor((updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const updatedMinutes = Math.floor((updatedDiff % (1000 * 60 * 60)) / (1000 * 60));
            const updatedSeconds = Math.floor((updatedDiff % (1000 * 60)) / 1000);

            setTimeRemaining({
              days: updatedDays,
              hours: updatedHours,
              minutes: updatedMinutes,
              seconds: updatedSeconds,
            });
          } else clearInterval(countdownInterval);
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, [fechaFin]);

  useEffect(() => {
    if (Object.keys(user).length > 0 && shortUrl === "") {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${user.UUID}`);
      let rute = `${URL_PUBLICA_FRONT}invitado/${user.UUID}`;
      if (user.ambito_perfil.filter((item) => item.idTipo === 5).length) {
        let perfil = user.ambito_perfil.filter((item) => item.idTipo === 5);
        setMessage(
          `Copia el siguiente link para invitar a tus familiares y amigos a formar parte 
        de tu red para defender al ${user ? perfil[0].AmbitoVisualizacion : ""}  ${
            user ? perfil[0].LabelAmbitoValorCargo : ""
          }.`
        );
        setMessageLink(`Hola te invito a defender al ${user ? perfil[0].AmbitoVisualizacion : ""} ${
          user ? perfil[0].LabelAmbitoValorCargo : ""
        }, se parte de mi red de amigos registrándote en ${rute}`);
      } else {
        setMessage(
          `Copia el siguiente link para invitar a tus familiares y 
        amigos a formar parte de tu red para defender a Guanajuato.`
        );
        setMessageLink(
          `Hola, te invito a defender a Guanajuato, sé parte de mi red registrándote en ${rute}`
        );
      }
    }
    // eslint-disable-next-line
  }, [user]);


  const getAvances = () => {
    UserServices.getAvanceCompromisos({})
      .then((res) => {
        if (res.results) {
          setTotalesAvance(res.response.data);
          setFechaFin(res.response.FechaFin);
        } else {
          setTotalesAvance({
            TotalWeb: 0,
            TotalGuest: 0,
          });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  const finalCards = (
    <>
      {middleware.checkMenuActionId("Ver", 256) && (
        <Grid2 xs={12} md={5} lg={6}>
          <Card
            className="card-primary"
            onClick={() => navigate("/compromisos-registro")}
            sx={{
              mb: "0 !important",
              minHeight: 300,
              height: "100%",
              borderRadius: 10,
              transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#ffffff",
              cursor: "default",
              zIndex: "auto",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
                backgroundColor: "#E3F2FD",
                cursor: "pointer",
                zIndex: 9999999999,
              },
            }}
          >
            <CardContent>
              <Icon fontSize="large" style={{ color: "#007bff" }}>
                edit_note
              </Icon>{" "}
              {/* Icono con color azul */}
              <Typography
                variant="h5"
                sx={{ textAlign: "center", marginTop: 2, color: "#007bff" }} // Título con color azul
              >
                Captura
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", marginTop: 2, color: "#495057" }} // Texto con color gris oscuro
              >
                Comienza a registrar tus simpatizantes.
              </Typography>
              <br />
              <Divider color={"#00000"} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Typography variant="body2" sx={{ color: "#FF4081" }}>
                  Ir a Captura de Compromisos{" "}
                </Typography>{" "}
                {/* Texto con color rosa */}
                <Icon style={{ color: "#FF4081" }}>arrow_forward</Icon> {/* Flecha con color rosa */}
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      )}
      <Grid2 xs={12} md={7} lg={middleware.checkMenuActionId("Ver", 256) ? 6 : 12}>
        <Card
          className="card-primary"
          onClick={() => navigate("/mi-perfil")}
          sx={{
            mb: "0 !important",
            minHeight: 300,
            height: "100%",
            borderRadius: 10,
            transition: "box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#ffffff",
            cursor: "default",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
              backgroundColor: "#FCE4EC",
              cursor: "pointer",
              zIndex: 9999999999,
            },
          }}
        >
          <CardContent>
            <Grid2 container spacing={0}>
              <Grid2 xs={12}>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Icon sx={{ color: "darkblue" }}>manage_accounts</Icon>
                  </ListItemIcon>
                  <Typography fontSize={20} fontWeight={500} color="darkblue">
                    Mi Perfil
                  </Typography>
                </ListItem>
              </Grid2>
              <Grid2 xs={12}>
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon>qr_code_scanner</Icon>
                    </ListItemIcon>
                    <Typography variant="overline">MI QR identificador</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon>phonelink_off</Icon>
                    </ListItemIcon>
                    <Typography variant="overline">Desvinculación</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon>password</Icon>
                    </ListItemIcon>
                    <Typography variant="overline">Cambio Contraseña</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon>dialpad</Icon>
                    </ListItemIcon>
                    <Typography variant="overline">Cambio PIN</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon>photo_camera</Icon>
                    </ListItemIcon>
                    <Typography variant="overline">Asistencia de eventos</Typography>
                  </ListItem>
                </List>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Grid2>
    </>
  );

  return (
    <AdminLayout>
      <style>{` @keyframes bounce {
                0%,100%{ transform:translate(0); }
                25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
                50%{ transform:translateY(-10px) rotate(3deg) scale(1.05);  }
              }`}</style>
      <Container
        component="main"
        sx={{ "&:hover .span-word": { animation: "bounce .6s" } }} /* maxWidth="lg" */
      >
        <Grid2 container spacing={1}>
          {user.linkToShare !== 0 ? (
            <Grid2 xs={12} md={5} lg={4}>
              <Card
                className="card-primary"
                sx={{ textAlign: "center", minHeight: "530px", height: "100%", mb: "0 !important" }}
              >
                <CardContent>
                  <CardMedia sx={{ overflow: "auto" }}>
                    <QRCode value={shortUrl} />
                  </CardMedia>

                  <Grid2 container rowSpacing={1} columnSpacing={1}>
                    <Grid2 xs={12}>
                      <Typography variant="h5" component="div" align="center" color="#303F9F" gutterBottom>
                        ¡Hola!{" "}
                        {username.map((item, index) => (
                          <span key={index} style={{ display: "inline-block", marginRight: 8 }}>
                            {item.split("").map((word, i) => (
                              <span
                                className="span-word"
                                key={i}
                                style={{
                                  display: "inline-block",
                                  animationDelay: `${delayNumber++ * 0.05}s`,
                                }}
                              >
                                {word}
                              </span>
                            ))}
                          </span>
                        ))}
                      </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}
                      >
                        {message}
                      </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Button
                        variant="text"
                        href={shortUrl}
                        target="_blank"
                        size="small"
                        fullWidth
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {shortUrl}
                      </Button>
                    </Grid2>
                    <Grid2 xs={12}>
                      <ClipboardCopy copyText={messageLink} />
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>
          ) : (
            <Grid2 xs={12} md={12} lg={12}>
              <Typography variant="h5" fontWeight={700}>
                ¡Hola!{" "}
                {username.map((item, index) => (
                  <span key={index} style={{ display: "inline-block", marginRight: 8 }}>
                    {item.split("").map((word, i) => (
                      <span
                        className="span-word"
                        key={i}
                        style={{
                          display: "inline-block",
                          animationDelay: `${delayNumber++ * 0.05}s`,
                        }}
                      >
                        {word}
                      </span>
                    ))}
                  </span>
                ))}
              </Typography>
            </Grid2>
          )}

          <Grid2
            container
            xs={12}
            md={user.linkToShare !== 0 ? 7 : 12}
            lg={user.linkToShare !== 0 ? 8 : 12}
            spacing={1}
          >
            <Grid2 xs={12} sm={6} md={6} lg={4}>
              <CardIconColor
                title="Invitados"
                total={totalesAvance.TotalGuest}
                color="info"
                icon={"schema"}
                sx={{ minHeight: "215px", height: "100%" }}
                loading={loading}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={6} lg={4}>
              <CardIconColor
                title="Compromisos"
                total={totalesAvance.TotalWeb}
                color="success"
                icon={"schema"}
                sx={{ minHeight: "215px", height: "100%" }}
                loading={loading}
              />
            </Grid2>

            <Grid2 xs={12} md={12} lg={4}>
              <CardIconColor
                title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
                total={`${timeRemaining.days} días`}
                color="warning"
                icon={"schema"}
                sx={{ minHeight: "215px", height: "100%" }}
              />
            </Grid2>

            <Grid2 container xs={12} spacing={1} sx={{ display: { xs: "none", lg: "flex" } }}>
              {finalCards}
            </Grid2>
          </Grid2>

          <Grid2 container xs={12} spacing={1} sx={{ display: { lg: "none" } }}>
            {finalCards}
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default React.memo(Welcome);
