import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  Grid,
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  Icon,
  Modal,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";

// Servicios / Middleware
import middleware from "@middlewares/middleware";
import UserRoleService from "@services/RoleServices";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { UserRoleAddInterface, UserRoleInfo } from "@interfaces/RoleInterfaces";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicList from "@components/Lists/BasicList";
import AddNewMenuList from "@components/Menus/AddNewMenuList";
import MenuToolbar from "@components/Menus/MenuToolbar";
import UsersList from "@components/Roles/UsersList";
import PerfilRoles from "@components/Roles/PerfilRoles";
// import ViewMenus from "@components/Roles/ViewMenus";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import RoleListItem from "@components/Roles/RoleListItem";
import UserRoleForm from "@components/Roles/RoleForm";
import RoleOrderList from "@components/Roles/RoleOrderList";
import RoleEdit from "@components/Roles/RoleEdit";
import UserRoleMenuList from "@components/Menus/UserRoleMenuList";
import MenusList from "@components/Menus/MenusList";
import CustomTabs from "@components/Tabs/CustomTabs";
import DrawerRelative from "@components/Containers/DrawerRelative";

const inputOptions = new Promise((resolve) => {
  setTimeout(() => {
    resolve({
      0: "Aplicar a partir de ahora",
      1: "Aplicar a todos",
    });
  }, 1000);
});

const Roles = () => {
  const [actionForm, setActionForm] = useState("menu");
  const [formData, setFormData] = useState(UserRoleAddInterface);
  const [roleList, setRoleList] = useState([]);
  const [total, setTotal] = useState(0);
  const [perfilInfo, setPerfilInfo] = useState(UserRoleInfo);
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const { height } = useWindowDimensions();
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingAddMenu, setIsLoadingAddMenu] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [menuType, setMenuType] = useState("web");
  const [currentMenus, setCurrentMenus] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(true);

  // Actualiza el listado al editar un perfil
  const [changeEdit, setChangeEdit] = useState(false);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const config = {
    title: "Listado de Roles",
    icon: "people",
    height: height * 0.68,
    endMessage: "No hay más registros para mostrar",
  };

  const getMenus = useCallback((id) => {
    if (id) {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      UserRoleService.getMenus({
        idPerfil: id,
        Movil: 0, // 0 para web
      })
        .then((res) => {
          if (res.results) {
            setWebMenus(res.response.data);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((error) => {
          Swal.fire({ title: error.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });

      UserRoleService.getMenus({
        idPerfil: id,
        Movil: 1, // 1 para app
      })
        .then((res) => {
          if (res.results) {
            setAppMenus(res.response.data);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((error) => {
          Swal.fire({ title: error.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });
    }
    //eslint-disable-next-line
  }, []);

  const handleClickItem = useCallback((id, role) => {
    if (role) {
      const perfilEncontrado = role.find((perfil) => perfil.id === id);
      setPerfilInfo(perfilEncontrado);
      getMenus(id);
    }
    //eslint-disable-next-line
  }, []);

  const getRoles = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setRoleList(res.response.data);
            setTotal(res.response.total);
            handleClickItem(res.response.data[0].id, res.response.data);
          }

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRoles({ page, pageSize, filtered });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tabValue) {
      switch (parseInt(tabValue)) {
        case 1: //WEB
          setCurrentMenus(webMenus);
          setMenuType("web");
          break;
        case 2: //APP
          setCurrentMenus(appMenus);
          setMenuType("app");
          break;
        default:
          setCurrentMenus([]);
          setMenuType("web");
          break;
      }
    }
  }, [tabValue, webMenus, appMenus]);

  const fetchHasMore = () => {
    UserRoleService.getRoles({ page: page + 1, pageSize: pageSize })
      .then((res) => {
        if (res.results) {
          setRoleList(roleList.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage(page + 1);
  };

  const handleReloadList = (id) => {
    setPage(0);
    UserRoleService.getRoles({ page: 0, pageSize: pageSize })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setRoleList(res.response.data);
            setTotal(res.response.total);
            if (id && res.response.data.find((item) => item.id === id)) {
              handleClickItem(id);
            } else {
              handleClickItem(res.response.data[0].id, res.response.data);
            }
          }

          if (pageSize >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const handleAddMenu = () => {
    setIsModalOpen(true);
    setActionForm("menu");
    setModalTitle("Agregar menús");
  };

  const handleEditRole = () => {
    setIsModalOpen(true);
    setActionForm("role");
    setModalTitle("Editar perfil");
    setFormData(() => {
      let data = {};
      Object.keys(formData).forEach((key) => {
        data[key] = perfilInfo[key] !== undefined ? perfilInfo[key] : "";
      });
      return data;
    });
  };

  const submitMenus = (menus) => {
    setIsLoadingAddMenu(true);
    const params = {
      menus,
      idPerfil: perfilInfo.id,
      Accion: 0,
    };

    UserRoleService.getImpact4Delete(perfilInfo.id)
      .then((res) => {
        if (res.success) {
          if (res.response.total > 0) {
            Swal.fire({
              title: "Advertencia",
              html: `Actualmente existen ${res.response.total} usuario(s) que tienen asignado este perfil. Consulte la pestaña de USUARIOS y aségurese de aplicar la acción correcta. ¿Qué acción desea realizar?`,
              icon: "warning",
              input: "radio",
              inputOptions: inputOptions,
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonText: "Aplicar",
              confirmButtonColor: "#1976d2",
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (action) => {
                if (!action) {
                  return "Debe seleccionar una opción!";
                } else {
                  if (action) {
                    insertMenus({ ...params, Accion: parseInt(action) });
                  }
                }
              },
              customClass: { container: "modal-alert" },
            });
          } else {
            insertMenus(params);
          }
        }
      })
      .catch((error) => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        Swal.fire({ title: error.message, icon: "warning" });
      });
  };

  const insertMenus = (params) => {
    UserRoleService.insertMenus(params)
      .then((res) => {
        if (res.success) {
          setIsModalOpen(false);
          Swal.fire({
            title: res.message,
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: { container: "modal-alert" },
          }).then((res) => {
            if (res.isConfirmed) {
              handleClickItem(perfilInfo.id, roleList);
            }
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
            customClass: { container: "modal-alert" },
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: "warning",
          customClass: { container: "modal-alert" },
        });
      })
      .finally(() => {
        setIsLoadingAddMenu(false);
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleDeleteItem = (item) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserRoleService.getImpact4Delete(item.id)
      .then((res) => {
        if (res.response.total > 0) {
          Swal.fire({
            title: "Advertencia",
            html: `Actualmente existen ${res.response.total} usuario(s) que tienen asignado este perfil. Consulte la pestaña de USUARIOS y aségurese que no exista ningún usuario con el perfil seleccionado para eliminar.`,
            icon: "warning",
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        } else {
          UserRoleService.delete(item.id)
            .then((res) => {
              if (res.success) {
                Swal.fire({
                  title: res.message,
                  icon: "success",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then((res) => {
                  if (res.isConfirmed) {
                    handleReloadList();
                  }
                });
              } else {
                Swal.fire({ title: res.message, icon: "warning" });
              }
            })
            .catch((e) => {
              Swal.fire({ title: e.message, icon: "warning" });
            })
            .finally(() => {
              setIsLoadingForm(false);
              setOpenLinearProgress(false);
            });
        }
      })
      .catch((error) => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        Swal.fire({ title: error.message, icon: "warning" });
      });
  };

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
    } else {
      filtro = [
        {
          id: "perfiles.Perfil",
          filter: "LIKE",
          value: searchInput,
          inheritFilterType: null,
        },
      ];
    }
    setFiltered(filtro);
    setPage(0);
    getRoles({ page: 0, pageSize, filtered: filtro });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ paddingX: { xs: 0, sm: 2, md: 3 } }}>
        <ActionHeader title="Administración de perfiles de usuario" isCustom={true}>

          {middleware.checkMenuAction("Agregar") && (
            <Button
              color={"primaryDark"}
              variant={"contained"}
              size={"medium"}
              startIcon={<Icon>add</Icon>}
              onClick={(e) => {
                navigate("/admin/perfiles/agregar");
              }}
            >
              Agregar
            </Button>
          )}
          {middleware.checkMenuAction("Editar") && (
            <Button
              color={"primaryDark"}
              variant={"contained"}
              size={"medium"}
              startIcon={<Icon>edit</Icon>}
              onClick={(e) => {
                setActionForm("edit");
                setIsModalOpen(true);
                setModalTitle("Editar Perfil: " + perfilInfo.Perfil);
              }}
            >
              Editar
            </Button>
          )}

          <Button 
            color={"primaryDark"}
            variant={"contained"}
            size={"medium"}
            onClick={() => setOpenDrawer((prev) => !prev)}
          >
            {openDrawer ? <Icon>chevron_left</Icon> : <Icon>chevron_right</Icon>}
          </Button>
          {/* {middleware.checkMenuAction("Editar") && (
              <Button
                text="Orden"
                icon="reorder"
                variant="outlined"
                onClick={(e) => {
                  setActionForm("order");
                  setIsModalOpen(true);
                  setModalTitle("Ordenar Perfiles");
                }}
              />
            )} */}
        </ActionHeader>
        <LoadingForm
          isLoading={isloadigForm}
          success={issuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={"Cargando..."}
        />

        <Grid container spacing={1}>
          <Grid xs={12} md={4} sm={12} lg={4} item>
            <DrawerRelative
              anchor="left"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Listado de Usuarios"}
              icon="people"
              className="card-primary"
              sxPaper={{ pb: 0, borderRadius: '12px' }}
              sx={{ mb: "0 !important", borderRadius: '12px !important' }}
            >
              {
                <BasicList
                  configParams={config}
                  data={roleList}
                  fetchHasMore={fetchHasMore}
                  totalItems={total}
                  itemComponent={RoleListItem}
                  hasMore={hasMore}
                  handleClickItem={(e) => {
                    handleClickItem(e, roleList);
                  }}
                  finder={true}
                  handleDeleteItem={handleDeleteItem}
                  search={handleSearch}
                />
              
              }
            </DrawerRelative>
          </Grid>

          <Grid xs={12} md={openDrawer? 8 : 12} sm={12} lg={openDrawer ? 8 : 12}item>
            <Card sx={{ height: "100%", maxHeight: { xs: "auto", md: height * 0.9 } }}>
              <CardContent sx={{ px: { xs: 0, sm: 2 } }}>
                <CustomTabs
                      value={tabValue}
                      setValue={setTabValue}
                      color={"secondary"}
                      tabs={[
                        {
                          label: "Menús Web",
                          value: "1",
                          icon: 'admin_panel_settings',
                          iconPosition: 'top',
                          component: middleware.checkMenuAction("Editar") ? (
                            <UserRoleMenuList
                              menus={webMenus}
                              type={"list-edit"}
                              maxHeight={height * 0.7}
                              toolbar={true}
                              ComponentToolbar={MenuToolbar}
                              configToolbar={{
                                title: `${perfilInfo.Perfil}`,
                                icon: "add_circle",
                                btnAdd: true,
                                handleAdd: handleAddMenu,
                                handleEditRole: handleEditRole,
                              }}
                              setIsOpen={setOpenLinearProgress}
                              setIsLoading={setIsLoadingForm}
                              setIsSuccess={setIsSuccessForm}
                              externalID={perfilInfo.id}
                              handleReloadList={handleReloadList}
                            />
                          ) : (
                            <MenusList menus={webMenus} type={"list"} maxHeight={height * 0.7} />
                          )
                        },
                        {
                          label: "Menús App",
                          value: "2",
                          icon: 'app_settings_alt',
                          iconPosition: 'top',
                          component: middleware.checkMenuAction("Editar") ? (
                            <UserRoleMenuList
                              menus={appMenus}
                              type={"list-edit"}
                              maxHeight={height * 0.7}
                              toolbar={true}
                              ComponentToolbar={MenuToolbar}
                              configToolbar={{
                                title: `${perfilInfo.Perfil}`,
                                icon: "add_circle",
                                btnAdd: true,
                                handleAdd: handleAddMenu,
                                handleEditRole: handleEditRole,
                              }}
                              setIsOpen={setOpenLinearProgress}
                              setIsLoading={setIsLoadingForm}
                              setIsSuccess={setIsSuccessForm}
                              externalID={perfilInfo.id}
                              handleReloadList={handleReloadList}
                            />
                          ) : (
                            <MenusList menus={appMenus} type={"list"} maxHeight={height * 0.7} />
                          ),
                        },
                        {
                          label: "Usuarios",
                          value: "3",
                          icon: 'people',
                          iconPosition: 'top',
                          component: <UsersList data={perfilInfo} />
                        },
                        {
                          label: "Perfiles",
                          value: "4",
                          icon: 'people',
                          iconPosition: 'top',
                          component: <PerfilRoles
                            data={perfilInfo}
                            ComponentToolbar={MenuToolbar}
                            configToolbar={{ title: `${perfilInfo.Perfil}`, btnAdd: false }}
                            change={changeEdit}
                          />,
                          display: middleware.checkMenuAction("Ver")
                        },
                      ]}
                    />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "100%", sm: "80%", md: "60%" },
            maxHeight: "100%",
            overflowY: "auto",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            py: 4,
            px: { xs: 0, sm: 4 },
          }}
        >
          <CardHeader
            title={modalTitle}
            action={
              <IconButton onClick={() => setIsModalOpen(false)}>
                <Close />
              </IconButton>
            }
          />
          <CardContent>
            {actionForm === "role" && (
              <UserRoleForm
                handleCloseModal={() => setIsModalOpen(false)}
                handleReloadList={handleReloadList}
                data={formData}
              />
            )}
            {actionForm === "menu" && (
              <AddNewMenuList
                currentMenus={currentMenus}
                type={menuType}
                submitMenus={submitMenus}
                isLoadingAdd={isLoadingAddMenu}
                handleCloseModal={() => setIsModalOpen(false)}
              />
            )}
            {actionForm === "order" && <RoleOrderList />}
            {actionForm === "edit" && (
              <RoleEdit data={perfilInfo} change={changeEdit} setChange={setChangeEdit} />
            )}
          </CardContent>
        </Card>
      </Modal>
    </AdminLayout>
  );
};

export default Roles;
