import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Material UI
import { Drawer, List, Icon, Divider, ListItemButton, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";

// Componentes
import NestedLink from "@components/MainPage/NestedLink";

// Utils
import { getVars, setVars } from "@utils/global";

import "./AdminColorsNav-Side-Bar.css";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawers = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSidebar = (props) => {
  const { open, handleToggle } = props;
  const Token = getVars("Token");
  const [menus /* setMenus */] = useState(() => {
    if (Token.menus.length < 1) return [];
    return Token.menus;
  });
  const historyMenu = getVars("sideBar");
  const [openMenuList, setOpenMenuList] = useState(["Inicio"]);
  const [stateMenu] = useState(historyMenu.clickMenu || "");

  const setBreadcrumbs = (name, id) => {
    if (openMenuList.includes(name)) {
      const result = openMenuList.filter((item) => item !== name);
      setOpenMenuList(result);
      setVars("sideBar", {
        clickMenu: stateMenu,
        caminoMenu: result,
        Menu: name,
      });
    } else {
      setOpenMenuList([...openMenuList, name]);
      setVars("sideBar", {
        clickMenu: stateMenu,
        caminoMenu: [...openMenuList, name],
        Menu: name,
      });
    }
  };

  const content = (
    <List>
      {menus &&
        menus.map((menu, index) => (
          <Fragment key={menu.idMenu || index}>
            {menu.Visible === 1 &&
              (menu.submenus.length < 1 ? (
                <ListItemButton
                  onClick={() => {
                    setBreadcrumbs(menu.Menu, menu.idMenu);
                    handleToggle(true);
                  }}
                  key={Math.random()}
                >
                  <NavLink
                    to={menu.Ruta ? menu.Ruta : "#"}
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <ListItemIcon>
                      <Icon className="sideBar-icons">{menu.Icono}</Icon>
                    </ListItemIcon>

                    <span className="sideBar-one-text">{menu.Menu}</span>
                  </NavLink>
                </ListItemButton>
              ) : (
                <NestedLink menu={menu} setBreadcrumbs={setBreadcrumbs} handleToggle={handleToggle} />
              ))}
          </Fragment>
        ))}
    </List>
  );

  return (
    <Drawers
    className="Drawers"
      PaperProps={{
        className: "Drawers-paper", 
      }}
      variant="permanent"
      open={open}
      onMouseLeave={(e) => handleToggle(false)}
      // onMouseOut={(e) => handleToggle(true)}
    >
      <DrawerHeader />
      <Divider />
      {content}
    </Drawers>
  );
};

AdminSidebar.propTypes = {
  handleToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default React.memo(AdminSidebar);
