import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Icon,
  InputAdornment,
  IconButton,
  Box,
  Chip,
  Typography,
  Stack
} from "@mui/material";
import { useFormik } from "formik";
import { getVars } from "@utils/global";
import { UserProfileInterface } from "@data/interfaces/UserInterfaces";
import { UserUpdateProfileSchema } from "@data/schemas/UserSchemas";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";

import PropTypes from "prop-types";
import Swal from "sweetalert2";
import UserService from "@services/UserServices";
// import CatalogService from "@services/CatalogServices";
import BasicSelect from "@components/Selects/BasicSelect";
import LoadingForm from "@components/LinearProgress/LoadingForm";

const catalogsParams = [
  { id: "dependencias" },
  { id: "perfiles_hijos" },
  { id: "municipios" },
  { id: "centros" },
];

const UserForm = (props) => {
  const { action, data, isAdmin, handleChangeUserInfo } = props;

  let newData = {
    id: data.id,
    Celular: data.Celular,
    Materno: data.Materno,
    Nombre: data.Nombre,
    Paterno: data.Paterno,
    Correo: data.Correo
  };

  //delete newData.idCentro;
  const formik = useFormik({
    initialValues: action === "add" ? UserProfileInterface : newData,
    validationSchema:
      action === "add" ? UserProfileInterface : UserUpdateProfileSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const [catalogs, setCatalogs] = useState(() => {
    let obj = {};
    catalogsParams.forEach((item) => {
      obj[item.id] = [];
    });
    return obj;
  });
  const [catCentros /*setCatCentros*/] = useState([]);
  const [flags, setFlags] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.access_token;
    return "";
  });
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [issuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [catPage /*setCatPage*/] = useState([]);
  useEffect(() => {
    // setIsLoadingForm(true);
    // setOpenLinearProgress(true);
    // CatalogService.getCatalogs(catalogsParams)
    //   .then((res) => {
    //     if (res.results) {
    //       if (res.response.errors.length > 0) {
    //         Swal.fire({
    //           title:
    //             "Algunos catálogos pudieron ser cargados. Contacte al administrador",
    //           icon: "warning",
    //         });
    //       }
    //       setCatalogs(() => {
    //         let obj = {};
    //         catalogsParams.forEach((item) => {
    //           obj[item.id] =
    //             res.response.catalogs[item.id] !== undefined
    //               ? res.response.catalogs[item.id]
    //               : [];
    //         });
    //         return obj;
    //       });
    //       setCatCentros(res.response.catalogs["centros"]);
    //     } else {
    //       Swal.fire({ title: res.message, icon: "warning" });
    //     }
    //   })
    //   .catch((e) => { })
    //   .finally(() => {
    //     setIsLoadingForm(false);
    //     setOpenLinearProgress(false);
    //     if (action !== "add") {
    //       handlePages({
    //         target: { value: formik.values.idPerfil, name: 'idPerfil' }
    //       });
    //     }
    //   });
    // eslint-disable-next-line
  }, [access_token]);

  const handlePages = (e) => {
    formik.handleChange(e);
    // CatalogService.getCatalogs(
    //   [
    //     {
    //       id: "paginas",
    //       filtered: [{ id: "usuarios_perfiles_menus.idPerfil", value: e.target.value, filter: "=" }],
    //     },
    //   ]
    // )
    //   .then((res) => {
    //     if (res.results) {
    //       setCatPage(res.response.catalogs.paginas);
    //     } else {
    //       Swal.fire({ title: res.message, icon: "warning" });
    //     }
    //   })
    //   .catch((e) => { });
  };

  const handleSave = (data) => {
    if (action === "add") {
      setUser(data);
    } else {
      let values = {};
      Object.keys(UserProfileInterface).forEach((key) => {
        values[key] = data[key] !== undefined ? data[key] : "";
      });

      updateUser(values);
    }
  };

  const handleMunicipio = (event) => {
    const municipalityId = event.target.value;
    formik.setFieldValue("idMunicipio", municipalityId, false);
    formik.setValues({
      ...formik.values,
      idMunicipio: municipalityId,
      idCentroGto: "",
    });

    let centros = catCentros.filter(
      (item) => item.idMunicipio === municipalityId
    );
    setCatalogs({ ...catalogs, centros });
  };

  /*   useEffect(() => {
      if (action === "add") {
        setUser(flagSubmit);
      }
      // eslint-disable-next-line
    }, [flagSubmit, action]) */

  const setUser = (params) => {
    let data = {
      ...params,
      Nombre: params.Nombre.toUpperCase(),
      Paterno: params.Paterno.toUpperCase(),
      Materno: params.Materno.toUpperCase(),
    };
    delete data.idMunicipio;
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserService.setUser(data, access_token)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
          }).then((res) => {
            if (res) {
              handleCleanForm();
              handleChangeUserInfo(params);
            }
          });
        } else {
          Swal.fire({
            title: "No se pudo almacenar",
            text: res.message,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Ha ocurrido un error",
          text: e.message,
          icon: "warning",
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const updateUser = (params) => {
    const data = {
      id: params.id,
      Nombre: params.Nombre.toUpperCase(),
      Paterno: params.Paterno.toUpperCase(),
      Materno: params.Materno.toUpperCase(),
      Celular: params.Celular,
      Correo: params.Correo,
    };
    //delete data.idMunicipio;
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserService.updateProfile(data, access_token)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
          }).then((res) => {
            if (res) {
              handleCleanForm();
              handleChangeUserInfo(params);
            }
          });
        } else {
          Swal.fire({
            title: "No se pudo actualizar",
            text: res.message,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "No se pudo actualizar",
          text: e.message,
          icon: "warning",
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleClickShowPassword = (prop) => (event) => {
    setFlags({ ...flags, [prop]: !flags[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCleanForm = (e) => {
    formik.setValues(action === "add" ? UserProfileInterface : data);
  };

  return (
    <Box sx={{ p: 3 }}>
      <LoadingForm
        isLoading={isloadigForm}
        success={issuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />
      {action === "add" && (
        <>
          <Grid container spacing={2} sx={{ mt: "1vh" }}>
            <Chip icon={<Icon>login</Icon>} label="Datos de Acceso" />
          </Grid>
          <Grid container spacing={3} sx={{ mt: "1vh" }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                error={
                  formik.touched.email &&
                  !isEmptyOrInvalidString(formik.errors.email)
                }
                label="Correo electrónico *"
                helperText={
                  formik.touched.email &&
                  formik.errors.email &&
                  formik.errors.email
                }
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                error={
                  formik.touched.password &&
                  !isEmptyOrInvalidString(formik.errors.password)
                }
                label="Contraseña *"
                helperText={
                  formik.touched.password &&
                  formik.errors.password &&
                  formik.errors.password
                }
                type={flags.showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                size="small"
                variant="outlined"
                className="fixed-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword("showPassword")}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {flags.showPassword ? (
                          <Icon>visibility_off</Icon>
                        ) : (
                          <Icon>visibility</Icon>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                error={
                  formik.touched.confirm &&
                  !isEmptyOrInvalidString(formik.errors.confirm)
                }
                label="Confirmar contraseña *"
                helperText={
                  formik.touched.confirm &&
                  formik.errors.confirm &&
                  formik.errors.confirm
                }
                type={flags.showConfirmPassword ? "text" : "password"}
                name="confirm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm}
                size="small"
                variant="outlined"
                className="fixed-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword("showConfirmPassword")}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {flags.showConfirmPassword ? (
                          <Icon>visibility_off</Icon>
                        ) : (
                          <Icon>visibility</Icon>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} sx={{ mt: "1vh" }}>
        <Chip icon={<Icon>person</Icon>} label="Datos Personales" />
      </Grid>
      <Grid container spacing={3} sx={{ mt: "1vh" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            required
            error={
              formik.touched.Nombre &&
              !isEmptyOrInvalidString(formik.errors.Nombre)
            }
            label={"Nombre"}
            helperText={
              formik.touched.Nombre &&
              formik.errors.Nombre &&
              formik.errors.Nombre
            }
            type="text"
            name="Nombre"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Nombre.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            required
            error={
              formik.touched.Paterno &&
              !isEmptyOrInvalidString(formik.errors.Paterno)
            }
            label={"Paterno"}
            helperText={
              formik.touched.Paterno &&
              formik.errors.Paterno &&
              formik.errors.Paterno
            }
            type="text"
            name="Paterno"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Paterno.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: "1vh" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            required
            error={
              formik.touched.Materno &&
              !isEmptyOrInvalidString(formik.errors.Materno)
            }
            label={"Materno"}
            helperText={
              formik.touched.Materno &&
              formik.errors.Materno &&
              formik.errors.Materno
            }
            type="text"
            name="Materno"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Materno.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            error={
              formik.touched.Celular &&
              !isEmptyOrInvalidString(formik.errors.Celular)
            }
            label="Celular"
            helperText={
              formik.touched.Celular &&
              formik.errors.Celular &&
              formik.errors.Celular
            }
            type="text"
            name="Celular"
            onChange={(e) =>
              formik.handleChange({
                target: {
                  name: "Celular",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : formik.values.Celular,
                },
              })
            }
            onBlur={(e) =>
              formik.handleChange({
                target: {
                  name: "celular",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : formik.values.Celular,
                },
              })
            }
            value={formik.values.Celular}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid>
        {/*   <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            error={
              formik.touched.Telefono &&
              !isEmptyOrInvalidString(formik.errors.Telefono)
            }
            label="Teléfono"
            helperText={
              formik.touched.Telefono &&
              formik.errors.Telefono &&
              formik.errors.Telefono
            }
            type="text"
            name="Telefono"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.Telefono === null ? "" : formik.values.Telefono
            }
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            error={
              formik.touched.Extension &&
              !isEmptyOrInvalidString(formik.errors.Extension)
            }
            label="Extensión"
            helperText={
              formik.touched.Extension &&
              formik.errors.Extension &&
              formik.errors.Extension
            }
            type="text"
            name="Extension"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.Extension === null ? "" : formik.values.Extension
            }
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid> */}
      </Grid>
      {isAdmin && (
        <>
          <Grid container spacing={2} sx={{ mt: "1vh" }}>
            <Chip
              icon={<Icon>perm_contact_calendar</Icon>}
              label="Datos de Aréa"
            />
          </Grid>
          <Grid container spacing={3} sx={{ mt: "1vh" }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={
                  formik.touched.idMunicipio &&
                  !isEmptyOrInvalidString(formik.errors.idMunicipio)
                }
                label="Municipio"
                name="idMunicipio"
                onChange={(e) => handleMunicipio(e)}
                onBlur={(e) => handleMunicipio(e)}
                value={formik.values.idMunicipio}
                options={catalogs.municipios}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={
                  formik.touched.idCentroGto &&
                  !isEmptyOrInvalidString(formik.errors.idCentroGto)
                }
                label="Centro Gto Contigo Sí*"
                name="idCentroGto"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idCentroGto}
                options={catalogs.centros}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={
                  formik.touched.idDependencia &&
                  !isEmptyOrInvalidString(formik.errors.idDependencia)
                }
                label="Dependencia *"
                name="idDependencia"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idDependencia}
                options={catalogs.dependencias}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: "1vh" }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                required
                error={
                  formik.touched.Cargo &&
                  !isEmptyOrInvalidString(formik.errors.Cargo)
                }
                label={"Cargo / Puesto"}
                helperText={
                  formik.touched.Cargo &&
                  formik.errors.Cargo &&
                  formik.errors.Cargo
                }
                type="text"
                name="Cargo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Cargo}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={
                  formik.touched.idPerfil &&
                  !isEmptyOrInvalidString(formik.errors.idPerfil)
                }
                label="Perfil / Rol *"
                name="idPerfil"
                onChange={(e) => {
                  handlePages(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.idPerfil}
                options={catalogs.perfiles_hijos}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={
                  !isEmptyOrInvalidString(formik.errors.DefaultPage)
                }
                label="Página de Inicio"
                name="DefaultPage"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.DefaultPage}
                options={catPage}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={3} sx={{ mt: "1vh" }}>
        {/* {action === "add" && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              error={
                formik.touched.PinCode &&
                !isEmptyOrInvalidString(formik.errors.PinCode)
              }
              label="Pin de Acceso"
              helperText={
                formik.touched.PinCode &&
                formik.errors.PinCode &&
                formik.errors.PinCode
              }
              type="text"
              name="PinCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.PinCode}
              variant="standard"
              className="fixed-input"
            />
          </Grid>
        )} */}
        {action === "edit" && (
          // <Grid item xs={12} sm={12} md={4} lg={4}>
          //   <TextField
          //     error={
          //       formik.touched.email &&
          //       !isEmptyOrInvalidString(formik.errors.email)
          //     }
          //     label="Correo electrónico *"
          //     helperText={
          //       formik.touched.email &&
          //       formik.errors.email &&
          //       formik.errors.email
          //     }
          //     type="text"
          //     name="email"
          //     onChange={formik.handleChange}
          //     onBlur={formik.handleBlur}
          //     value={formik.values.email}
          //     variant="standard"
          //     className="fixed-input"
          //   />
          // </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              required
              error={
                formik.touched.Correo &&
                !isEmptyOrInvalidString(formik.errors.Correo)
              }
              label="Correo electrónico "
              helperText={
                formik.touched.Correo &&
                formik.errors.Correo &&
                formik.errors.Correo
              }
              type="text"
              name="Correo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Correo}
              size="small"
              variant="outlined"
              className="fixed-input"
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ mt: "2vh" }}>
        <Chip icon={<Icon>person</Icon>} label="Datos Personales" />
      </Grid>
      <Grid container spacing={3} sx={{ mt: "3vh", ml:"1vh" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Typography variant="subtitle2" gutterBottom>
            Responsable:
          </Typography>
          <Typography variant="subtitle1" color={"steelblue"} gutterBottom>
            {data.Responsable}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: "5vh", textAlign: "end" }}>
        <Button
          type="button"
          sx={{ mr: "1vh" }}
          variant="outlined"
          color="primaryDark"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primaryDark"
          onClick={(e) => {
            formik.submitForm();
          }}
          startIcon={<Icon>save</Icon>}
        >
          Guardar
        </Button>
      </Grid>
    </Box>
  );
};

UserForm.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleChangeUserInfo: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

UserForm.defaultProps = {
  action: "add",
  isAdmin: true,
};

export default UserForm;
